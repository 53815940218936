import React, { useRef, useEffect, useState } from 'react';
import { Image } from 'react-konva';

const LocalImage = (props: any) => {
  const [source, setSource] = useState<any>(null);
  const imageRef = useRef(new window.Image());

  const handleLoad = () => {
    setSource(imageRef.current);
  };

  useEffect(() => {
    imageRef.current.src = props.src;
    imageRef.current.addEventListener('load', handleLoad);

    return () => {
      imageRef.current.removeEventListener('load', handleLoad);
    };
  }, [props.src]);

  return <Image {...props} image={source} />;
};

export default LocalImage;
