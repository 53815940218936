import React, {useContext} from 'react';
import { View } from 'react-native';
import { mobileSizeChecker } from '@src/utils/screensize-helper';
import useStyles from './styles.css';
import MobileHeader from './mobile';
import WebHeader from './web';
import { AppRoutingContext } from '@src/navigators/AppRouting';

const DrawerItemHeader = () => {
  const styles = useStyles();
  const isMobile = mobileSizeChecker();

  const {headerHidden} = useContext(AppRoutingContext)

  return headerHidden ? <View style={{height: 0}}/> : (
    <View style={styles.drawer_item_header}>
      {isMobile ? <MobileHeader /> : <WebHeader />}
    </View>
  );
};

export default DrawerItemHeader;
