import React, { useContext } from 'react';
import { getClientBox, getTotalBox } from '@src/utils/signature-helper';
import { Group, Rect, Transformer } from 'react-konva';
import { CanvasContext } from '../../provider/canvas';
import QRCode from '@src/components/qr-code';
import { Html } from 'react-konva-utils';
import images from '@assets/images';
import LocalImage from '../Image';

const ACTION_IMAGE_SIZE = 50;
const ACTION_IMAGE_MARGIN = 10;

type QRCodeSignProps = {
  field: any;
  isSelected: boolean;
  onSelect: () => void;
  onRemove: () => void;
  onDeselect: () => void;
  onChange: (item: any) => void;
};

const QRCodeSign = (props: QRCodeSignProps) => {
  const { field } = props;
  const trRef = React.useRef<any>();
  const shapeRef = React.useRef<any>();
  const { canvas, stageRef } = useContext(CanvasContext);
  const [move, setMove] = React.useState({ ...field });
  const actionState = React.useMemo(() => {
    let rotatedPos90 =
      Math.abs(move.rotation) === 90
        ? move.height
        : move.width + ACTION_IMAGE_MARGIN;
    const rotatedNeg90 =
      Math.trunc(move.rotation) === -90
        ? move.width + ACTION_IMAGE_SIZE
        : ACTION_IMAGE_SIZE;

    const rotatedNeg90Y = Math.trunc(move.rotation) === -90 ? move.width : 0;
    let checkX = move.x > 0 ? move.x + rotatedPos90 : rotatedPos90;
    let checkY = move.y < ACTION_IMAGE_SIZE ? 0 : move.y - rotatedNeg90;
    let deleteX = move.x > 0 ? move.x + rotatedPos90 : rotatedPos90;
    let deleteY =
      move.y < ACTION_IMAGE_SIZE ? ACTION_IMAGE_SIZE : move.y - rotatedNeg90Y;

    if (
      stageRef.current.getWidth() - (move.x + rotatedPos90) <
      ACTION_IMAGE_SIZE
    ) {
      checkX =
        stageRef.current.getWidth() - rotatedPos90 - ACTION_IMAGE_SIZE * 2;
    }

    return {
      checkX: checkX,
      checkY,
      deleteX,
      deleteY,
    };
  }, [move, stageRef.current]);

  React.useEffect(() => {
    if (props.isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }

    if (field) {
      setMove(field);
    }
  }, [props.isSelected, field]);

  return (
    <React.Fragment>
      <Group
        draggable
        ref={shapeRef}
        x={field.x}
        y={field.y}
        width={field.width}
        height={field.height}
        onClick={props.onSelect}
        onTap={props.onSelect}
        onDragStart={props.onSelect}
        onDragMove={() => {
          const boxes = trRef.current
            ?.nodes()
            .map((node: any) => node.getClientRect());
          const box = getTotalBox(boxes);

          trRef.current?.nodes().forEach((shape: any) => {
            const absPos = shape.getAbsolutePosition();
            const rotation = shape.getAbsoluteRotation();
            // where are shapes inside bounding box of all shapes?

            const offsetX = box.x - absPos.x;
            const offsetY = box.y - absPos.y;

            // we total box goes outside of viewport, we need to move absolute position of shape
            const newAbsPos = { ...absPos };
            if (box.x < 0) {
              newAbsPos.x = -offsetX;
            }
            if (box.y < 0) {
              newAbsPos.y = -offsetY;
            }
            if (box.x + box.width > stageRef.current.width()) {
              newAbsPos.x = stageRef.current.width() - box.width - offsetX;
            }
            if (box.y + box.height > stageRef.current.height()) {
              newAbsPos.y = stageRef.current.height() - box.height - offsetY;
            }
            shape.setAbsolutePosition(newAbsPos);

            setMove({
              x: box.x,
              y: box.y,
              rotation,
              width: box.width,
              height: box.height,
            });
          });
        }}
        onDragEnd={(e) => {
          const targetX = e.target.x();
          const targetY = e.target.y();

          props.onChange({
            ...field,
            x: Math.abs(targetX / canvas.scale),
            y: Math.abs(targetY / canvas.scale),
            width: Math.abs(field.width / canvas.scale),
            height: Math.abs(field.height / canvas.scale),
          });
        }}
        onTransformEnd={(e) => {
          const node = e.target;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          const mathWidth = Math.abs(node.width() / canvas.scale);
          const mathHeight = Math.abs(node.height() / canvas.scale);

          node.scaleX(1);
          node.scaleY(1);
          props.onChange({
            ...field,
            x: Math.abs(node.x() / canvas.scale),
            y: Math.abs(node.y() / canvas.scale),
            width: Math.max(mathWidth * scaleX),
            height: Math.max(mathHeight * scaleY),
            rotation: e.target.getAbsoluteRotation(),
          });
        }}
      >
        <Rect width={field.width} height={field.height} />
        <Html
          divProps={{
            style: {
              pointerEvents: 'none',
            },
          }}
        >
          <QRCode
            width={field.height}
            height={field.height}
            value={'valualsfkjlaskglakjsglkajsglkajslgkajslkgjase'}
          />
        </Html>
      </Group>

      {props.isSelected && (
        <>
          <LocalImage
            x={actionState.checkX}
            y={actionState.checkY}
            width={ACTION_IMAGE_SIZE}
            height={ACTION_IMAGE_SIZE}
            onClick={props.onDeselect}
            onTap={props.onDeselect}
            verticalAlign="middle"
            src={images.ic_check}
          />
          <LocalImage
            x={actionState.checkX}
            y={actionState.deleteY}
            width={ACTION_IMAGE_SIZE}
            height={ACTION_IMAGE_SIZE}
            onClick={props.onRemove}
            onTap={props.onRemove}
            verticalAlign="middle"
            src={images.ic_delete}
          />
        </>
      )}

      {props.isSelected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          rotationSnapTolerance={45}
          rotationSnaps={[0, 90, 180, 270]}
          boundBoxFunc={(oldBox, newBox) => {
            const box = getClientBox(newBox);
            const isOut =
              box.x < 0 ||
              box.y < 0 ||
              box.x + box.width > stageRef.current?.getWidth() ||
              box.y + box.height > stageRef.current?.getHeight();

            if (isOut) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(QRCodeSign);
