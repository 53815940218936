import React, { useContext, useState } from 'react';
import { Text, Transformer } from 'react-konva';
import { getClientBox, getTotalBox } from '@src/utils/signature-helper';
import { CanvasContext } from '../../provider/canvas';
import colors from '@assets/colors';
import images from '@assets/images';
import LocalImage from '../Image';
import { Html } from 'react-konva-utils';

const ACTION_IMAGE_SIZE = 50;
const ACTION_IMAGE_MARGIN = 30;

type TextProps = {
  field: any;
  isSelected: boolean;
  onSelect: () => void;
  onRemove: () => void;
  onDeselect: () => void;
  onChange: (item: any) => void;
};

const TextBox = (props: TextProps) => {
  const { field } = props;
  const trRef = React.useRef<any>();
  const textRef = React.useRef<any>();
  const [move, setMove] = React.useState({ ...field });
  const { canvas, stageRef } = useContext(CanvasContext);
  const textareaRef = React.useRef<any>(null);

  const [text, setText] = useState('Some text here');
  const [textareaValue, setTextareaValue] = useState('');

  const [transformerVisible, setTransformerVisible] = useState(false);
  const [textareaVisible, setTextareaVisible] = useState(false);
  const [textareaPosition, setTextareaPosition] = useState({ x: 0, y: 0 });
  const [textareaSize, setTextareaSize] = useState({ width: 0, height: 0 });

  console.log('textareaVisible', textareaVisible, textareaSize);

  const actionState = React.useMemo(() => {
    let checkX =
      move.x > 0
        ? move.x + move.width + ACTION_IMAGE_MARGIN
        : move.width + ACTION_IMAGE_MARGIN;
    let checkY = move.y < ACTION_IMAGE_SIZE ? 0 : move.y - ACTION_IMAGE_SIZE;
    let deleteX =
      move.x > 0
        ? move.x + move.width + ACTION_IMAGE_MARGIN
        : move.width + ACTION_IMAGE_MARGIN;
    let deleteY = move.y < ACTION_IMAGE_SIZE ? ACTION_IMAGE_SIZE : move.y;

    if (
      stageRef.current.getWidth() - (move.x + move.width) <
      ACTION_IMAGE_SIZE
    ) {
      checkX =
        stageRef.current.getWidth() - move.width - (ACTION_IMAGE_SIZE * 2 + 10);
    }

    return {
      checkX,
      checkY,
      deleteX,
      deleteY,
    };
  }, [move, stageRef.current]);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleTextDblClick = (e: any) => {
    const textNode = e.target;
    setTransformerVisible(false);

    const stageContainer = stageRef.current.container();
    const textPosition = textNode.absolutePosition();

    const areaPosition = {
      x: stageContainer.offsetLeft + textPosition.x,
      y: stageContainer.offsetTop + textPosition.y,
    };

    setTextareaPosition(areaPosition);
    setTextareaSize({
      width: textNode.width() - textNode.padding() * 2,
      height: textNode.height() - textNode.padding() * 2 + 5,
    });

    setTextareaVisible(true);
    setTextareaValue(textNode.text());

    textareaRef.current.focus();
  };

  const handleTextareaChange = (e: any) => {
    console.log('e.target.value', e.target.value);
    // setTextareaValue(e.target.value);

    // const newWidth =
    //   textRef.current.placeholder.length * textRef.current.fontSize();
    // setTextareaSize((prevState) => ({
    //   ...prevState,
    //   width: newWidth,
    // }));
  };

  const handleTextareaBlur = () => {
    textRef.current.text(textareaValue);
    setTextareaVisible(false);
    setTransformerVisible(true);
  };

  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([textRef.current]);
      trRef.current.getLayer().batchDraw();
    }

    if (field) {
      setMove(field);
    }
  }, [props.isSelected, field]);

  return (
    <React.Fragment>
      <Text
        align="center"
        draggable
        ref={textRef}
        x={field.x}
        y={field.y}
        text={field.text}
        onTap={props.onSelect}
        onClick={props.onSelect}
        rotation={field.rotation}
        onDragStart={props.onSelect}
        fontSize={field.fontSize}
        onDblClick={handleTextDblClick}
        onDblTap={handleTextDblClick}
        fill={colors.black}
        fontFamily="DMSans"
        verticalAlign="bottom"
        onDragMove={() => {
          const boxes = trRef.current
            ?.nodes()
            .map((node: any) => node.getClientRect());
          const box = getTotalBox(boxes);

          trRef.current?.nodes().forEach((shape: any) => {
            const absPos = shape.getAbsolutePosition();
            const rotation = shape.getAbsoluteRotation();
            // where are shapes inside bounding box of all shapes?

            const offsetX = box.x - absPos.x;
            const offsetY = box.y - absPos.y;

            // we total box goes outside of viewport, we need to move absolute position of shape
            const newAbsPos = { ...absPos };
            if (box.x < 0) {
              newAbsPos.x = -offsetX;
            }
            if (box.y < 0) {
              newAbsPos.y = -offsetY;
            }
            if (box.x + box.width > stageRef.current.width()) {
              newAbsPos.x = stageRef.current.width() - box.width - offsetX;
            }
            if (box.y + box.height > stageRef.current.height()) {
              newAbsPos.y = stageRef.current.height() - box.height - offsetY;
            }
            shape.setAbsolutePosition(newAbsPos);

            setMove({
              x: box.x,
              y: box.y,
              rotation,
              width: box.width,
              height: box.height,
            });
          });
        }}
        onDragEnd={(e) => {
          const node = textRef.current;
          const scaleX = node.scaleX();
          const targetX = e.target.x();
          const targetY = e.target.y();

          props.onChange({
            ...field,
            x: Math.abs(targetX / canvas.scale),
            y: Math.abs(targetY / canvas.scale),
            fontSize: Math.abs(field.fontSize / canvas.scale),
            width: Math.abs(Math.max(node.width() * scaleX) / canvas.scale),
          });
        }}
        onTransformEnd={(e) => {
          const node = e.target;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);
          props.onChange({
            ...field,
            x: Math.abs(node.x() / canvas.scale),
            y: Math.abs(node.y() / canvas.scale),
            rotation: e.target.getAbsoluteRotation(),
            width: Math.abs(Math.max(node.width() * scaleX) / canvas.scale),
            height: Math.abs(Math.max(node.height() * scaleY) / canvas.scale),
            fontSize: Math.max(
              Math.abs(field.fontSize / canvas.scale) * scaleX
            ),
          });
        }}
      />

      {textareaVisible && (
        <Html
          divProps={{
            style: {
              // pointerEvents: 'none',
            },
          }}
        >
          <textarea
            value={field.text}
            onChange={handleTextareaChange}
            onBlur={handleTextareaBlur}
            style={{
              position: 'absolute',
              left: field.x,
              top: field.y,
              // top: `${textareaPosition.y}px`,
              // left: `${textareaPosition.x}px`,
              width: field.width,
              fontSize: field.fontSize,
            }}
            ref={textareaRef}
          />
        </Html>
      )}

      {props.isSelected && (
        <>
          <LocalImage
            x={actionState.checkX}
            y={actionState.checkY}
            width={ACTION_IMAGE_SIZE}
            height={ACTION_IMAGE_SIZE}
            onClick={props.onDeselect}
            onTap={props.onDeselect}
            verticalAlign="middle"
            src={images.ic_check}
          />
          <LocalImage
            x={actionState.checkX}
            y={actionState.deleteY}
            width={ACTION_IMAGE_SIZE}
            height={ACTION_IMAGE_SIZE}
            onClick={props.onRemove}
            onTap={props.onRemove}
            verticalAlign="middle"
            src={images.ic_delete}
          />
        </>
      )}

      {props.isSelected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          rotationSnaps={[0, 90, 180, 270]}
          rotationSnapTolerance={45}
          enabledAnchors={[
            'top-left',
            'top-right',
            'middle-right',
            'middle-left',
            'bottom-left',
            'bottom-right',
          ]}
          boundBoxFunc={(oldBox, newBox) => {
            const box = getClientBox(newBox);

            setMove(newBox);

            const isOut =
              box.x < 0 ||
              box.y < 0 ||
              box.x + box.width > stageRef.current?.getWidth() ||
              box.y + box.height > stageRef.current?.getHeight();

            // if new bounding box is out of visible viewport, let's just skip transforming
            // this logic can be improved by still allow some transforming if we have small available space
            if (isOut) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(TextBox);
