import React from 'react';
import { Rect, Text, Group, Path } from 'react-konva';
import RectImage from '../RectImage';
import colors from '@assets/colors';

type SignatureDateProps = {
  field: any;
  onSelect: () => void;
};

const baseWidth = 17.81;

const SignatureDate = (props: SignatureDateProps) => {
  const { field } = props;

  const imagePosition = React.useMemo(() => {
    const posX = field.width * 0.40621;
    const posY = field.height * 0.11;
    const heightPercentage = field.height * 0.37494;
    const widthScale = heightPercentage / baseWidth;
    const heightScale = heightPercentage / baseWidth;

    return {
      posX,
      posY,
      scale: Math.min(widthScale, heightScale),
    };
  }, [field.width, field.height]);

  return (
    <React.Fragment>
      <Group
        x={field.x}
        y={field.y}
        width={field.width}
        height={field.height}
        onClick={props.onSelect}
        onTap={props.onSelect}
        rotation={field.rotation}
      >
        {field.signature ? (
          <>
            <RectImage
              width={field.width}
              height={field.height}
              src={field.signature}
            />
            <Text
              y={field.height + 10}
              text={field.text}
              fontFamily="DMSans"
              fill={colors.black}
              fontSize={field.fontSize}
            />
          </>
        ) : (
          <>
            <Rect
              dash={[8, 8]}
              dashOffset={3}
              strokeWidth={2}
              cornerRadius={6.25}
              width={field.width}
              height={field.height}
              stroke={field.fill}
              fill={`${field.fill}30`}
            />
            <Path
              data="M12.9102 20.8845 H22.2832"
              x={imagePosition.posX}
              y={imagePosition.posY}
              scaleX={imagePosition.scale}
              scaleY={imagePosition.scale}
              stroke={field.fill}
              strokeWidth={2.0829}
              linecap="round"
              linejoin="round"
            />
            <Path
              data="M17.5986 3.70078C18.0129 3.28647 18.5748 3.05371 19.1608 3.05371C19.4509 3.05371 19.7382 3.11085
          20.0062 3.22188C20.2743 3.3329 20.5178 3.49564 20.7229 3.70078C20.9281 3.90593
          21.0908 4.14948 21.2019 4.41751C21.3129 4.68555 21.37 4.97283 21.37 5.26295C21.37
          5.55308 21.3129 5.84036 21.2019 6.1084C21.0908 6.37643 20.9281 6.61998 20.7229 6.82513L7.70485
          19.8432L3.53906 20.8847L4.58051 16.7189L17.5986 3.70078Z"
              x={imagePosition.posX}
              y={imagePosition.posY}
              scaleX={imagePosition.scale}
              scaleY={imagePosition.scale}
              stroke={field.fill}
              strokeWidth={2.0829}
              linecap="round"
              linejoin="round"
            />
            <Text
              text="Sign"
              padding={field.height * 0.05}
              align="center"
              width={field.width}
              height={field.height}
              fontFamily="DMSans"
              verticalAlign="bottom"
              fill={field.fill}
              fontSize={field.height / 3.5}
            />
            <Text
              y={field.height + 10}
              text={field.text}
              fontFamily="DMSans"
              fill={colors.black}
              fontSize={field.fontSize}
            />
          </>
        )}
      </Group>
    </React.Fragment>
  );
};

export default React.memo(SignatureDate);
