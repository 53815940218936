import React, { useContext, useEffect } from 'react';
import { SignatureContext } from '../provider/signature';
import { CanvasContext } from '../provider/canvas';
import LayerCanvas from './layer/index';
import useStyles from './styles.css';
import { View } from 'react-native';
import { Stage } from 'react-konva';
import { LayerContext } from '../../signature-fields/provider/layer';
// import SigningModal from '../signing-modal';

function Stages(props: any) {
  const styles = useStyles();
  const { loading, documentId } = props;
  const { canvas } = useContext(CanvasContext);
  const { onShowModal } = React.useContext(SignatureContext);
  const { layers } = useContext(LayerContext);

  const { CANVAS_WIDTH, CANVAS_HEIGHT } = React.useMemo(() => {
    const C_WIDTH = canvas.width * canvas.scale || 0;
    const C_HEIGHT = canvas.height * canvas.scale || 0;

    return { CANVAS_WIDTH: C_WIDTH, CANVAS_HEIGHT: C_HEIGHT };
  }, [canvas]);

  const onShowSignature = React.useCallback(onShowModal, []);

  return (
    <View
      style={[
        styles.v_stage_container,
        {
          width: CANVAS_WIDTH,
          height: CANVAS_HEIGHT,
        },
      ]}
    >
      <Stage width={CANVAS_WIDTH} height={CANVAS_HEIGHT}>
        {loading === false &&
          layers.map((item: any, index: number) => {
            if (index === canvas.pageIndex) {
              return (
                <LayerCanvas
                  key={index.toString()}
                  item={item}
                  layerId={index}
                  documentId={documentId}
                  onShowSignature={onShowSignature}
                />
              );
            }

            return null;
          })}
      </Stage>
      {/* <SigningModal /> */}
    </View>
  );
}

export default React.memo(Stages);
