import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StyleSheet } from 'react-native';
import TypographyStyle from '@assets/typography';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      padding: 24,
      justifyContent: 'center',
      backgroundColor: 'grey',
    },
    containerSuccess: {
      flex: 1,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.white,
    },
    contentContainer: {
      flex: 1,
      alignItems: 'center',
    },
    textContainer: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      {
        color: colors.black3,
        fontWeight: '700',
        lineHeight: 23,
        textAlign: 'center',
      },
    ]),
    txt1: {},
    txt2: {},
    imgContainer: {},
    upload_docx: {},
    choosefileContainer: {},
    choosefileBtn: {},
    choosefileTxt: {},
    imgContainerSuccess: {},
    upload_success: {},
    v_body: {
      height: 100,
      marginTop: -300,
      justifyContent: 'center',
      textAlign: 'center',
      flexDirection: 'column',
    },
    successTextContainer: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      {
        color: colors.black3,
        fontWeight: '700',
        lineHeight: 23,
        textAlign: 'center',
      },
    ]),
    textSuccess: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      {
        color: colors.black3,
        fontWeight: '700',
        lineHeight: 23,
        textAlign: 'center',
      },
    ]),
    cardContainer: {
      marginTop: 25,
    },
    cardText: {
      paddingHorizontal: 0,
      paddingVertical: 0,
      backgroundColor: '#F4F4FF',
      textAlign: 'left',
    },
    cardText1: {
      display: 'flex',
    },
    fileName: {
      fontSize: 14,
      fontWeight: '700',
    },
    fileSize: {
      fontSize: 10,
      fontWeight: '400',
      marginTop: -5,
      lineHeight: 0,
    },
    closeBtn: {
      backgroundColor: colors.purple,
      color: colors.white,
      borderRadius: 10,
      marginTop: 15,
      marginRight: 15,
      paddingHorizontal: 5,
      paddingVertical: 5,
      height: 20,
    },
    fileIcon_container: {
      marginTop: 50,
      flexDirection: 'row',
    },
    file_icon: {
      width: 16,
      height: 18,
    },
    fileIcon_label: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      {
        fontWeight: '700',
        color: colors.black10,
      },
    ]),
    fileIcon_btn: {
      backgroundColor: colors.transparent,
      height: 20,
    },
    nextBtnContainer: {
      marginTop: 100,
    },
    nextBtnText: {
      paddingHorizontal: 100,
    },
  },

  {
    [DEVICE_SIZES.XS]: {
      textContainer: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.black3,
          fontWeight: '700',
          lineHeight: 23,
          textAlign: 'center',
          paddingTop: 50,
          paddingBottom: 50,
        },
      ]),
      txt1: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.black3,
          fontWeight: '700',
          lineHeight: 23,
          textAlign: 'center',
        },
      ]),
      imgContainer: { alignItems: 'center', justifyContent: 'center' },
      upload_docx: { width: 290, height: 100 },
      choosefileContainer: {
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        flex: 1,
        flexDirection: 'column',
        marginTop: 100,
      },
      choosefileTxt: {
        padding: 85,
        color: colors.black,
      },
      choosefileBtn: {
        backgroundColor: colors.transparent,
        borderColor: colors.black,
        borderWidth: 1,
      },
      upload_success: {
        width: 64,
        height: 75,
      },
      successTextContainer: { textAlign: 'center', marginTop: 20 },
      textSuccess: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.black3,
          fontWeight: '700',
          lineHeight: 23,
          textAlign: 'center',
        },
      ]),
      imgContainerSuccess: {
        justifyContent: 'center',
        flex: 1,
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      },
      v_body: {
        height: 100,
        marginTop: 100,
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      },
      cardContainer: {
        marginTop: 25,
        width: 290,
        backgroundColor: '#F4F4FF',
      },
      cardText: {
        textAlign: 'left',
        backgroundColor: '#F4F4FF',
        width: 500,
        height: 100,
      },
      cardText1: {
        display: 'flex',
        flex: 1,
      },
      closeBtn: {
        backgroundColor: colors.purple,
        color: colors.white,
        borderRadius: 10,
        marginTop: 20,
        marginRight: 15,
        paddingHorizontal: 5,
        paddingVertical: 5,
        height: 20,
      },
      nextBtnContainer: {
        marginTop: 100,
      },
    },
  }
);

export default useStyles;
