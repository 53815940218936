import React, { useEffect } from 'react';
import { useDeviceSize, DEVICE_SIZES } from 'rn-responsive-styles';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { View } from 'react-native';
import useStyles from './styles.css';
import { useNavigation } from '@react-navigation/native';
import Logo from './logo';
import OnboardingText from './onboarding-text';

const OnBoarding = () => {
  const device_size = useDeviceSize();
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();

  return (
    <View style={styles.container}>
      <View style={styles.v_body}>
        <Logo />
        <OnboardingText />
      </View>
    </View>
  );
};

export default OnBoarding;
