import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1 },
    scroll_vertical: { flexGrow: 1, height: 500 },
    scroll_horizontal: { flexGrow: 1, justifyContent: 'center' },
    v_viewer: {
      marginTop: 50,
      marginLeft: 50,
      marginRight: 50,
      marginBottom: 50,
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_height: { height: 50 },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      v_viewer: {
        width: '100%',
        marginTop: 20,
      },
      v_height: { height: 200 },
      scroll_vertical: {
        width: '100%',
        height: '100%',
      },
      scroll_horizontal: {
        width: '100%',
        marginHorizontal: 10,
      },
    },
    [DEVICE_SIZES.XS]: {
      v_viewer: {
        width: '100%',
        marginTop: 20,
      },
      v_height: { height: 200 },
      scroll_vertical: {
        width: '100%',
        height: '100%',
      },
      scroll_horizontal: {
        width: '100%',
        marginHorizontal: 10,
      },
    },
  }
);

export default useStyles;
