// DUCKS pattern
import { createAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@src/ducks/store';
import { createSelector } from '@reduxjs/toolkit';
import {
  ErrorValue,
  CreateLiveRequestValue,
  CreateDocumentRequestValue,
  SessionValue,
} from '../types';
import JSONData from 'screens/live-request/video-session/data.json';
import TestInitial from './test3.json';

export interface initialStateValue {
  createRequestData: CreateLiveRequestValue;
  videoSessionData: any;
  loading: boolean;
  document: CreateDocumentRequestValue;
  error: ErrorValue;
  signatures: any;
  generatedpdf: any;
  affixSignature: CreateDocumentRequestValue;
  currentLoading: string;
}

export const initialState: initialStateValue = {
  createRequestData: TestInitial as any,
  videoSessionData: {},
  loading: false,
  error: {} as ErrorValue,
  signatures: [],
  generatedpdf: '',
} as initialStateValue;

// Slice
export const signsecureSlice = createSlice({
  name: 'signsecure',
  initialState: initialState,
  reducers: {
    // Create Request
    createLiveRequest: (state) => {
      state.loading = true;
    },
    createLiveRequestSuccess: (state, action) => {
      state.createRequestData = action.payload;
      state.signatures = action.payload.workflow.signatures;
      state.loading = false;
    },
    createLiveRequestFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    // View Document
    viewDocumentRequest: (state) => {
      state.document = {} as CreateDocumentRequestValue;
      state.error = {} as ErrorValue;
      state.loading = true;
    },
    viewDocumentSuccess: (state, action) => {
      state.document = action.payload;
      state.loading = false;
    },
    viewDocumentFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    // Video Session
    submitVideoSession: (state) => {
      state.loading = true;
    },
    submitVideoSessionSuccess: (state, action) => {
      state.videoSessionData = action.payload;
      state.generatedpdf = action.payload;
      state.loading = false;
    },
    submitVideoSessionFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    // affixing signatures
    affixSignatureRequest: (state) => {
      state.loading = true;
      state.currentLoading = 'Signing';
    },
    affixSignatureSuccess: (state, action) => {
      state.affixSignature = action.payload;
      state.currentLoading = '';
      state.loading = false;
    },
    affixSignatureFailed: (state, action) => {
      state.error = action.payload;
      state.currentLoading = '';
      state.loading = false;
    },

    resetError: (state) => {
      state.error = {} as ErrorValue;
    },
    updateSignatures: (state, action) => {
      state.createRequestData = action.payload;
    },
  },
});

// Actions
export const signsecureActions = {
  createLiveRequest: createAction(
    `${signsecureSlice.name}/createLiveRequest`,
    (params: CreateLiveRequestValue) => ({
      payload: params,
    })
  ),
  createLiveRequestSuccess: signsecureSlice.actions.createLiveRequestSuccess,
  createLiveRequestFailed: signsecureSlice.actions.createLiveRequestFailed,
  viewDocumentRequest: createAction(
    `${signsecureSlice.name}/viewDocumentRequest`,
    (params: CreateDocumentRequestValue) => ({
      payload: params,
    })
  ),
  viewDocumentSuccess: signsecureSlice.actions.viewDocumentSuccess,
  viewDocumentFailed: signsecureSlice.actions.viewDocumentFailed,
  submitVideoSession: createAction(
    `${signsecureSlice.name}/submitVideoSession`,
    (params: any) => ({
      payload: params,
    })
  ),
  affixSignatureRequest: createAction(
    `${signsecureSlice.name}/affixSignatureRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  affixSignatureSuccess: signsecureSlice.actions.affixSignatureSuccess,
  affixSignatureFailed: signsecureSlice.actions.affixSignatureFailed,
  submitVideoSessionSuccess: signsecureSlice.actions.submitVideoSessionSuccess,
  submitVideoSessionFailed: signsecureSlice.actions.submitVideoSessionFailed,
  resetError: signsecureSlice.actions.resetError,
  updateSignatures: signsecureSlice.actions.updateSignatures,
};

// Selectors
export const selectedSignSecureLoading = (state: RootState) =>
  state.signsecure.loading;
export const selectCreateRequestData = (state: RootState) =>
  state.signsecure.createRequestData;
export const selectVideoSessionData = (state: RootState) =>
  state.signsecure.videoSessionData;
export const selectedSignatures2 = (state: RootState) =>
  state.signsecure.signatures;
export const selectedGeneratedPDF = (state: RootState) =>
  state.signsecure.generatedpdf;
export const selectUserFailed = createSelector(
  (state: RootState) => state.signsecure.error,
  (error) => (error.message || '').includes('Unauthorized')
);
export const selectedSignatures = createSelector(
  (state: RootState) => state.signsecure.createRequestData,
  (state) => state?.workflow?.signatures ?? []
);
export const selectedViewDocument = (state: RootState) =>
  state.signsecure.document;

// Reducer
export default signsecureSlice.reducer;
