import React from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import useStyles from './style.css';
import PaymentsHeader from './header';
import PaymentConfirmationDetails from './confirmation-details';
import PaymentConfirmationLogo from './logo';
import { Button01 } from '@src/components/button';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { useNavigation } from '@react-navigation/native';

const PaymentConfirmation = () => {
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();

  const backToDashboard = () => {
    navigation.navigate('Home');
  };

  return (
    <View style={styles.container}>
      <View style={styles.paymentContainerDetails}>
        <PaymentsHeader />
        <View style={styles.v_body}>
          <PaymentConfirmationLogo />
        </View>
        <PaymentConfirmationDetails />
      </View>
      <View style={styles.btnContainer}>
        <Button01
          style={styles.btnNext}
          labelStyle={styles.btnText}
          label="Back to Dashboard"
          onPress={backToDashboard}
        />
      </View>
    </View>
  );
};

export default PaymentConfirmation;
