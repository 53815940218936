import React from 'react';
import { WitnessScreenContext } from './provider/screen';
import { View, StyleSheet } from 'react-native';
import Loading from '@src/components/loading';
import colors from '@assets/colors';

// SCREENS
const Signatures = React.lazy(() => import('./signatures'));

const WitnessSignature = () => {
  const { screen } = React.useContext(WitnessScreenContext);

  const components = React.useMemo(() => {
    switch (screen) {
      case 'witness':
        return <Signatures />;
      case 'signature':
        return <Signatures />;
      default:
        return <View />;
    }
  }, [screen]);

  return (
    <View style={styles.container}>
      <React.Suspense fallback={<Loading />}>{components}</React.Suspense>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: colors.offwhite },
});

export default React.memo(WitnessSignature);
