import React, { useState, useCallback, useRef } from 'react';
import {
  View,
  Image,
  Text,
  Modal,
  Dimensions,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from 'react-native';
import { DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { Button01 } from '@src/components/button';
import useStyles from './styles.css';
import { useNavigation } from '@react-navigation/native';

const FaceVerifiedText = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();

  const uploadDocument = () => {
    console.log('upload docs');
  };

  return (
    <View>
      <View style={styles.textContainer}>
        <Text style={styles.txtNote1}>Session Concluded</Text>
        <View style={styles.textContainer2}>
          <Text style={styles.txtNote3}>
            {`Thank you for your active participation! An email summarizing the key points and details of the session will be sent to you shortly.`}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default FaceVerifiedText;
