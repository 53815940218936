import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      backgroundColor: colors.offwhite,
    },
    scrollview: { height: 500 },
    v_height40: { height: 40 },

    chatContainer: {
      flex: 1,
      padding: 10,
    },
    yourMessageContainer: {
      padding: 10,
      alignItems: 'flex-end',
    },
    yourMessageText: {
      maxWidth: 400,
      fontSize: 15,
      padding: 10,
      marginLeft: 40,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      color: colors.white,
      backgroundColor: colors.purple,
    },
    messageContainer: {
      padding: 10,
      alignItems: 'flex-start',
    },
    messageText: {
      maxWidth: 400,
      fontSize: 15,
      padding: 10,
      marginRight: 40,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      color: colors.black,
      backgroundColor: colors.border6,
    },
    message_input: {
      backgroundColor: colors.white,
    },
    message_input_border_style: { 
      height: 50,
      borderColor: colors.black 
    },
    message_input_style: {  
      position: 'absolute', 
      alignSelf: 'center',  
      bottom: 0, 
      width: '90%', 
      margin: 20,
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
  }
);

export default useStyles;
