import React, { createContext, useRef, useCallback, useState } from 'react';
import {
  selectUserEmail,
  selectUserFullname,
} from '@src/ducks/slices/user.slice';
import { useAppSelector } from '@src/ducks/ducksHook';
import * as Types from './types';

interface ContextValue {
  jitsiRef: any;
  mic: boolean;
  video: boolean;
  tileview: boolean;
  showsignsecure: boolean;
  participants: object[];
  onChangeSignSecure: () => void;
  onChangeMicStatus: (value: boolean) => void;
  onChangeVideoStatus: (value: boolean) => void;
  onChangeTileViewStatus: (value: boolean) => void;
  updateParticipants: (value: any) => void;
}

export const FONT_SIZE = 15;
export const IMAGE_SIZE = 20;
export const LAYER_WIDTH = 130;
export const LAYER_HEIGHT = 50;

const VideoContext = createContext<ContextValue>({} as ContextValue);

const VideoProvider = ({ children }: Types.DocumentProviderProps) => {
  const jitsiRef = useRef<any>();
  const [mic, setMic] = useState(false);
  const [video, setVideo] = useState(false);
  const email = useAppSelector(selectUserEmail);
  const [tileview, setTileView] = useState(false);
  const fullname = useAppSelector(selectUserFullname);
  const [showsignsecure, setSignsecure] = useState(false);
  const [participants, setParticipants] = useState<any>([
    { email, displayName: fullname },
  ]);

  const onChangeSignSecure = useCallback(() => {
    setSignsecure(!showsignsecure);
  }, [showsignsecure]);

  const onChangeMicStatus = useCallback(
    (value: boolean) => {
      setMic(value);
    },
    [mic]
  );

  const onChangeVideoStatus = useCallback(
    (value: boolean) => {
      setVideo(value);
    },
    [video]
  );

  const onChangeTileViewStatus = useCallback(
    (value: boolean) => {
      setTileView(value);
    },
    [tileview]
  );

  const updateParticipants = useCallback(() => {
    const result = jitsiRef.current?.getParticipantsInfo();
    setParticipants(result);
    console.log('updateParticipants', result);
  }, [participants]);

  const value = {
    jitsiRef,
    mic,
    video,
    tileview,
    participants,
    showsignsecure,
    onChangeMicStatus,
    onChangeVideoStatus,
    onChangeTileViewStatus,
    updateParticipants,
    onChangeSignSecure,
  };

  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};

export { VideoProvider, VideoContext };
