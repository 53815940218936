// DUCKS pattern
import { createAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@src/ducks/store';
import { createSelector } from '@reduxjs/toolkit';
import { ErrorValue, SessionValue } from '../types';

export interface UserState {
  loading: boolean;
  data: any;
  error: ErrorValue;
}

export const initialState: UserState = {
  loading: false,
  data: {},
  error: {} as ErrorValue,
} as UserState;

// Slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserDetails: () => {},
    success: (state, action) => {
      state.data = action.payload;
    },
    failed: (state, action) => {
      state.error = action.payload;
    },
    resetError: (state) => {
      state.error = {} as ErrorValue;
    },

    updateUserRequest: (state) => {
      state.loading = true;
    },
    updateUserSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    updateUserFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// Actions
export const userActions = {
  fetchUserDetails: createAction(
    `${userSlice.name}/fetchUserDetails`,
    (params: SessionValue) => ({
      payload: params,
    })
  ),
  success: userSlice.actions.success,
  failed: userSlice.actions.failed,
  resetError: userSlice.actions.resetError,

  // Update user details
  updateUserRequest: createAction(
    `${userSlice.name}/updateUserRequest`,
    (params: SessionValue) => ({
      payload: params,
    })
  ),
  updateUserSuccess: userSlice.actions.updateUserSuccess,
  updateUserFailed: userSlice.actions.updateUserFailed,
};

// Selectors
export const selectedUserLoading = (state: RootState) => state.user.loading;
export const selectUserDetails = (state: RootState) => state.user.data;
export const selectUserFailed = createSelector(
  (state: RootState) => state.user.error,
  (error) => (error.message || '').includes('Unauthorized')
);
export const selectUserEmail = createSelector(
  (state: RootState) => state.user.data,
  (user) => user?.email
);
export const selectUserFullname = createSelector(
  (state: RootState) => state.user.data,
  (user) =>
    `${user.firstName || ''} ${user.middleName || ''} ${
      user.lastName || ''
    }`.trim()
);

// Reducer
export default userSlice.reducer;
