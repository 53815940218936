import colors from '@assets/colors';
import TypographyStyle from '@assets/typography';
import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      position: 'absolute',
      width: '100%',
      height: '150%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blurBG,
    },
    viewContainer: {
      // width: 636,
      // borderRadius: 10,
      backgroundColor: colors.white,
    },
    header: {
      backgroundColor: colors.purple,
      height: 50,
      display: 'flex',
    },
    v_field: { marginTop: 20 },
    h4: {
      ...TypographyStyle.heading4Bold,
      marginTop: 24,
      marginBottom: 37,
    },
    body2: {
      ...TypographyStyle.Body2Regular,
      color: colors.black3,
      textAlign: 'center',
    },
    bodyS: {
      ...TypographyStyle.BodySRegular,
      color: colors.black3,
      textAlign: 'center',
      marginHorizontal: 21,
    },
    fieldText: {
      ...TypographyStyle.fieldsTitle,
      color: colors.black3,
      textAlign: 'left',
      marginTop: 23,
      marginHorizontal: 53,
      width: '35%',
    },
    BGLightGray: {
      borderWidth: 3,
      borderColor: colors.grayPrimary,
      borderStyle: 'dashed',
      backgroundColor: '#F5F5F5',
      marginHorizontal: 53,
      borderRadius: 10,
      paddingBottom: 34,
    },
    iconClose: { alignSelf: 'flex-end', padding: 24 },
    iconUpload: { alignSelf: 'center', marginTop: 36, marginBottom: 16 },
    v: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: 23,
    },
    dash: {
      height: 1,
      width: 41,
      backgroundColor: colors.darkGray,
    },
    btn: { width: 150, alignSelf: 'center' },
    btnSee: {
      width: 180,
      alignSelf: 'center',
      marginVertical: 30,
    },
    viewContainerProgress: {
      // width: 918,
      borderRadius: 10,
      backgroundColor: colors.white,
    },
    loader: {
      height: 8,
      width: '70%',
      backgroundColor: colors.green,
      marginTop: 32,
      marginBottom: 15,
      alignSelf: 'center',
      borderRadius: 10,
    },
    iconSucess: {
      height: 132,
      width: 120,
      marginTop: 63,
      marginBottom: 28,
      alignSelf: 'center',
    },
    viewContainerSeeDetails: {
      // width: 918,
      // borderRadius: 10,
      backgroundColor: colors.white,
      // paddingHorizontal: 51,
      // paddingVertical: 55,
    },
    detailsHeader: {
      ...TypographyStyle.heading4Bold,
      marginBottom: 10,
      textAlign: 'left',
    },
    detailsDescription: {
      ...TypographyStyle.Body2Regular,
      color: colors.black3,
      textAlign: 'left',
    },
    btnContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
    },
    btnBack: {
      width: 150,
      alignSelf: 'center',
      backgroundColor: colors.purple,
    },
    btnNext: {
      alignSelf: 'center',
      backgroundColor: colors.purple,
      width: '80%',
    },
    btnEditDetails: {
      alignSelf: 'center',
      backgroundColor: colors.white,
      borderWidth: 1,
      borderColor: colors.purple,
      width: '80%',
      marginBottom: 30,
    },
    btnTextEditDetails: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.purple,
        fontWeight: '700',
        textAlign: 'center',
      },
    ]),
    btnText: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.colorPrimary,
        fontWeight: '700',
        textAlign: 'center',
        paddingHorizontal: 110,
      },
    ]),
    btnTextHeader: {
      textAlign: 'left',
      color: colors.colorPrimary,
      width: 0,
    },
    inputV: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    detailsContainer: {
      flexDirection: 'column',
    },
    detailsContainer1: {
      flexDirection: 'column',
      marginTop: 70,
    },
    editBtnImage: { width: 15, height: 15, paddingRight: 30 },
    editPadding: { paddingRight: 30 },
    inputVdetailsRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: -20,
      marginTop: 20,
    },
    labelDetails: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.black3,
        paddingLeft: 30,
      },
    ]),
    w70: { width: '70%' },
    timeContainer: {
      width: '25%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    notarizeTime: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginTop: 14,
    },
    center: { alignSelf: 'center', justifyContent: 'center' },
    timeInput: { width: 40, marginTop: 0, marginLeft: 20 },
    colon: { ...TypographyStyle.heading4Bold, marginHorizontal: 8 },
    seconds: {
      marginTop: 8,
      borderRadius: 8,
      minHeight: 42,
      width: 40,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blurBG,
    },
    switchContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 8,
    },
    time: {
      width: 40,
      height: 42,
      marginTop: 8,
      borderWidth: 1,
      borderRadius: 10,
      borderColor: colors.border1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerText: {},
    w71: {},
    footerContainer: { marginTop: 50 },
    footerText: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.darkGray,
        fontWeight: '400',
        padding: 25,
        marginTop: -20,
      },
    ]),
    arrow: {
      color: colors.white,
      width: '24',
      height: '24',
      marginLeft: 20
    }
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {
      container: {
        // flex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        color: colors.white,
        // flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        // backgroundColor: colors.blurBG,
      },
      viewContainerSeeDetails: {
        width: '100%',
        height: '100%',
        // borderRadius: 10,
        backgroundColor: colors.white,
        paddingHorizontal: 0,
        paddingVertical: 0,
      },
      v: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 23,
      },
      header: {
        backgroundColor: colors.purple,
        top: 0,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        alignItems: 'center'
      },
      headerText: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.white,
          fontWeight: '700',
          padding: 15,
          flex: 1,
          marginLeft: -40
        },
      ]),
      v_field: { marginTop: 20 },
      w70: {
        marginTop: 100,
        paddingHorizontal: 30,
      },
      w71: {
        paddingHorizontal: 30,
        marginTop: 20,
      },
      timeContainer: {
        width: '100%',
        alignItems: 'flex-start',
        paddingLeft: 40,
      },
      notarizeTime: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: 14,
        marginLeft: -20,
      },
    },
  }
);

export default useStyles;
