import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@expo/vector-icons/MaterialIcons';
import { DatePicker } from '@mui/x-date-pickers';
import { View, Text } from 'react-native';
import useStyles from './styles.css';
import colors from '@assets/colors';
import _ from 'lodash';

function DatePicker01(props: any) {
  const styles = useStyles();
  const errLabelStyle = props.error ? { color: colors.red } : {};

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <View style={[styles.container, props.style]}>
        {!!props.label && (
          <Text style={[styles.label, props.labelStyle, errLabelStyle]}>
            {props.label}
          </Text>
        )}
        <DatePicker
          format={props.format}
          disablePast={props.disablePast}
          slotProps={{
            textField: {
              variant: 'outlined',
              error: !_.isEmpty(props.error),
              helperText: props.error,
              InputProps: {
                sx: [styles.inputSx, props.textField],
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      name="calendar-today"
                      size={14}
                      color={colors.purple}
                    />
                  </InputAdornment>
                ),
              },
            },
          }}
          onChange={props.onChange}
        />
      </View>
    </LocalizationProvider>
  );
}

export default DatePicker01;
