import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: '60%',
      height: '15%',
      borderRadius: 5,
      marginBottom: 20,
      flexDirection: 'row',
      backgroundColor: colors.white,
    },
    errorStyle: {
      borderWidth: 2,
      borderColor: colors.red,
    },
    textError: {
      right: 10,
      fontSize: 10,
      color: colors.red,
      position: 'absolute',
      alignSelf: 'center',
      fontFamily: 'Roboto_Regular',
    },
    input: {
      flex: 1,
      paddingLeft: 10,
      color: colors.black,
      fontSize: 16,
      fontFamily: 'Roboto_Regular',
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      input: {
        fontSize: 13,
      },
    },
    [DEVICE_SIZES.SM]: {
      input: {
        fontSize: 12,
      },
    },
    [DEVICE_SIZES.XS]: {
      input: {
        fontSize: 12,
      },
    },
  }
);

export default useStyles;
