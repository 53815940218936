import React, { useState, useReducer, useImperativeHandle, useContext, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { TextInput02 } from '@src/components/textinput';
import { WitnessValue } from './types';
import { LayerContext } from '../../signature-fields/provider/layer';
import Icon from '@expo/vector-icons/MaterialIcons';
import colors from '@assets/colors';
import validator from 'validator';
import useStyles from './styles';
import _ from 'lodash';

function reducer(state: any, action: any) {
  return { ...state, [action.type]: action.data };
}

type PrincipalItemProps = {
  item: WitnessValue;
  index: number;
  setName: (value: string) => void;
  setEmail: (value: string) => void;
  onRemove: () => void;
};

const WitnessItemMobile = React.forwardRef(
  ({ index, setEmail, setName, item, onRemove }: PrincipalItemProps) => {
    const styles = useStyles();
    const [error, setError] = useState<any>({});
    const [input, dispatch] = useReducer(reducer, item);
    const { setDocument, document } = useContext(LayerContext);

    const witnessName = useCallback((value: string) => {
      setDocument((updatedDocument: any) => ({
        ...updatedDocument,
        witnessName: value,
      }));
      setName(value)
    }, []);

    const witnessEmail = useCallback((value: string) => {
      setDocument((updatedDocument: any) => ({
        ...updatedDocument,
        witnessEmail: value,
      }));
      setEmail(value)
    }, []);

    const onContinue = () => {
      const newErr: any = {};

      if (!input.name) {
        newErr.name = 'This field is required';
      }
      if (!input.email) {
        newErr.email = 'This field is required';
      } else if (!validator.isEmail(input.email)) {
        newErr.email = 'Email format is invalid.';
      }

      setError(newErr);

      if (_.isEmpty(newErr)) {
        return { ...input };
      }

      return null;
    };

    return (
      <View style={styles.witnessContainer}>
        <View style={styles.witnessHeader}>
          <Text style={styles.witnessLabel}>Witness {index + 1}</Text>
          {index > 0 && (
            <TouchableOpacity onPress={onRemove}>
              <Icon name="delete" color={colors.purple} size={20} style={{paddingLeft: 2, marginTop: -1}}/>
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.witnessInputContainer}>
          <TextInput02
            label="Full Name"
            error={error.name}
            style={styles.input}
            placeholder="Enter full name"
            onChangeText={witnessName}
          />
          <View style={styles.v_divider} />
          <TextInput02
            label="Email"
            error={error.email}
            style={styles.input}
            placeholder="Enter email"
            onChangeText={witnessEmail}
          />
        </View>
      </View>
    );
  }
);

WitnessItemMobile.displayName = 'WitnessItemMobile';

export default React.memo(WitnessItemMobile);
