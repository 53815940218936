import React, { createContext, useCallback, useRef, useState } from 'react';
import * as Types from './types';
import Konva from 'konva';

interface CanvasContextValue {
  numPages: number;
  canvas: Types.CanvasProps;
  viewRef: any;
  stageRef: any;
  onUpdateCanvas: (item: Types.CanvasProps) => void;
  checkNumberOfPages: (value: number) => void;
}

export const FONT_SIZE = 15;
export const IMAGE_SIZE = 20;
export const LAYER_WIDTH = 130;
export const LAYER_HEIGHT = 50;

const CanvasContext = createContext<CanvasContextValue>({
  numPages: 0,
  canvas: {} as Types.CanvasProps,
  viewRef: {},
  stageRef: {},
  onUpdateCanvas: () => {},
  checkNumberOfPages: () => {},
} as CanvasContextValue);

const CanvasProvider = ({ children }: Types.DocumentProviderProps) => {
  const viewRef = useRef<any>();
  const [numPages, setNumPages] = useState<number>(0);
  const [canvas, setCanvas] = useState<Types.CanvasProps>(
    {} as Types.CanvasProps
  );
  const stageRef = useRef<React.RefObject<Konva.Stage>>();

  const onUpdateCanvas = useCallback(
    (item: Types.CanvasProps) => {
      setCanvas(item);
    },
    [canvas]
  );

  const checkNumberOfPages = useCallback(
    (pages: number) => {
      setNumPages(pages);
    },
    [numPages]
  );

  const value = {
    numPages,
    canvas,
    viewRef,
    stageRef,
    onUpdateCanvas,
    checkNumberOfPages,
  };

  return (
    <CanvasContext.Provider value={value}>{children}</CanvasContext.Provider>
  );
};

export { CanvasProvider, CanvasContext };
