import React, { useState, useCallback, useRef } from 'react';
import { View, Text } from 'react-native';
import { DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { Button01 } from '@src/components/button';
import useStyles from './style.css';
import { useNavigation } from '@react-navigation/native';

const SuccessPaymentDetails = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();

  const uploadDocument = () => {
    console.log('upload docs');
  };

  const nextBtn = () => {
    navigation.navigate('PaymentConfirmation')
  }

  return (
    <View>
      <View style={styles.textContainer}>
        <Text style={styles.txtNote1}>Successfully Paid to</Text>
        <Text style={styles.txtNote2}>Notary Public Name</Text>
        <View style={styles.textContainer2}>
          <Text style={styles.txtNote3}>Php 215.00</Text>
        </View>

        <View style={styles.paymentContainerDetails}>
          <View style={styles.divider} />
          <View style={styles.detailsContainer2}>
            <View style={styles.inputVdetailsRow}>
              <Text style={styles.labelDetails3}>Amount Due</Text>
              <Text style={styles.labelDetails3Padding}>Php 210.00</Text>
            </View>
          </View>
          <View style={[styles.detailsContainer2, { marginTop: 5 }]}>
            <View style={styles.inputVdetailsRow}>
              <Text style={styles.labelDetails3}>Payment Method</Text>
              <Text style={styles.labelDetails3Padding}>Gcash</Text>
            </View>
          </View>
          <View style={styles.divider} />
          <View style={styles.detailsContainer3}>
            <Text style={styles.txtNote4}>Ref.No. 000111222</Text>
            <Text style={styles.txtNote5}>27 December 2023. 11:11 AM</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default SuccessPaymentDetails;
