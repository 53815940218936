import React from 'react';
import colors from '@assets/colors';
import { Text } from 'react-konva';

type TextProps = {
  field: any;
};

const ItemText = (props: TextProps) => {
  const { field } = props;

  return (
    <React.Fragment>
      <Text
        align="center"
        x={field.x}
        y={field.y}
        text={field.text}
        rotation={field.rotation}
        fontSize={field.fontSize}
        fill={colors.black}
        fontFamily="DMSans"
        verticalAlign="bottom"
      />
    </React.Fragment>
  );
};

export default React.memo(ItemText);
