import React from 'react';
import { useDeviceSize, DEVICE_SIZES } from 'rn-responsive-styles';
import { View, Image, Text, Modal, Pressable } from 'react-native';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { useNavigation } from '@react-navigation/native';
import useStyles from './styles.css';
import { Button01 } from '@src/components/button';
import { colors, images } from 'theme';

const OnBoardingStep1 = () => {
  const styles = useStyles();
  const [isModalVisible, setIsModalVisible] = React.useState(true);
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();
  
  const playButton = () => {
    setIsModalVisible(false);
    navigation.navigate('PaymentPage');
  };

  const closeStep1 = () => {
    setIsModalVisible(false);
    navigation.navigate('OnBoarding')
  }

  return (
    <View style={styles.containerStep1}>
      <Modal transparent={true} visible={isModalVisible}>
        <View>
          <Button01
            onPress={closeStep1}
            style={{
              top: 30,
              right: 15,
              position: 'absolute',
              backgroundColor: 'none',
              width: 24,
              height: 24,
              opacity: 0.8,
            }}
            label="X"
            labelStyle={styles.xBtnLabel}
          ></Button01>
        </View>
        <View style={styles.playModal}>
          <View style={styles.imgContainerPlay}>
            <Pressable>
              <Image
                source={images.play}
                style={styles.play}
                resizeMode="cover"
              />
            </Pressable>
          </View>
        </View>
      </Modal>
      <View style={styles.v_logo_container}>
        <View style={styles.imgContainerStep1}>
          <Image
            source={images.get_started_step1}
            style={styles.get_started_step1}
            resizeMode="contain"
          />
        </View>
        <View style={styles.step1TextContainer}>
          <Text style={styles.text1}>STEP 1</Text>
          <Text style={styles.text2}>Upload Your Document</Text>
        </View>
        <View style={styles.step1TextContainer}>
          <Text
            style={styles.text3}
          >{`Begin the process by securely uploading your \n document to the NotarizeIT platform. Ensure \n it's ready for official notarization.`}</Text>
        </View>
      </View>
    </View>
  );
};

export default OnBoardingStep1;
