import React, { memo, useContext, useImperativeHandle } from 'react';
import { CanvasContext } from '../../provider/canvas';
import { Layer } from 'react-konva';
import Items from './items';

export const FONT_SIZE = 15;
export const IMAGE_SIZE = 20;
export const LAYER_WIDTH = 130;
export const LAYER_HEIGHT = 50;

const LayerCanvas = React.forwardRef(
  ({ item, onUpdateLayer }: any, ref: any) => {
    const { canvas } = useContext(CanvasContext);
    const [selectedId, selectShape] = React.useState(null);

    useImperativeHandle(ref, () => ({
      onClearSelected: () => selectShape(null),
    }));

    return (
      <Layer>
        {item.layerFields.map((field: any, index: number) => {
          return (
            <Items
              key={index.toString()}
              field={{
                ...field,
                x: field.x * canvas.scale,
                y: field.y * canvas.scale,
                width: field.width * canvas.scale,
                height: field.height * canvas.scale,
                fontSize: field.fontSize * canvas.scale,
              }}
              isSelected={field.id === selectedId}
              onDeselect={() => selectShape(null)}
              onRemove={() => {
                const rects = item.layerFields.slice();
                rects.splice(index, 1);
                onUpdateLayer({ ...item, layerFields: rects });
              }}
              onSelect={() => selectShape(field.id)}
              onChange={(newAttrs: any) => {
                const rects = item.layerFields.slice();
                rects[index] = newAttrs;
                onUpdateLayer({ ...item, layerFields: rects });
              }}
            />
          );
        })}
      </Layer>
    );
  }
);

export default memo(LayerCanvas);
