import React, { useContext } from 'react';
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';
import { RequestContext } from '../../RequestProvider';
import { useAppSelector } from '@src/ducks/ducksHook';
import Button from '@src/components/button/Button01';
import { View, Text } from 'react-native';
import useStyles from './styles.css';
import colors from '@assets/colors';

const ButtonRequest = () => {
  const styles = useStyles();
  const role = useAppSelector(selectedAuthRole);
  const { onCreateRequestPress } = useContext(RequestContext);

  if (role === 'principal') {
    return (
      <Button
        onPress={onCreateRequestPress}
        style={styles.btn_search}
        labelStyle={styles.btn_label}
        label="Create new request +"
      />
    );
  } 
  /* Hide this notif for MVP phase - Notary */
  // else if (role === 'notary') {
  //   return (
  //     <View style={styles.drawer_mobile_notif}>
  //       <View style={[styles.icon_notif, styles.relative]} />
  //       <Text style={[styles.drawer_mobile_textnotif, { color: colors.white }]}>
  //         You have a live notarization request!
  //       </Text>
  //     </View>
  //   );
  // } 
  else {
    return null;
  }
};

export default ButtonRequest;
