import { PDFDocument, rgb, PageSizes, degrees } from 'pdf-lib';
import Enotary from '@assets/images/auth/enotary_horizontal_color.png';
import SignBackground from '@assets/images/signature/sign_background.png';
import DMSansBold from '@assets/fonts/DMSans-Bold.ttf';
import QRCode from '@assets/images/base64/qrcode';
import DMSans from '@assets/fonts/DMSans.ttf';
import fontkit from '@pdf-lib/fontkit';
import { nanoid } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  getAuthorFromAuditTrail,
  getCompletedFromAuditTrail,
  getPartiesApprove,
  getPartiesHasExecute,
  getSignatoryLabels,
  getSinatoryAndApproverValue,
  getTextConfig,
  signCheckBoxPosition,
  signConfigPosition,
  signLogoAndDatePosition,
  signSingleTextPosition,
  signTextBoxPosition,
  signTextPosition,
} from './filter-helper';
import moment from 'moment';
import QRStyling from '@src/components/qr-code/qr-styling';

const headerColor = rgb(
  0.9607843137254902,
  0.9607843137254902,
  0.9607843137254902
);
const textColor = rgb(
  0.12941176470588237,
  0.13333333333333333,
  0.12941176470588237
);
const TOTAL_SIGNATORY_HEIGHT = 120;
const TOP_HEIGHT_DIVIDER = 12;

export const getCorner = (
  pivotX: number,
  pivotY: number,
  diffX: number,
  diffY: number,
  angle: number
) => {
  const distance = Math.sqrt(diffX * diffX + diffY * diffY);

  /// find angle from pivot to corner
  angle += Math.atan2(diffY, diffX);

  /// get new x and y and round it off to integer
  const x = pivotX + distance * Math.cos(angle);
  const y = pivotY + distance * Math.sin(angle);

  return { x: x, y: y };
};

export const getClientBox = (rotatedBox: any) => {
  const { x, y, width, height } = rotatedBox;
  const rad = rotatedBox.rotation;

  const p1 = getCorner(x, y, 0, 0, rad);
  const p2 = getCorner(x, y, width, 0, rad);
  const p3 = getCorner(x, y, width, height, rad);
  const p4 = getCorner(x, y, 0, height, rad);

  const minX = Math.min(p1.x, p2.x, p3.x, p4.x);
  const minY = Math.min(p1.y, p2.y, p3.y, p4.y);
  const maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
  const maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

  return {
    ...rotatedBox,
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
};

export const getTotalBox = (boxes: any) => {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  boxes.forEach((box: any) => {
    minX = Math.min(minX, box.x);
    minY = Math.min(minY, box.y);
    maxX = Math.max(maxX, box.x + box.width);
    maxY = Math.max(maxY, box.y + box.height);
  });
  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
};

export const scaleImage = (
  imageWidth: number,
  imageHeight: number,
  boxWidth: number,
  boxHeight: number
) => {
  // Calculate the aspect ratio of the image and the box
  const imageAspectRatio = imageWidth / imageHeight;
  const boxAspectRatio = boxWidth / boxHeight;

  let scaledWidth, scaledHeight;

  // Scale the image based on the aspect ratios
  if (imageAspectRatio > boxAspectRatio) {
    // The image is wider than the box
    scaledWidth = boxWidth;
    scaledHeight = boxWidth / imageAspectRatio;
  } else {
    // The image is taller than the box or has the same aspect ratio
    scaledWidth = boxHeight * imageAspectRatio;
    scaledHeight = boxHeight;
  }

  // Return the scaled dimensions
  return {
    width: scaledWidth,
    height: scaledHeight,
  };
};

export const generateQRCodePosition = (position: string, page: any) => {
  const { width, height } = page.getSize();
  const qr_size = 40;
  const qr_padding = 10;
  const qritem = {
    x: qr_padding,
    y: qr_padding,
    width: qr_size,
    height: qr_size,
  };

  switch (position) {
    case 'Bottom left':
      return qritem;
    case 'Bottom right':
      qritem.x = width - (qr_size + qr_padding);
      qritem.y = qr_padding;
      return qritem;
    case 'Top left':
      qritem.y = height - (qr_size + qr_padding);
      return qritem;
    case 'Top right':
      qritem.x = width - (qr_size + qr_padding);
      qritem.y = height - (qr_size + qr_padding);
      return qritem;
    default:
      return qritem;
  }
};

export const submitOnlyAffixSignature = async (pdfFile: any, document: any) => {
  const signatures = document?.workflow?.signatures ?? [];
  const pdfDoc = await PDFDocument.load(pdfFile);
  const DMSansFontBytes = await fetch(DMSans).then((res) => res.arrayBuffer());
  const SignSecureV2BlackBytes = await fetch(Enotary).then((res) =>
    res.arrayBuffer()
  );
  const SignBackgroundBytes = await fetch(SignBackground).then((res) =>
    res.arrayBuffer()
  );
  pdfDoc.registerFontkit(fontkit);

  const DMSansFont = await pdfDoc.embedFont(DMSansFontBytes);
  const SignSecureV2BlackImage = await pdfDoc.embedPng(SignSecureV2BlackBytes);
  const SignBackgroundImage = await pdfDoc.embedPng(SignBackgroundBytes);

  signatures.forEach((item: any, index: number) => {
    const pageIndex = pdfDoc.getPage(index);
    const { height } = pageIndex.getSize();

    item.forEach(async (field: any) => {
      if (['signature', 'initials'].includes(field.type) && field.signature) {
        const xy = signConfigPosition(field.config, height);
        const pngImage = await pdfDoc.embedPng(field.signature);
        pageIndex.drawImage(pngImage, {
          ...xy,
          width: field.config.width * 0.6,
          height: field.config.height * 0.67,
          rotate: degrees(-(field.config.rotation || 0)),
        });

        generateSignLogoAndID(
          pageIndex,
          field,
          document,
          SignSecureV2BlackImage,
          SignBackgroundImage,
          DMSansFont
        );
      } else if (
        ['signature-name', 'signature-date'].includes(field.type) &&
        field.signature
      ) {
        const textConfig = getTextConfig(field);
        const xy = signConfigPosition(field.config, height);
        const text_xy = signTextPosition(field.config, textConfig, height);
        const pngImage = await pdfDoc.embedPng(field.signature);
        pageIndex.drawImage(pngImage, {
          ...xy,
          width: field.config.width * 0.6,
          height: field.config.height * 0.67,
          rotate: degrees(-(field.config.rotation || 0)),
        });
        pageIndex.drawText(textConfig.text, {
          ...text_xy,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          size: textConfig.fontSize,
          rotate: degrees(-(field.config.rotation || 0)),
        });
        generateSignLogoAndID(
          pageIndex,
          field,
          document,
          SignSecureV2BlackImage,
          SignBackgroundImage,
          DMSansFont
        );
      } else if (
        field.type === 'signature-name-designation' &&
        field.signature
      ) {
        const xy = signConfigPosition(field.config, height);
        const pngImage = await pdfDoc.embedPng(field.signature);
        const name_xy = signTextPosition(
          field.config,
          field.nameConfig,
          height
        );
        const designation_xy = signTextPosition(
          field.config,
          field.designationConfig,
          height
        );
        pageIndex.drawImage(pngImage, {
          ...xy,
          width: field.config.width * 0.6,
          height: field.config.height * 0.67,
          rotate: degrees(-(field.config.rotation || 0)),
        });
        pageIndex.drawText(field.nameConfig.text, {
          ...name_xy,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          size: field.nameConfig.fontSize,
          rotate: degrees(-(field.config.rotation || 0)),
        });
        pageIndex.drawText(field.designationConfig.text, {
          ...designation_xy,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          size: field.designationConfig.fontSize,
          rotate: degrees(-(field.config.rotation || 0)),
        });
        generateSignLogoAndID(
          pageIndex,
          field,
          document,
          SignSecureV2BlackImage,
          SignBackgroundImage,
          DMSansFont
        );
      } else if (
        ['full-name', 'designation', 'date-time'].includes(field.type)
      ) {
        const textConfig = getTextConfig(field);
        const xy = signSingleTextPosition(field.config, textConfig, height);
        pageIndex.drawText(textConfig.text, {
          ...xy,
          size: textConfig.fontSize,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          rotate: degrees(-(field.config.rotation || 0)),
        });
      } else if (field.type === 'textbox') {
        const textConfig = getTextConfig(field);
        const xy = signTextBoxPosition(field.config, textConfig, height);
        pageIndex.drawRectangle({
          x: xy.boxX,
          y: xy.boxY,
          color: headerColor,
          width: field.config.width,
          height: field.config.height,
          rotate: degrees(-(field.config.rotation || 0)),
        });

        pageIndex.drawText(textConfig.text, {
          x: xy.x,
          y: xy.y,
          size: textConfig.fontSize,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          rotate: degrees(-(field.config.rotation || 0)),
        });
      } else if (field.type === 'checkbox') {
        const config = field.config;
        const checkConfig = field.checkConfig;
        const textConfig = getTextConfig(field);
        const xy = signCheckBoxPosition(
          config,
          textConfig,
          checkConfig,
          height
        );
        pageIndex.drawRectangle({
          x: xy.checkX,
          y: xy.checkY,
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          width: checkConfig.width,
          height: checkConfig.height,
        });
        pageIndex.drawText(textConfig.text, {
          x: xy.textX,
          y: xy.textY,
          size: textConfig.fontSize,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          rotate: degrees(-(field.config.rotation || 0)),
        });
      }
    });
  });

  return await pdfDoc.saveAsBase64();
};

export const completeSignatureWithCreateManifest = async (
  pdfFile: any,
  document: any
) => {
  const pdfDoc = await PDFDocument.load(pdfFile);
  const DMSansFontBytes = await fetch(DMSans).then((res) => res.arrayBuffer());
  const DMSansBoldFontBytes = await fetch(DMSansBold).then((res) =>
    res.arrayBuffer()
  );
  pdfDoc.registerFontkit(fontkit);

  const DMSansFont = await pdfDoc.embedFont(DMSansFontBytes);
  const DMSansBoldFont = await pdfDoc.embedFont(DMSansBoldFontBytes);

  await qrcodePerPages(pdfDoc, document); // GENERATE ON THE FIRST CURRENT PAGES BEFORE CREATING MANIFEST
  await createManifest(pdfDoc, document, DMSansFont, DMSansBoldFont);

  return await pdfDoc.saveAsBase64();
};

export const createManifest = async (
  pdfDoc: any,
  document: any,
  DMSansFont: any,
  DMSansBoldFont: any
) => {
  const documentId = document.id;
  const SignSecureV2Bytes = await fetch(Enotary).then((res) =>
    res.arrayBuffer()
  );
  const qrcode = await QRStyling(100, 100, documentId);
  const QRCodeEmbed = await pdfDoc.embedPng(qrcode);

  const pageCount = pdfDoc.getPageCount(); // Get page count

  // Add a blank page to the document
  const page = pdfDoc.addPage(PageSizes.Letter);
  const { height } = page.getSize(); // Get the height of the page

  qrcodeSection(page, QRCodeEmbed, DMSansFont);

  // HEADER MANIFEST
  const SignSecureV2Embed = await pdfDoc.embedPng(SignSecureV2Bytes);
  page.drawImage(SignSecureV2Embed, {
    x: 262,
    y: height - 52,
    width: 88,
    height: 20,
  });
  page.drawText('M A N I F E S T', {
    x: 248,
    y: height - 100,
    size: 20,
    width: 116,
    font: DMSansBoldFont,
    color: textColor,
  });

  await documentSection(page, document, DMSansFont, DMSansBoldFont, pageCount); // DOCUMENT SECTION
  const result = await signatorySection(
    page,
    pdfDoc,
    document,
    DMSansFont,
    DMSansBoldFont
  ); // SIGNATORY SECTION

  if (result.height_per_signatory > 672) {
    const newpage = pdfDoc.addPage(PageSizes.Letter);
    qrcodeSection(newpage, QRCodeEmbed, DMSansFont);
    await approverSection(
      {
        newpage,
        height_per_signatory: 100,
      },
      pdfDoc,
      document,
      DMSansFont,
      DMSansBoldFont
    ); // SIGNATORY SECTION
  } else {
    await approverSection(result, pdfDoc, document, DMSansFont, DMSansBoldFont); // SIGNATORY SECTION
  }

  return page;
};

// CREATE SIGNATURE LOGO WITH ID AND DATES
const generateSignLogoAndID = (
  pageIndex: any,
  field: any,
  document: any,
  SignSecureV2BlackImage: any,
  SignBackgroundImage: any,
  DMSansFont: any
) => {
  const { height } = pageIndex.getSize();
  const width = field.config.width;
  const heigh1_3rd = field.config.height * 0.3;
  const heigh1_7th = field.config.height * 0.7;
  const dateFontSize = heigh1_3rd * 0.2;
  const pos = signLogoAndDatePosition(
    field.config,
    heigh1_3rd,
    heigh1_7th,
    dateFontSize,
    height
  );

  pageIndex.drawImage(SignBackgroundImage, {
    x: pos.x,
    y: pos.y + heigh1_7th,
    width: width,
    height: heigh1_3rd,
    rotate: degrees(-(field.config.rotation || 0)),
  });
  pageIndex.drawImage(SignSecureV2BlackImage, {
    x: pos.logoX,
    y: pos.logoY,
    width: field.config.width * 0.308,
    height: heigh1_3rd * 0.6,
    rotate: degrees(-(field.config.rotation || 0)),
  });
  pageIndex.drawText(
    `${document.id}  |  ${moment(field.signatureDate).format(
      'YYYY.MM.DD  |  hh:mm:ss'
    )}`,
    {
      x: pos.textX,
      y: pos.textY,
      font: DMSansFont,
      color: textColor,
      size: dateFontSize,
      rotate: degrees(-(field.config.rotation || 0)),
    }
  );
};

// GENERATE QR CODE ON EACH PAGES
const qrcodePerPages = async (pdfDoc: any, document: any) => {
  const documentId = document.id;
  const qrPosition = document.workflow.qrPosition;
  const qrcode = await QRStyling(100, 100, documentId);
  const pngImage = await pdfDoc.embedPng(qrcode);

  pdfDoc.getPages().forEach((item: any, index: number) => {
    const pageIndex = pdfDoc.getPage(index);

    const qrconfig = generateQRCodePosition(qrPosition, pageIndex);
    pageIndex.drawImage(pngImage, qrconfig);
  });
};

// CREATE DOCUMENT SECTION
const documentSection = async (
  page: any,
  document: any,
  DMSansFont: any,
  DMSansBoldFont: any,
  pageCount: number
) => {
  const { width, height } = page.getSize();
  const author = getAuthorFromAuditTrail(document);
  const audit = getCompletedFromAuditTrail(document);

  // HEADER BAR (DOCUMENT DETAILS)
  page.drawRectangle({
    x: 25,
    height: 30,
    y: height - 157,
    width: width - 50,
    color: headerColor,
  });
  page.drawText('Document Details', {
    x: 38,
    y: height - 148,
    size: 14,
    font: DMSansBoldFont,
    color: textColor,
  });

  // DOCUMENT DETAILS INFO
  page.drawText('Folder Name:', {
    x: 38,
    y: height - 179,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(document.folderName || 'N/A', {
    x: 102,
    y: height - 179,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText('Document Title:', {
    x: 38,
    y: height - 199,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(document.name, {
    x: 113,
    y: height - 199,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText('Date Created:', {
    x: 38,
    y: height - 219,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(moment(document.createdAt).format('MMMM DD, YYYY'), {
    x: 104,
    y: height - 219,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText('Document Pages:', {
    x: 38,
    y: height - 239,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(String(pageCount), {
    x: 121,
    y: height - 239,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText('Date of Completion:', {
    x: 38,
    y: height - 259,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(moment(audit.timestamp).format('MMMM DD, YYYY  |  hh:mm A'), {
    x: 133,
    y: height - 259,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText('Time Zone:', {
    x: 38,
    y: height - 279,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(author.timezone, {
    x: 90,
    y: height - 279,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText('Author:', {
    x: 337,
    y: height - 179,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(author?.author?.name, {
    x: 373,
    y: height - 179,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText('Email:', {
    x: 337,
    y: height - 199,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(author?.author?.email, {
    x: 366,
    y: height - 199,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText('IP Address:', {
    x: 337,
    y: height - 219,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(author.ip, {
    x: 390,
    y: height - 219,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });

  return page;
};

const signatorySection = async (
  page: any,
  pdfDoc: any,
  document: any,
  DMSansFont: any,
  DMSansBoldFont: any
) => {
  const { width, height } = page.getSize();
  const parties = getPartiesHasExecute(document);
  const documentId = document.id;
  const qrcode = await QRStyling(100, 100, documentId);
  const QRCodeEmbed = await pdfDoc.embedPng(qrcode);

  if (parties.length > 0) {
    // HEADER BAR (DOCUMENT DETAILS)
    page.drawRectangle({
      x: 25,
      height: 30,
      y: height - 352,
      width: width - 50,
      color: headerColor,
    });
    page.drawText('Signatory', {
      x: 38,
      y: height - 343,
      size: 14,
      font: DMSansBoldFont,
      color: textColor,
    });
  }

  let count = 0;
  let newpage = page;
  let offset = 364;
  let height_per_signatory = 0;

  parties.forEach(async (value: any, key: number) => {
    height_per_signatory = count * TOTAL_SIGNATORY_HEIGHT;
    const params: any = {
      value,
      DMSansFont,
      DMSansBoldFont,
    };

    if (height_per_signatory + offset > 672) {
      count = 0;
      height_per_signatory = 0;
      offset = 100;
      newpage = pdfDoc.addPage(PageSizes.Letter);
      qrcodeSection(newpage, QRCodeEmbed, DMSansFont);
    }

    params.index = key;
    params.offset = offset;
    params.height_per_signatory = height_per_signatory;
    params.page = newpage;

    signatory(params);

    count += 1;
  });

  return {
    newpage,
    height_per_signatory:
      parties.length > 0
        ? height_per_signatory +
          offset +
          TOTAL_SIGNATORY_HEIGHT +
          TOP_HEIGHT_DIVIDER
        : 352,
  };
};

const approverSection = async (
  result: any,
  pdfDoc: any,
  document: any,
  DMSansFont: any,
  DMSansBoldFont: any
) => {
  const { newpage: page, height_per_signatory: new_height_per_signatory } =
    result;
  const { width, height } = page.getSize();
  const approver = getPartiesApprove(document);
  const documentId = document.id;
  const qrcode = await QRStyling(100, 100, documentId);
  const QRCodeEmbed = await pdfDoc.embedPng(qrcode);

  if (approver.length === 0) {
    return;
  }

  // HEADER BAR (DOCUMENT DETAILS)
  page.drawRectangle({
    x: 25,
    height: 30,
    y: height - new_height_per_signatory + 15,
    width: width - 50,
    color: headerColor,
  });
  page.drawText('Approver', {
    x: 38,
    size: 14,
    y: height - new_height_per_signatory + 15 + 9,
    font: DMSansBoldFont,
    color: textColor,
  });

  let count = 0;
  let newpage = page;
  let offset = new_height_per_signatory;
  let height_per_signatory = 0;

  approver.forEach(async (value: any, key: number) => {
    height_per_signatory = count * TOTAL_SIGNATORY_HEIGHT;
    const params: any = {
      value,
      DMSansFont,
      DMSansBoldFont,
    };

    if (height_per_signatory + offset > 672) {
      count = 0;
      height_per_signatory = 0;
      offset = 100;
      newpage = pdfDoc.addPage(PageSizes.Letter);
      qrcodeSection(newpage, QRCodeEmbed, DMSansFont);
    }

    params.index = key;
    params.offset = offset;
    params.height_per_signatory = height_per_signatory;
    params.page = newpage;

    signatory(params);
    count += 1;
  });

  return {
    newpage,
    height_per_signatory,
  };
};

// RE-USABLE SIGNATORY DETAILS
const signatory = async (props: any) => {
  const {
    page,
    index,
    height_per_signatory,
    value,
    offset,
    DMSansFont,
    DMSansBoldFont,
  } = props;
  const { height } = page.getSize();
  const labels = getSignatoryLabels();

  if (index > 0) {
    const line_y = height_per_signatory + offset - 21;

    page.drawRectangle({
      x: 37,
      height: 2,
      width: 519,
      y: height - line_y,
      color: headerColor,
    });
  }

  labels.forEach((item: any, key: number) => {
    const y = key * 20 + offset + item.size + height_per_signatory;

    page.drawText(item.label1, {
      x: item.l1_x,
      y: height - y,
      size: item.size,
      font: DMSansFont,
      color: textColor,
    });
    page.drawText(getSinatoryAndApproverValue(item.label1, value), {
      x: item.l1_value_x,
      y: height - y,
      size: item.size,
      color: textColor,
      font: DMSansBoldFont,
    });
    page.drawText(item.label2, {
      x: item.l2_x,
      y: height - y,
      size: item.size,
      font: DMSansFont,
      color: textColor,
    });
    page.drawText(getSinatoryAndApproverValue(item.label2, value), {
      x: item.l2_value_x,
      y: height - y,
      size: item.size,
      font: DMSansBoldFont,
      color: textColor,
    });
  });
};

const qrcodeSection = (page: any, QRCodeEmbed: any, DMSansFont: any) => {
  page.drawText('This document was signed with SignSecure™', {
    x: 375,
    y: 44,
    size: 10,
    font: DMSansFont,
    color: rgb(0.4, 0.4, 0.4),
  });
  page.drawText('Scan the QR code to verify document', {
    x: 375,
    y: 31,
    size: 10,
    font: DMSansFont,
    color: rgb(0.4, 0.4, 0.4),
  });

  page.drawImage(QRCodeEmbed, {
    x: 25,
    y: 26,
    width: 37,
    height: 37,
  });
};

export const affixSignature = async (pdfFile: any, signatures: any[]) => {
  const pdfDoc = await PDFDocument.load(pdfFile);
  const DMSansFontBytes = await fetch(DMSans).then((res) => res.arrayBuffer());
  pdfDoc.registerFontkit(fontkit);

  const DMSansFont = await pdfDoc.embedFont(DMSansFontBytes);

  await createManifest(pdfDoc, DMSansFont);

  signatures.forEach((item: any, index: number) => {
    const pageIndex = pdfDoc.getPage(index);
    const { height } = pageIndex.getSize();
    item.layerFields.forEach(async (field: any) => {
      if (
        ['signature', 'initials', 'signature_notary'].includes(field.type) &&
        field.signature
      ) {
        const xy = signOnlyPosition(field, height);
        const pngImage = await pdfDoc.embedPng(field.signature);
        pageIndex.drawImage(pngImage, {
          ...xy,
          width: field.width,
          height: field.height,
          rotate: degrees(-(field.rotation || 0)),
        });
      } else if (
        ['signature_fullname', 'signature_date'].includes(field.type) &&
        field.signature
      ) {
        const xy = signTextPosition(field, height);
        const text_xy = signTextOnlyPosition(field, height);
        const pngImage = await pdfDoc.embedPng(field.signature);
        pageIndex.drawImage(pngImage, {
          ...xy,
          width: field.width,
          height: field.height,
          rotate: degrees(-(field.rotation || 0)),
        });
        pageIndex.drawText(field.text, {
          ...text_xy,
          size: field.fontSize,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          rotate: degrees(-(field.rotation || 0)),
        });
      } else if (
        ['full_name', 'designation', 'date_time'].includes(field.type)
      ) {
        pageIndex.drawText(field.text, {
          x: field.x,
          y: height - (field.y + field.fontSize),
          size: field.fontSize,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          rotate: degrees(-(field.rotation || 0)),
        });
      }
    });
  });

  return await pdfDoc.saveAsBase64();
};

export const createManifestold = async (pdfDoc: any, DMSansFont: any) => {
  const EnotaryBytes = await fetch(Enotary).then((res) => res.arrayBuffer());

  // Add a blank page to the document
  const page = pdfDoc.addPage(PageSizes.Folio);

  // Get the width and height of the page
  const { height } = page.getSize();

  // HEADER
  const EnotaryEmbed = await pdfDoc.embedPng(EnotaryBytes);
  const EnotaryScale = EnotaryEmbed.scale(0.4);
  page.drawImage(EnotaryEmbed, {
    x: 50,
    y: height - 90,
    width: EnotaryScale.width,
    height: EnotaryScale.height,
  });
  page.drawText('Manifest', {
    x: page.getWidth() - 120,
    y: height - 90,
    size: 20,
    font: DMSansFont,
    color: rgb(0.267, 0.267, 0.608),
  });

  // LINE DRAW
  page.drawRectangle({
    x: 50,
    y: height - 115,
    width: page.getWidth() - 100,
    height: 0.7,
    borderColor: rgb(0, 0, 0),
  });

  // RECTANGLE DRAW
  page.drawRectangle({
    x: 50,
    y: height - (130 + 116),
    width: page.getWidth() - 100,
    height: 116,
    color: rgb(0.808, 0.808, 0.867),
  });

  // DOCUMENT INFO
  page.drawText('Created:', {
    x: 70,
    y: height - 160,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });
  page.drawText('2023-04-01', {
    x: 200,
    y: height - 160,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });
  page.drawText('By:', {
    x: 70,
    y: height - 180,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });
  page.drawText('Juan Dela Cruz (jdelacruz@email.com)', {
    x: 200,
    y: height - 180,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });
  page.drawText('Status:', {
    x: 70,
    y: height - 200,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });
  page.drawText('Approved', {
    x: 200,
    y: height - 200,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });
  page.drawText('Transaction ID:', {
    x: 70,
    y: height - 220,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });
  page.drawText('OGOTKBKDCRMSCKFVMSL_564243', {
    x: 200,
    y: height - 220,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  // DOCUMENT CREATED BY
  const fontSize = 12;
  page.drawText('Document created by Juan Dela Cruz (jdelacruz@email.com)', {
    x: 50,
    y: height - 24 * fontSize,
    size: fontSize,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  page.drawText('2023-04-01 - 7:22:01 pm', {
    x: 50,
    y: height - 25.5 * fontSize,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  // DOCUMENT SENT TO
  page.drawText('Document sent to Ann  Santos (annsantos@email.com)', {
    x: 50,
    y: height - 29 * fontSize,
    size: fontSize,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  page.drawText('2023-04-01 - 7:22:01 pm', {
    x: 50,
    y: height - 30.5 * fontSize,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  // DOCUMENT VIEWED BY
  page.drawText('Document viewed by Ann Santos (annsantos@email.com)', {
    x: 50,
    y: height - 34 * fontSize,
    size: fontSize,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  page.drawText('2023-04-01 - 7:22:01 pm', {
    x: 50,
    y: height - 35.5 * fontSize,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  // DOCUMENT NOTARIZED BY
  page.drawText('Document notarized by Ann Santos (annsantos@email.com)', {
    x: 50,
    y: height - 39 * fontSize,
    size: fontSize,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  page.drawText('2023-04-01 - 7:22:01 pm', {
    x: 50,
    y: height - 40.5 * fontSize,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  // DOCUMENT COMPLETED
  page.drawText('Document completed', {
    x: 50,
    y: height - 44 * fontSize,
    size: fontSize,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  page.drawText('2023-04-01 - 7:22:01 pm', {
    x: 50,
    y: height - 45.5 * fontSize,
    size: 11,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  // DOCUMENT CREATED INFO
  page.drawText(
    'Document created on 03/01/2023 - 07:22 pm by Juan Test | jdelacruz@email.com Total no. of pages (excl. Manifest): 1 page/s \nSignatures completed by all parties on 03/22/2023 - 10:52 am',
    {
      x: 50,
      y: height - 60 * 10,
      size: 10,
      ySkew: degrees(10),
      font: DMSansFont,
      color: rgb(0.522, 0.522, 0.522),
    }
  );

  // DOCUMENT SignSecure™
  page.drawText('This document was signed with SignSecure™', {
    x: 50,
    y: 76,
    size: 9,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });
  page.drawText('Scan the QR code to verify document', {
    x: 50,
    y: 60,
    size: 9,
    font: DMSansFont,
    color: rgb(0, 0, 0),
  });

  const QRCodeEmbed = await pdfDoc.embedPng(QRCode);
  page.drawImage(QRCodeEmbed, {
    x: page.getWidth() - 100,
    y: 50,
    width: 50,
    height: 50,
  });

  return page;
};

const QR_SIZE = 55;
const QR_PADDING_RIGHT = 15;

export const qrcodePosition = (
  position: string,
  canvas_width: number,
  canvas_height: number
) => {
  const id = nanoid();

  let qritem = {
    id,
    qrvalue: id,
    type: 'qrcode_sign',
    x: 10,
    y: 10,
    width: QR_SIZE,
    height: QR_SIZE,
  };

  switch (position) {
    case 'Top left':
      return qritem;
    case 'Top right':
      qritem.x = canvas_width - (QR_SIZE + QR_PADDING_RIGHT);
      return qritem;
    case 'Bottom left':
      qritem.y = canvas_height - (QR_SIZE + QR_PADDING_RIGHT);
      return qritem;
    case 'Bottom right':
      qritem.x = canvas_width - (QR_SIZE + QR_PADDING_RIGHT);
      qritem.y = canvas_height - (QR_SIZE + QR_PADDING_RIGHT);
      return qritem;
    default:
      return qritem;
  }
};

export const dragPosition = (
  position: string,
  canvas_width: number,
  canvas_height: number
) => {
  const id = nanoid();

  let qritem = {
    id,
    qrvalue: id,
    type: 'qrcode_sign',
    x: 10,
    y: 10,
    width: QR_SIZE,
    height: QR_SIZE,
  };

  switch (position) {
    case 'Top left':
      return qritem;
    case 'Top right':
      qritem.x = canvas_width - (QR_SIZE + QR_PADDING_RIGHT);
      return qritem;
    case 'Bottom left':
      qritem.y = canvas_height - (QR_SIZE + QR_PADDING_RIGHT);
      return qritem;
    case 'Bottom right':
      qritem.x = canvas_width - (QR_SIZE + QR_PADDING_RIGHT);
      qritem.y = canvas_height - (QR_SIZE + QR_PADDING_RIGHT);
      return qritem;
    default:
      return qritem;
  }
};

export const getViewport = (canvas: any, scale: number) => {
  if (_.isEmpty(canvas)) {
    return {
      CANVAS_WIDTH: 612 * scale,
      CANVAS_HEIGHT: 936 * scale,
    };
  }

  return {
    CANVAS_WIDTH: canvas.width * canvas.scale,
    CANVAS_HEIGHT: canvas.height * canvas.scale,
  };
};
