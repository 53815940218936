/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';
import Dropdown from '@src/components/dropdown/drop-down';
import { WitnessScreenContext } from '../provider/screen';
// import { WitnessContext } from '../../provider/witness';
// import { HeaderContext } from '../../provider/header';
import { useAppSelector } from '@src/ducks/ducksHook';
import { View, Text, Pressable } from 'react-native';
import { Button01 } from '@src/components/button';
import { LayerContext } from '../provider/layer';
import Icon from '@src/components/icon';
import useStyles from './styles.css';
import colors from '@assets/colors';
import moment from 'moment';
import Drag from './drag';
import { LiveSessionContext } from '../../provider';

const Panel = () => {
  const styles = useStyles();
  // const email = useAppSelector(selectUserEmail);
  // const name = useAppSelector(selectUserFullname);
  // const { onNext } = React.useContext(HeaderContext);
  // const { witnesses } = React.useContext(WitnessContext);
  const { onHideAddSignature } = React.useContext(LiveSessionContext);
  const { onBack } = React.useContext(WitnessScreenContext);
  const {
    layers,
    qrposition,
    selectedUser,
    participants,
    appendOnLayers,
    updateQRPosition,
    updateSelectedUser,
  } = React.useContext(LayerContext);
  const [selectedField, setSelectedField] = useState<any>()
  const Users = [
    {
      name: 'Arjay Hufancia',
      email: `arjay@aa.asia`,
      fill: colors.signature1,
    },
    {
      name: 'Randolf Dini-ay',
      email: 'rdiniay@unawa.asia',
      fill: colors.signature2,
    },
  ]  //].concat(witnesses);

  const isSelectedByType = (type: string) => {
    const hasSelectedField = layers && layers[0]?.layerFields
        .filter((field: any) => selectedUser?.name?.includes(field?.name) && field?.type === type).length
    return hasSelectedField>0 ? hasSelectedField : selectedField?.type === type
  }

  const onDragItem = React.useCallback(
    (item: any) => {
      const addparams = {
        ...item,
        name: selectedUser.name,
        fill: selectedUser.fill,
        email: selectedUser.email,
        userType: selectedUser.userType,
      };

      switch (item.type) {
        case 'signature':
        case 'initials':
        case 'qrcode_sign':
          appendOnLayers({
            ...addparams,
          });
          break;
        case 'signature_fullname':
          appendOnLayers({
            ...addparams,
            text: addparams.name,
          });
          break;
        case 'signature_date':
          appendOnLayers({
            ...addparams,
            text: moment().format('MM/DD/YYYY hh:mm:ss A'),
          });
          break;
        case 'full_name':
          appendOnLayers({
            ...addparams,
            text: addparams.name,
          });
          break;
        case 'designation':
          appendOnLayers({
            ...addparams,
            text: addparams.designation,
          });
          break;
        case 'date_time':
          appendOnLayers({
            ...addparams,
            text: moment().format('MM/DD/YYYY hh:mm:ss A'),
          });
          break;
        case 'textbox':
          appendOnLayers({
            ...addparams,
            text: 'Type here...',
          });
          break;
      }
    },
    [selectedUser, appendOnLayers]
  );

  const onConfirm = useCallback(() => {
    // onLiveVideoSession()
    onHideAddSignature()
    onDragItem(selectedField)
  },[selectedField])

  return (
    <View style={styles.panel_container}>
      <Text style={styles.t_document_field}>Document Fields</Text>
      {/* <Dropdown
        iconSize={50}
        options={['Top left', 'Top right', 'Bottom left', 'Bottom right']}
        name="arrow-drop-down"
        value={qrposition}
        onSelected={updateQRPosition}
        renderItem={(props: any) => (
          <Text key={`${props.index}`} style={styles.t_label}>
            {props.item}
          </Text>
        )}
        renderBase={(props: any) => (
          <Pressable onPress={props.onPress} style={styles.v_qrcode}>
            <Text selectable={false} style={styles.t_label}>
              {props.value}
            </Text>
            <Icon name={props.name} size={props.iconSize} color={props.color} />
          </Pressable>
        )}
      />
      <Text style={styles.t_add_field}>ADD FIELDS FOR</Text> */}
      <Dropdown
        default
        iconSize={50}
        options={participants}
        name="arrow-drop-down"
        value={selectedUser.name}
        color={selectedUser.fill}
        onSelected={updateSelectedUser}
      />
      <View style={{ marginTop: 50, }} />
      {/* <View style={styles.v_title}>
        <Text style={styles.t_document}>DOCUMENT FIELDS</Text>
        <Text style={styles.t_dragndrop}>
          <Text style={styles.t_dragndrop_bold}>Drag and drop</Text> the field
          into the document
        </Text>
      </View> */}
      <Drag
        name="edit"
        label="Signature"
        type="signature"
        selected={isSelectedByType("signature")}
        onDrag={setSelectedField}
      />
      <Drag
        name="edit"
        label="Signature & Full Name"
        type="signature_fullname"
        selected={isSelectedByType("signature_fullname")}
        onDrag={setSelectedField}
      />
      <Drag
        name="edit"
        label="Signature & Date"
        type="signature_date"
        selected={isSelectedByType("signature_date")}
        onDrag={setSelectedField}
      />
      <Drag
        name="text"
        label="Full Name"
        type="full_name"
        selected={isSelectedByType("full_name")}
        onDrag={setSelectedField}
      />
      <Drag
        name="initial"
        label="Initial"
        type="initials"
        selected={isSelectedByType("initials")}
        onDrag={setSelectedField}
      />
      <Drag
        name="date"
        label="Date & Time"
        type="date_time"
        selected={isSelectedByType("date_time")}
        onDrag={setSelectedField}
      />
      {/* <Drag name="date" label="Textbox" type="textbox" onDrag={onDragItem} /> */}
      <View style={{ flexDirection: 'row' }}>
        <Button01
          label="Back"
          onPress={onHideAddSignature}
          style={styles.btnBack}
          labelStyle={{ color: colors.purple }}
        />
        <Button01 onPress={onConfirm} style={styles.btnConfirm} label="Confirm" />
      </View>
      <View style={styles.v_height} />
    </View>
  );
};

export default React.memo(Panel);
