import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useSignsecureService } from '@src/ducks/hooks';
import { selectedViewDocument } from "@src/ducks/slices/signsecure.slice";
import * as Types from './types';
import moment from 'moment';
import _ from 'lodash';
// import { useAppSelector } from '@src/ducks/ducksHook';
import { selectCreateRequestData } from '@src/ducks/slices/signsecure.slice';
import { LayerContext } from '../../signature-fields/provider/layer';
import { useAppSelector } from "@src/ducks/ducksHook";


type SelectedItemValue = {
  layerId: string | number;
  fieldId: string | number;
  type: string;
};

interface ContextValue {
  showSignature: boolean;
  onShowModal: (value: SelectedItemValue) => void;
  onSetSignature: (id: Types.SignatureValue) => void;
  setShowSignature: Dispatch<SetStateAction<boolean>>;
}

const SignatureContext = createContext<ContextValue>({} as ContextValue);

const DOCSTAMP = [
  {
    id: '9aqqUpoIlW1OqczK-pNYO',
    type: 'full_name',
    x: 73.94791666666667,
    y: 571.3333333333334,
    fontSize: 10,
    fill: '#FF9F00',
    text: 'NOTARY PUBLIC',
  },
  {
    id: 'wrjztDQFlgRIO14953tBS',
    type: 'full_name',
    x: 73.94791666666667,
    y: 604.6666666666666,
    fontSize: 10,
    fill: '#FF9F00',
    text: 'Doc No. ______;',
  },
  {
    id: 'tKJQFjKmS_tEjllv49KuI',
    type: 'full_name',
    x: 73.94791666666667,
    y: 638.6666666666666,
    fontSize: 10,
    text: 'Page No. ______;',
  },
  {
    id: '5_8JK-P8vsn7rVRGz9sLK',
    type: 'full_name',
    x: 73.94791666666667,
    y: 671.3333333333334,
    fontSize: 10,
    text: 'Book No. ______;',
  },
  {
    id: 'CGIKd4h_h3ta94D9Dj_zs',
    type: 'full_name',
    x: 73.94791666666667,
    y: 706,
    fontSize: 10,
    text: 'Series of ______;',
  },
];

const SignatureProvider = ({ children }: Types.DocumentProviderProps) => {
  const { updateSignatures } = useSignsecureService();
  // const document = useAppSelector(selectCreateRequestData);

  const [selectedItem, setSelectedItem] = useState<SelectedItemValue>(
    {} as SelectedItemValue
  );
  const [showSignature, setShowSignature] = useState<boolean>(false);
  const document = useAppSelector(selectedViewDocument);
  const { layers, updateLayers, } = React.useContext(LayerContext);

  const onShowModal = useCallback(
    (params: SelectedItemValue) => {
      setSelectedItem(params);
      setShowSignature(true);
    },
    [selectedItem]
  );

  const onSetSignature = useCallback(
    (signature: Types.SignatureValue) => {
      let affixLayers: any = [...layers]
      let layer = affixLayers[selectedItem.layerId]
      layer.layerFields[selectedItem.fieldId] = {
        ...layer.layerFields[selectedItem.fieldId],
        ...signature,
        signatureDate: +moment(),
      }
      affixLayers[selectedItem.layerId] = layer
      console.log(`affixLayers: `, affixLayers)
      updateLayers(affixLayers)
      setShowSignature(false);
      // return
      // const cloneDocument = _.cloneDeep(document);
      // const nlayers: any = cloneDocument.workflow.signatures.slice();
      // const nfields = nlayers[selectedItem.layerId];
      // nfields[selectedItem.fieldId] = {
      //   ...nfields[selectedItem.fieldId],
      //   ...signature,
      //   signatureDate: +moment(),
      // };

      // nlayers[selectedItem.layerId] = nfields;
      // cloneDocument.workflow.signatures = nlayers;
      // cloneDocument.signature = signature;

      // updateSignatures(cloneDocument);
      // setShowSignature(false);
    },
    [selectedItem, document]
  );

  const value = {
    showSignature,
    document,
    setShowSignature,
    onShowModal,
    onSetSignature,
  };

  return (
    <SignatureContext.Provider value={value}>
      {children}
    </SignatureContext.Provider>
  );
};

export { SignatureProvider, SignatureContext };
