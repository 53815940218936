// DUCKS pattern
import { createAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@src/ducks/store';
import { DashboardValue, ErrorValue } from '../types';

export interface SignupState {
  loading: boolean;
  data: {};
  error: ErrorValue;
}

export const initialState: SignupState = {
  loading: false,
  data: {},
  error: {} as ErrorValue,
};

// Slice
// 3. Create Slice
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    dashboardRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    dashboardSuccess: (state, action) => {
      state.data = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
    },
    dashboardFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// Actions
// 2. Create Action
export const dashboardActions = {
  dashboardRequest: createAction(
    `${dashboardSlice.name}/dashboardRequest`,
    (params: DashboardValue) => ({
      payload: params,
    })
  ),
  dashboardSuccess: dashboardSlice.actions.dashboardSuccess,
  dashboardFailure: dashboardSlice.actions.dashboardFailure,
};

// Selectors
// 4. Create Selectors
export const selectedDashboard = (state: RootState) => state.dashboard.loading;
export const selectedDashboardFailed = (state: RootState) => state.dashboard.error;
export const selectedDashboardData = (state: RootState) => state.dashboard.data;

// Reducer
export default dashboardSlice.reducer;
