import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: { flexDirection: 'row', alignItems: 'center' },
    label: { ...TypographyStyle.fieldsTitle, marginRight: 15 },
    v_label: { flexDirection: 'row', justifyContent: 'space-between' },
    t_input: {
      flex: 1,
      fontSize: 12,
      marginLeft: 15,
      paddingVertical: 0,
      color: colors.black,
      fontFamily: 'Roboto',
    },
    inputSx: {
      width: 140,
      height: 42,
      borderRadius: 2,
      fontSize: 12,
      backgroundColor: 'white',
    },
    i_schedule: { width: 24, height: 24 },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      container: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      label: {
        marginBottom: 5,
      },
      inputSx: { width: '100%' },
    },
    [DEVICE_SIZES.XS]: {
      container: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      label: {
        marginBottom: 5,
      },
      inputSx: { width: '100%' },
    },
  }
);

export default useStyles;
