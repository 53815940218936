import React from 'react';
import ProfileMenu, { MeasureValue } from './profile-menu';
import { DrawerContext } from '../../DrawerProvider';
import { useAuthService } from '@src/ducks/hooks';
import { Pressable, Image } from 'react-native';
import useStyles from './styles.css';
import images from '@assets/images';

const MENU = [
  {
    icon: 'people',
    name: 'My Profile',
  },
  {
    icon: 'power-settings-new',
    name: 'Logout',
  },
];

const ProfileButton = () => {
  const styles = useStyles();
  const { onLogout } = useAuthService();
  const pressableRef = React.useRef<any>(null);
  const { onActiveTab } = React.useContext(DrawerContext);
  const [isFocus, setFocus] = React.useState<boolean>(false);
  const [measure, setMesasure] = React.useState<MeasureValue>(
    {} as MeasureValue
  );

  const onSelected = React.useCallback((item: string) => {
    if (item === 'Logout') {
      onLogout();
    } else if (item === 'My Profile') {
      onActiveTab('Account');
    }
  }, []);

  React.useEffect(() => {
    if (pressableRef.current) {
      pressableRef.current?.measure(
        (
          fx: any,
          fy: any,
          width: number,
          height: any,
          px: number,
          py: number
        ) => {
          setMesasure({
            x: px,
            y: py,
            width,
            height,
          });
        }
      );
    }
  }, [pressableRef.current, isFocus]);

  return (
    <>
      <Pressable
        ref={pressableRef}
        onPress={() => setFocus(true)}
        style={styles.header_profile_picture}
      >
        <Image
          style={styles.header_profile_picture}
          source={images.placeholder_profile}
          resizeMode="stretch"
        />
      </Pressable>
      <ProfileMenu
        visible={isFocus}
        measures={measure}
        options={MENU}
        onSelected={onSelected}
        onClose={() => setFocus(false)}
      />
    </>
  );
};

export default ProfileButton;
