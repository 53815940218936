import React, { useContext } from 'react';
import { View, Image, Text } from 'react-native';
import { DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { Button01 } from '@src/components/button';
import useStyles from './styles.css';
import { useNavigation } from '@react-navigation/native';
import { images } from 'theme';
import { AppRoutingContext } from '@src/navigators/AppRouting';

const OnboardingText = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();
  const { testLogIn } = useContext(AppRoutingContext);

  const getStarted = () => {
    navigation.navigate('OnBoardingStep1');
  };

  const navigateToSignIn = () => {
    // navigation.navigate('SignInBack');
    testLogIn();
  };

  return (
    <View>
      <View style={styles.textContainer}>
        <Text style={styles.txtNote1}>Seal the Deal Anywhere</Text>
        <Text style={styles.txtNote1}>with NotarizeIT,</Text>
        <Text style={styles.txtNote2}>your premier E-Notary solution</Text>
        <View style={styles.textContainer2}>
          <Text style={styles.txtNote3}>
            Experience the ultimate convenience
          </Text>
          <Text style={styles.txtNote3}>
            of remote notarization, ensuring secure.
          </Text>
          <Text style={styles.txtNote3}>
            and hassle-free transactions no matter
          </Text>
          <Text style={styles.txtNote3}>where you are.</Text>
        </View>
      </View>
      <View style={styles.btn_container}>
        <Button01
          label="Get Started"
          color={'white'}
          onPress={getStarted}
          //   isLoading={isLoading}
          style={styles.btn_signin}
          labelStyle={styles.lbl_signin}
        />
        <Button01
          label="Skip"
          onPress={() => navigateToSignIn()}
          //   isLoading={isLoading}
          style={styles.btn_skip}
          labelStyle={styles.lbl_skip}
        />
      </View>
    </View>
  );
};

export default OnboardingText;
