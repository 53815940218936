import React, { useCallback, useContext, useMemo } from 'react';
import { CanvasContext } from '../provider/canvas';
import { LayerContext } from '../provider/layer';
import LayerCanvas from './layer/index';
import useStyles from './styles.css';
import { View } from 'react-native';
import { Stage } from 'react-konva';

function Stages({ loading }: any) {
  const styles = useStyles();
  const { canvas, stageRef } = useContext(CanvasContext);
  const { layers, updateLayers } = useContext(LayerContext);
  const { CANVAS_WIDTH, CANVAS_HEIGHT } = useMemo(() => {
    const C_WIDTH = canvas.width * canvas.scale - 5 || 0;
    const C_HEIGHT = canvas.height * canvas.scale - 5 || 0;

    return { CANVAS_WIDTH: C_WIDTH, CANVAS_HEIGHT: C_HEIGHT };
  }, [canvas]);

  console.log('layerslayers', layers);

  const checkDeselect = (e: any) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      layers.forEach((item: any) => {
        item.ref.current?.onClearSelected();
      });
    }
  };

  const onUpdateLayer = useCallback(
    (item: any, index: number) => {
      const newlayer = layers.slice();
      newlayer[index] = item;
      updateLayers(newlayer);
    },
    [layers]
  );

  return (
    <View
      style={[
        styles.v_stage_container,
        {
          width: CANVAS_WIDTH,
          height: CANVAS_HEIGHT,
        },
      ]}
    >
      <Stage
        ref={stageRef}
        width={CANVAS_WIDTH}
        height={CANVAS_HEIGHT}
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
      >
        {loading === false &&
          layers.map((item: any, index: number) => {
            if (index === canvas.pageIndex) {
              return (
                <LayerCanvas
                  key={item.id}
                  item={item}
                  ref={item.ref}
                  onUpdateLayer={(i: any) => onUpdateLayer(i, index)}
                />
              );
            }

            return null;
          })}
      </Stage>
    </View>
  );
}

export default React.memo(Stages);
