import { Plugin, ViewerState } from '@react-pdf-viewer/core';

const onViewerStateChange = (onUpdateCanvas: any): Plugin => {
  const onStateChange = (props: ViewerState) => {
    onUpdateCanvas({
      scale: props.scale,
      width: props.pageWidth,
      height: props.pageHeight,
      pageIndex: props.pageIndex,
    });

    return props;
  };

  return {
    onViewerStateChange: onStateChange,
  };
};

export default onViewerStateChange;
