import colors from '@assets/colors';
import TypographyStyle from '@assets/typography';
import { windowHeight, windowWidth } from '@src/utils/dimension-helper';
import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      position: 'absolute',
      width: '100%',
      height: '150%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blurBG,
    },
    paymentContainerDetails: { backgroundColor: colors.white },
    header: {
      backgroundColor: colors.purple,
      height: 50,
      display: 'flex',
    },
    detailsContainer1: {
      flexDirection: 'column',
      marginTop: 70,
    },
    detailsContainer2: {
      flexDirection: 'column',
      marginTop: 0,
    },
    detailsContainer3: {
      flexDirection: 'column',
      marginTop: 10,
    },
    detailsContainer4: {
      flexDirection: 'row',
      marginTop: 25,
      marginBottom: 30
    },
    couponLabel: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.grayPrimary,
      },
    ]),
    couponTextInput: {
      marginTop: -20,
      paddingLeft: 20,
      width: 120,
      height: 40,
    },
    inputVdetailsRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: -20,
      marginTop: 20,
    },
    labelDetails: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      {
        color: colors.black3,
        paddingLeft: 30,
        fontWeight: '700',
      },
    ]),
    labelDetails1: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      {
        color: colors.black3,
        paddingLeft: 30,
        fontWeight: '700',
        marginTop: 15,
      },
    ]),
    labelDetails2: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      {
        color: colors.black3,
        paddingLeft: 30,
        fontWeight: '700',
        marginBottom: 20,
      },
    ]),
    labelDetails3: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.black3,
        paddingLeft: 30,
        fontWeight: '500',
        fontSize: 15,
        marginTop: 5,
      },
    ]),
    labelTotal: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.purple,
        paddingLeft: 30,
        fontWeight: '700',
        fontSize: 18,
      },
    ]),
    labelPadding: { paddingRight: 30, fontSize: 16, fontWeight: '700' },
    labelPadding1: {
      paddingRight: 30,
      fontSize: 16,
      fontWeight: '700',
      marginTop: 15,
    },
    labelTotalPadding: {
      paddingRight: 30,
      fontSize: 18,
      fontWeight: '700',
      color: colors.purple,
    },
    divider: {
      width: 327,
      backgroundColor: colors.grayPrimary,
      height: 3,
      rotate: '180deg',
      alignItems: 'center',
      marginTop: 50,
      paddingHorizontal: 30,
    },
    headerText: {},
    btnContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      // marginTop: 50,
      position: 'absolute',
      bottom: 50,
      width: '100%'
    },
    btnNext: {
      alignSelf: 'center',
      backgroundColor: colors.purple,
      width: '80%',
    },
    btnText: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.colorPrimary,
        fontWeight: '700',
        textAlign: 'center',
      },
    ]),
    payText1: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.grayPrimary,
        paddingLeft: 30,
      },
    ]),
    payText2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.black3,
        paddingLeft: 30,
      },
    ]),
    payTextPadding: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.black3,
        paddingRight: 30,
      },
    ]),
    payTextPadding1: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.black3,
        paddingRight: 65,
      },
    ]),
    labelDetails3Padding: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.black3,
        paddingRight: 30,
        fontWeight: '700',
      },
    ]),
    arrow: {
      color: colors.white,
      width: '24',
      height: '24',
      marginLeft: 20,
      marginTop: 12
    },

    radio1: {
      color: colors.black,
      width: '16',
      height: '16',
      marginLeft: -50,
      marginTop: 15
    },
    radio2: {
      color: colors.black,
      width: '16',
      height: '16',
      paddingLeft: 20
    }
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {
      container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        color: colors.white,
      },
      paymentContainerDetails: {
        width: '100%',
        height: '100%',
        backgroundColor: colors.white,
        paddingHorizontal: 0,
        paddingVertical: 0,
      },
      header: {
        backgroundColor: colors.purple,
        top: 0,
        height: 50,
        position: 'absolute',
        width: '100%',
        flex: 1,
        textAlign: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      headerText: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.white,
          fontWeight: '700',
          padding: 15,
          marginRight: 'auto',
          marginLeft: 'auto',
          paddingRight: 50,
        },
      ]),
      divider: {
        width: '85%',
        backgroundColor: colors.grayPrimary,
        height: 1,
        alignItems: 'center',
        marginTop: 50,
        marginHorizontal: 'auto',
        // paddingHorizontal: 30
      },
    },
  }
);

export default useStyles;