import { Platform } from 'react-native';

export default {
  bg: '#FAFAFA',
  colorPrimary: '#FFFFFF',
  border: '#999999',
  border1: '#E9E9E9',
  border2: '#CCCCCC',
  border3: '#C3CADC',
  border4: '#D4D4D4',
  border5: '#dadce0',
  border6: '#F4F4FF',
  black: 'black',
  black1: '#262E37',
  black2: '#4B4B4B',
  black3: '#212221',
  black4: 'rgba(75, 75, 75, 0.5)',
  black5: '#1C242C',
  black6: '#1C242C50',
  black7: '#323C47',
  black8: '#1E1E1E',
  black9: '#535353',
  black10: '#050507',
  grayPrimary: '#A3A3A3',
  lightGray: '#CCCCCC30',
  darkGray: '#666666',
  lightBlack: '#404040',
  gray1: '#757575',
  gray2: '#F0F0F0',
  gray3: '#ECECEC',
  gray4: '#D9D9D9',
  gray5: '#F2F2F2',
  gray6: '#838383',
  orange: 'orange',
  orange1: 'rgba(255, 159, 0, 1)',
  orange2: 'rgba(255, 159, 0, 0.15)',
  paleMarigold: '#FFBA45',
  red: 'red',
  red1: '#FF4343',
  red2: '#c95757',
  white: 'white',
  offwhite: '#F5F5F5',
  offwhit2: 'rgba(255,255,255,0.5)',
  transparent: 'transparent',
  placeholder: Platform.OS === 'ios' ? '#C7C7CD' : '#a8a8a8',
  btnBG: '#1E9CB9',
  btnBG1: '#ECEFF1',
  blurBG: 'rgba(0,0,0,0.2)',
  blurBG2: 'rgba(0,0,0,0.8)',
  lilac: '#EDEDFF',
  purple: '#44449B',
  purple1: '#44449B80',
  purple2: '#44449B70',
  purple3: '#2A2A41',
  purple4: '#44459B',
  lightPurple: '#6767BB',
  lightPurple1: '#EAF2FF',
  green: '#34A853',
  green1: '#23AC00',
  signature1: '#FF9F00',
  signature2: '#FFAAAA',
  signature3: '#45459B',
  signature4: '#3B80AE',
  rowbg: 'rgba(236, 239, 241, 0.5)',
};
