export const SignUpFormDataParser = async (obj: any) => {
  console.log('objobjobj', obj);
  const formData = new FormData();

  formData.append('firstName', obj.firstName);
  formData.append('middleName', obj.middleName);
  formData.append('lastName', obj.lastName);
  formData.append('city', obj.city);
  formData.append('province', obj.province);
  formData.append('country', obj.country);
  formData.append('email', obj.email);
  formData.append('password', obj.password);
  formData.append('role', obj.role);
  if (obj.rollNo) {
    formData.append('rollNo', obj.rollNo);
  }
  if (obj.notaryCommNo) {
    formData.append('notaryCommNo', obj.notaryCommNo);
  }
  if (obj.validIdUrl.file) {
    formData.append('validId', obj.validIdUrl.file);
  }
  if (obj.ibpIdUrl.file) {
    formData.append('ibpId', obj.ibpIdUrl.file);
  }
  if (obj.notarialCommFileUrl.file) {
    formData.append('notarialCommFile', obj.notarialCommFileUrl.file);
  }

  return formData;
};

export const DocumentFormDataParser = async (obj: any) => {
  const formData = new FormData();

  formData.append('file', obj.file, obj.name);
  formData.append('name', obj.name);
  formData.append('type', obj.type);
  formData.append('city', obj.city);

  obj.workflow.parties.forEach((party: any, index: any) => {
    formData.append(`workflow[parties][${index}][name]`, party.name);
    formData.append(`workflow[parties][${index}][email]`, party.email);
    formData.append(`workflow[parties][${index}][id]`, party.id);
    formData.append(`workflow[parties][${index}][role]`, 'SIGN');
  });

  formData.append('workflow[signOrder]', obj.signOrder);
  formData.append('workflow[emailContent][subject]', 'test subject');
  formData.append('workflow[emailContent][message]', 'sample body message');
  formData.append('workflow[emailContent][expiration]', '08-08-2028');

  //TODO: 
  
  
  return formData;
};

export const SignedDocumentFormDataParser = async (obj: any) => {
  console.log('objobjobj', obj);
  const formData = new FormData();

  formData.append('firstName', obj.firstName);
  formData.append('middleName', obj.middleName);
  formData.append('lastName', obj.lastName);
  formData.append('city', obj.city);
  formData.append('province', obj.province);
  formData.append('country', obj.country);
  formData.append('email', obj.email);
  formData.append('password', obj.password);
  formData.append('role', obj.role);
  if (obj.rollNo) {
    formData.append('rollNo', obj.rollNo);
  }
  if (obj.notaryCommNo) {
    formData.append('notaryCommNo', obj.notaryCommNo);
  }
  if (obj.validIdUrl.file) {
    formData.append('validId', obj.validIdUrl.file);
  }
  if (obj.ibpIdUrl.file) {
    formData.append('ibpId', obj.ibpIdUrl.file);
  }
  if (obj.notarialCommFileUrl.file) {
    formData.append('notarialCommFile', obj.notarialCommFileUrl.file);
  }

  return formData;
};
