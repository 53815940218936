import React, { useState, useEffect } from 'react';
import MobileHeader from '@src/screens/drawer-screens/drawer-body/header/DrawerItemHeader';
import { mobileSizeChecker } from '@src/utils/screensize-helper';
import StepsHeader from './steps-web';
import {
  View,
  Text,
  Pressable,
  StyleSheet,
  SafeAreaView,
  Modal,
  TouchableWithoutFeedback,
  Animated,
} from 'react-native';
import useStyles from './styles.css';
// import { LayerContext } from '../'
// import { LayerContext } from '../witness-signature/provider/layer';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { useNavigation } from '@react-navigation/native';
import { LayerContext } from '../../live-request-new/signature-fields/provider/layer';
import { VideoContext } from '../../live-session/video-session/provider/video';
import colors from '@assets/colors';
import LiveSessionInformationModal from './information_modal';

const Header = () => {
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<'LiveSessionEnd'>>();
  const isMobile = mobileSizeChecker();
  const { layers } = React.useContext(LayerContext);
  const { mySignature, onAffixSignature } = React.useContext(VideoContext);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [fadeAnim] = useState(new Animated.Value(0));

  const hasSigned = () => {
    return (
      layers &&
      layers[0]?.layerFields.filter((field: any) => field?.signature).length
    );
  };

  // if (isMobile) {
  //   return <MobileHeader />;
  // }

  const changeModalVisible = (bool: any) => {
    setisModalVisible(bool);
  };

  useEffect(() => {
    changeModalVisible(true);
    setTimeout(() => {
      changeModalVisible(false);
    },3000)
  }, []);

  // return <StepsHeader />;
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Text style={{ padding: 30, paddingBottom: 20, fontSize: 14 }}>
        This session is being recorded
      </Text>

      {mySignature && (
        <Pressable
          onPress={onAffixSignature}
          disabled={!!mySignature?.affix}
          style={[
            styles.affixSignature,
            {
              backgroundColor: mySignature?.affix
                ? colors.gray4
                : colors.purple,
            },
          ]}
        >
          <Text style={styles.affixSignatureLabel}>Affix Signature</Text>
        </Pressable>
      )}
      <View style={{ marginTop: 100 }}>
        <SafeAreaView
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
        >
          <Modal
            transparent={true}
            animationType="fade"
            visible={isModalVisible}
            onRequestClose={() => changeModalVisible(false)}
          >
            <LiveSessionInformationModal />
            <TouchableWithoutFeedback
              onPress={() => setisModalVisible(!isModalVisible)}
            >
              <Text style={styles.closeButton}>X</Text>
            </TouchableWithoutFeedback>
          </Modal>
        </SafeAreaView>
      </View>
    </View>
  );
};

export default React.memo(Header);
