import React, { useState, useCallback, createContext } from 'react';
import { DrawerList } from '@src/utils/mockdata';
import { useAppSelector } from '@src/ducks/ducksHook';
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';

interface DrawerContextValue {
  drawerList: string[];
  activeTab: string;
  onActiveTab: (value: string) => void;
  isDrawerVisible: boolean;
  onDrawerStatus: () => void;
  hideItems: boolean;
  onShowItems: () => void;
  onHideItems: () => void;
}

const DrawerContext = createContext<DrawerContextValue>({
  drawerList: [],
  activeTab: 'Dashboard',
  onActiveTab: () => {},
  isDrawerVisible: false,
  onDrawerStatus: () => {},
  hideItems: true,
  onShowItems: () => {},
  onHideItems: () => {},
} as DrawerContextValue);

interface BoardProviderProps {
  children: React.ReactElement;
}

const DrawerProvider = ({ children }: BoardProviderProps) => {
  const role = useAppSelector(selectedAuthRole);
  const [activeTab, setActiveTab] = useState('Dashboard');
  const [isDrawerVisible, setDrawerStatus] = useState(false);
  const [hideItems, setHideItems] = useState(false);
  const drawerList = DrawerList(role);

  const onActiveTab = useCallback(
    (value: string) => {
      setActiveTab(value);
      setDrawerStatus(false);
      setHideItems(true);
    },
    [activeTab]
  );

  const onShowItems = useCallback(() => {
    setHideItems(false);
  }, []);

  const onHideItems = useCallback(() => {
    setHideItems(true);
  }, []);

  const onDrawerStatus = useCallback(() => {
    setDrawerStatus(!isDrawerVisible);
  }, [isDrawerVisible]);

  

  const value = {
    drawerList,
    activeTab,
    onActiveTab,
    isDrawerVisible,
    onDrawerStatus,
    onHideItems,
    onShowItems,
    hideItems,
  };

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export { DrawerProvider, DrawerContext };
