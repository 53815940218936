import React from 'react';
import { Rect, Text, Group } from 'react-konva';
import RectImage from '../RectImage';

type InitialsProps = {
  field: any;
  onSelect: () => void;
};

const Initials = (props: InitialsProps) => {
  const { field } = props;

  return (
    <React.Fragment>
      <Group
        x={field.x}
        y={field.y}
        width={field.width}
        height={field.height}
        onTap={props.onSelect}
        onClick={props.onSelect}
        rotation={field.rotation}
      >
        {field.signature ? (
          <>
            <RectImage
              width={field.width}
              height={field.height}
              src={field.signature}
            />
          </>
        ) : (
          <>
            <Rect
              dash={[8, 8]}
              dashOffset={3}
              strokeWidth={2}
              cornerRadius={6.25}
              fill={`${field.fill}30`}
              width={field.width}
              height={field.height}
              stroke={field.fill}
            />
            <Text
              text="[Initial will be place here]"
              align="center"
              fill={field.fill}
              width={field.width}
              height={field.height}
              fontFamily="DMSans"
              verticalAlign="middle"
              fontSize={field.height * 0.17}
            />
          </>
        )}
      </Group>
    </React.Fragment>
  );
};

export default React.memo(Initials);
