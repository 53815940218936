import React, { useCallback, useContext, useEffect } from 'react';
import { useDeviceSize, DEVICE_SIZES } from 'rn-responsive-styles';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { View, Text } from 'react-native';
import useStyles from './styles.css';
import { useNavigation } from '@react-navigation/native';
import Logo from './logo';
import FaceVerifiedText from './face-verified-text';
import UploadDocument from '../upload-document';
import { Button01 } from '@src/components/button';
import { DrawerContext } from '../../drawer-screens/DrawerProvider';
import { AppRoutingContext } from '@src/navigators/AppRouting';

type Props = {
  shouldUploadDocument?: boolean;
};

const FaceVerified = ({ shouldUploadDocument = true }: Props) => {
  const device_size = useDeviceSize();
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();
  const { onShowHeader, onHideHeader } = useContext(AppRoutingContext);
  const { onActiveTab } = useContext(DrawerContext);

  const onBacktoDashboard = useCallback(() => {
    onActiveTab('Dashboard');
    navigation.navigate('LiveSession');
  }, []);

  useEffect(() => {
    onHideHeader();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.v_body}>
        <View style={{marginTop: -200}}>
          <Logo />
          {!shouldUploadDocument && <FaceVerifiedText />}
          {shouldUploadDocument && (
            <View style={{ marginBottom: 200 }}>
              <View style={styles.textContainer}>
                <Text style={styles.txtNote1}>Identity Verified.</Text>
                <Text style={styles.txtNote2}>Welcome Kyle!</Text>
                <View style={styles.textContainer2}>
                  <Text style={styles.txtNote3}>
                    Your identity has been successfully
                  </Text>
                  <Text style={styles.txtNote3}>
                    verified. You may now proceed to upload
                  </Text>
                  <Text style={styles.txtNote3}>
                    the required document securely.
                  </Text>
                </View>
              </View>
            </View>
          )}
        </View>

        <View style={styles.btn_container}>
          {shouldUploadDocument && <UploadDocument />}
          {!shouldUploadDocument && (
            <View style={styles.btn_container}>
              <Button01
                style={{
                  backgroundColor: `#44449B`,
                  width: '100%',
                  bottom: 50,
                  position: 'absolute',
                }}
                onPress={onBacktoDashboard}
                label="Join Live Session"
                labelStyle={styles.btnStyle}
              ></Button01>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default FaceVerified;
