import React  from 'react';
import DrawerItemHeader from './header/DrawerItemHeader';
import useStyles from './styles.css';
import { View } from 'react-native';
import DrawerItemBody from './body';

const DrawerBody = () => {
  const styles = useStyles();

  return (
    <View style={[styles.drawer_item_container]}>
      <DrawerItemHeader />
      <DrawerItemBody />
    </View>
  );
};

export default DrawerBody;
