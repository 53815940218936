import React, { useState, useCallback, useRef } from 'react';
import { View, Image, Text } from 'react-native';
import { DocumentContext } from '@src/screens/live-request/provider/document';
import { FileUploader } from 'react-drag-drop-files';
import { Button01 } from '@src/components/button';
import { AuthContext } from '@src/AuthProvider';
import { LayerContext } from '@src/screens/live-request-new/signature-fields/provider/layer';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { useNavigation } from '@react-navigation/native';
import useStyles from './styles.css';
import { images } from 'theme';
const fileTypes = ['JPG', 'PDF', 'JPEG', 'DOCX'];

const UploadDocumentModal = () => {
  const styles = useStyles();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<SheetRef>();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();
  const [source, setSource] = React.useState(null);
  const { setDocumentFile } = React.useContext(LayerContext);
  const { onErrorMessage } = React.useContext(AuthContext);
  const { onSelectFile } = React.useContext(DocumentContext);
  const onTypeError = () =>
    onErrorMessage('Invalid file type. Please use PDF format.');
  const onSizeError = () =>
    onErrorMessage('Exceeded file size limit of 200MB.');

  const handleChange = (files: any) => {
    if (files) {
      setDocumentFile({ files });
      let reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onloadend = (e: any) => {
        setSource(e.target.result);
        setOpen(false);
        navigation.navigate('UploadDocumentSuccess');
      };
    }
  };

  const chooseFile = () => {
    setOpen(false);
    navigation.navigate('DocumentSuccess');
  };

  return (
    <>
      <Button01
        onPress={() => setOpen(true)}
        label="Upload Document"
      ></Button01>
      <Sheet
        ref={ref}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        snapPoints={[450]}
        initialSnap={1}
      >
        <Sheet.Container>
          <Sheet.Content>
            <View style={styles.textContainer}>
              <Text style={styles.txt1}>
                Acceptable file formats: jpg, pdf, docx
              </Text>
              <Text style={styles.txt1}>Max file size: 200MB</Text>
            </View>
            <View style={styles.imgContainer}>
              <Image
                source={images.upload_docx}
                style={styles.upload_docx}
                resizeMode="contain"
              />
            </View>
            <View style={styles.choosefileContainer}>
              <FileUploader
                classes="drop"
                maxSize={200}
                types={fileTypes}
                onTypeError={onTypeError}
                onSizeError={onSizeError}
                handleChange={handleChange}
              >
                <Button01
                  style={styles.choosefileBtn}
                  labelStyle={styles.choosefileTxt}
                  label="Choose File"
                  disabled
                ></Button01>
              </FileUploader>
            </View>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
};

export default UploadDocumentModal;
