import React, { useContext } from 'react';
import { useFetch } from '@src/utils/api/useFetch';
import { View, Text, Pressable } from 'react-native';
import useCommonStyles from '../styles';
import useStyles from './styles.css';
import colors from '@assets/colors';
import { API_BASE_URL } from '@env';
import { TextInput02 } from '@src/components/textinput';
import Icon from '@src/components/icon';
import { DrawerContext } from '../../DrawerProvider';
import { LayerContext } from '@src/screens/live-request-new/signature-fields/provider/layer';
import { TouchableOpacity } from 'react-native-gesture-handler';

const DocumentBoxes = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const { data } = useFetch(`${API_BASE_URL}/dashboard/data`);
  const { onActiveTab, hideItems } = useContext(DrawerContext);
  const { search, onSearchStateChange } = React.useContext(LayerContext);
  const [isVisible, setIsVisible] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (search.length > 0) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [search]);

  return (
    <View style={commonStyles.item_content_container}>
      <TextInput02
        value={search}
        placeholder="Search"
        inputStyle={styles.ti_search}
        borderStyle={styles.ti_border_style}
        style={styles.ti_style}
        onChangeText={onSearchStateChange}
      />
      {!hideItems && isVisible && (
        <Pressable
          onPress={() => onActiveTab('Live Session List')}
          style={styles.cr_card_btn}
        >
          <Text style={styles.cr_card_btn_label}>Live Sessions</Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={styles.badge_btn}>
              <Text style={styles.badge_btn_label}>3</Text>
            </View>
            <Icon name="keyboard-arrow-right" size={24} color={colors.white} />
          </View>
        </Pressable>
      )}

      <View style={styles.documents_content}>
        {isVisible && <Text style={styles.documents_label}>Documents</Text>}
        {!hideItems && isVisible && (
          <TouchableOpacity onPress={() => onActiveTab('View All Document')}>
            <Text style={styles.view_all}>VIEW ALL</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default DocumentBoxes;
