import React from 'react';
import { View } from 'react-native';
import UploadDocumentModal from './bottom-popup';

const UploadDocument = () => {
  return (
    <View>
      <UploadDocumentModal />
    </View>
  );
};

export default UploadDocument;
