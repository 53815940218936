import React, { useState, useCallback, createContext } from 'react';
import { useFetch } from '@src/utils/api/useFetch';
import { API_BASE_URL } from '@env';
import { useFocusEffect } from '@react-navigation/native';

type FileValue = {
  name: string;
  description: string;
  date: string;
  time: string;
  meridiem: string;
  uri: string;
} & any;

export interface ContextValue {
  visible: boolean;
  file: FileValue;
  screen: string;
  onUpdateScreen: (value: string) => void;
  onOpenUpload: () => void;
  onSelectFile: (value: any) => void;
  onUpdateFile: (value: any) => void;
}

interface ProviderProps {
  children: React.ReactElement;
}

const DocumentContext = createContext<ContextValue>({
  file: {
    name: '',
    description: '',
    date: '',
    time: '',
    meridiem: '',
    uri: '',
  } as FileValue,
} as ContextValue);

const DocumentProvider = ({ children }: ProviderProps) => {
  const [file, setFile] = useState<any>();
  const [screen, setScreen] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const { data } = useFetch(`${API_BASE_URL}/dashboard/documents`);
  const onUpdateScreen = useCallback(
    (value: string) => {
      setScreen(value);
    },
    [screen]
  );

  // for react-native only & expo
  // for react-native only & expo
  // for react-native only & expo
  useFocusEffect(
    React.useCallback(() => {
      if (data) {

        // setFile()

        // const firstItem = data?.items?.find((item: any, index: any) => {
        //   if (index === 0) {
        //     // setFile(item[0])
        //   }
        // });

        // const firstItem = data?.items?.find((item: any, index: Number) => index === 0)
        // alert('sample')
        // setFile(firstItem)
      }
    }, [data])
  );


  const firstItem = data?.items?.find((item: any, index: any) => {
    if (index === 0) {
      // setFile(item[0])
      return true;
    }

    return false;
  });

  const onOpenUpload = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const onSelectFile = useCallback(
    (value: any) => {
      setFile(value);
      setScreen('file-progress');
    },
    [file]
  );

  const onUpdateFile = useCallback(
    (value: any) => {
      setFile(value);
    },
    [file]
  );

  const value = {
    visible,
    file,
    screen,
    onUpdateScreen,
    onOpenUpload,
    onSelectFile,
    onUpdateFile,
  };

  return (
    <DocumentContext.Provider value={value}>
      {children}
    </DocumentContext.Provider>
  );
};

export { DocumentProvider, DocumentContext };
