import React, { useEffect } from 'react';
import Animated, {
  withTiming,
  interpolate,
  Extrapolation,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

const TranslateYAnimated = ({
  children,
  visible,
  style,
  duration,
  outputRange,
}: any) => {
  const animated = useSharedValue(0);

  useEffect(() => {
    animated.value = withTiming(visible ? 1 : 0, { duration: duration || 300 });
  }, [visible]);

  const animateStyle = useAnimatedStyle(() => {
    const translateX = interpolate(
      animated.value,
      [0, 1],
      outputRange || [0, 500],
      Extrapolation.CLAMP
    );

    return {
      transform: [{ translateX }],
    };
  }, [outputRange]);

  return (
    <Animated.View style={[style, animateStyle]}>{children}</Animated.View>
  );
};

export default TranslateYAnimated;
