import React, { useState, useCallback, createContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import * as Types from './types';

type ScrollValue = {
  x: number | 0;
  y: number | 0;
};

interface ContextValue {
  activeIndex: number;
  scrollPosition: ScrollValue;
  onBack: () => void;
  onNext: () => void;
  onSetCurrentPage: (value: number) => void;
  onUpdateScrollPosition: (value: ScrollValue) => void;
}

const HeaderContext = createContext<ContextValue>({} as ContextValue);

const HeaderProvider = ({ children }: Types.ProviderProps) => {
  const navigation = useNavigation();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState<ScrollValue>(
    {} as ScrollValue
  );

  const onBack = useCallback(() => {
    if (activeIndex === 0) {
      navigation.goBack();
      return;
    }
    setActiveIndex(activeIndex - 1);
  }, [activeIndex]);

  const onNext = useCallback(() => {
    setActiveIndex(activeIndex + 1);
  }, [activeIndex]);

  const onSetCurrentPage = useCallback(
    (value: number) => {
      setActiveIndex(value);
    },
    [activeIndex]
  );

  const onUpdateScrollPosition = useCallback(
    (value: ScrollValue) => {
      setScrollPosition(value);
    },
    [scrollPosition]
  );

  const value = {
    activeIndex,
    scrollPosition,
    onBack,
    onNext,
    onSetCurrentPage,
    onUpdateScrollPosition,
  };

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export { HeaderProvider, HeaderContext };
