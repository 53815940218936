import React, { useContext, useEffect } from 'react';
import { View, Text } from 'react-native';
import { DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import useStyles from './styles.css';
import { useNavigation } from '@react-navigation/native';


const FaceVerifiedText = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();


  const uploadDocument = () => {
    console.log('upload docs');
  };

  return (
    <View>
      <View style={styles.textContainer}>
        <Text style={styles.txtNote1}>Identity Verified.</Text>
        <Text style={styles.txtNote2}>Welcome Kyle!</Text>
        <View style={styles.textContainer2}>
          <Text style={styles.txtNote3}>
            Your identity has been successfully
          </Text>
          <Text style={styles.txtNote3}>
            verified. You may now proceed to join
          </Text>
          <Text style={styles.txtNote3}>the live session.</Text>
        </View>
      </View>
    </View>
  );
};

export default FaceVerifiedText;
