// DUCKS pattern
import { createAction, createSlice } from '@reduxjs/toolkit';
import { LoginInput, ErrorValue, SessionValue } from '../types';
import type { RootState } from '@src/ducks/store';
import { createSelector } from '@reduxjs/toolkit';

export interface AuthState {
  loading: boolean;
  isLoggedIn: boolean;
  session: SessionValue;
  error: ErrorValue;
}

export const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  session: {} as SessionValue,
  error: {} as ErrorValue,
};

// Slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    loginSuccess: (state, action) => {
      state.session = action.payload;
      state.error = {} as ErrorValue;
      state.isLoggedIn = true;
      state.loading = false;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetLoading: (state) => {
      state.loading = false;
    },
    logout: () => {
      return initialState;
    },
    refreshTokenUpdate: (state, action) => {
      state.session.accessToken = action.payload;
    },
  },
});

// Actions
export const authActions = {
  loginRequest: createAction(
    `${authSlice.name}/loginRequest`,
    (params: LoginInput) => ({
      payload: params,
    })
  ),
  loginSuccess: authSlice.actions.loginSuccess,
  loginFailure: authSlice.actions.loginFailure,
  resetLoading: authSlice.actions.resetLoading,
  logout: authSlice.actions.logout,

  refreshTokenRequest: createAction(
    `${authSlice.name}/refreshTokenRequest`,
    (params: SessionValue) => ({
      payload: { ...params, userId: params.id },
    })
  ),
  refreshTokenUpdate: authSlice.actions.refreshTokenUpdate,
};

// Selectors
export const selectAuthLoggingIn = (state: RootState) => state.auth.loading;
export const selectAuthLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectAuthLogInFailed = (state: RootState) => state.auth.error;
export const selectAuthSession = (state: RootState) => state.auth.session;
export const selectedAuthAccessToken = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.accessToken ?? ''
);
export const selectedAuthUserID = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.id ?? ''
);
export const selectedAuthRefreshToken = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.refreshToken
);
export const selectedAuthRole = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.role ?? 'principal'
);

// Reducer
export default authSlice.reducer;
