import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, marginTop: 10, backgroundColor: 'white' },
    header_label: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { textAlign: 'left', color: colors.black3, marginBottom: 20 },
    ]),
    v_separator: {
      height: 0,
    },
    v_height: { height: 50 },
    scrollview: { marginBottom: 10, backgroundColor: colors.lightGray,},
    v_height40: { height: 40 },

    item_content_container: {
      // flex: 1,
      // width: 500,
      // height: 500,
      marginLeft: 104,
      marginRight: 90,
      // marginTop: 51,
      // width: '100%',
      // height: '100%',
    },
    tab_view_title: {
      color: colors.gray1,
      paddingHorizontal: 10, 
      paddingVertical: 20,
    },
    request_notary_btn: {
      width: "70%",
      marginVertical: 25,
      borderRadius: 8,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 32,
      paddingRight: 32,
      paddingVertical: 15,
      backgroundColor: colors.purple,
    },
    request_notary_label: {

    },
    pagination: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column-reverse',
      paddingHorizontal: 30
    },
    documents_content: {
      flexDirection: 'row', 
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    documents_label: {
      ...TypographyStyle.heading4Regular
    },
  },
  {
    [DEVICE_SIZES.LARGE_DEVICE]: {},
    [DEVICE_SIZES.MD]: {
      item_content_container: {
        marginLeft: 40,
        marginRight: 40,
      },
    },
    [DEVICE_SIZES.SM]: {
      v_separator: {
        height: .5,
        opacity: 0.3,
        backgroundColor: colors.gray1,
      },
      item_content_container: {
        marginLeft: 60,
        marginRight: 60,
      },
    },
    [DEVICE_SIZES.XS]: {
      v_separator: {
        height: .5,
        opacity: 0.3,
        backgroundColor: colors.gray1,
      },
      item_content_container: {
        marginLeft: 20,
        marginRight: 20,
      },
      container: { marginTop: 0,backgroundColor: 'white' },
    },
  }
);

export default useStyles;
