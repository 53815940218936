import React from 'react';
import { HeaderProvider } from './provider/header';
import { WitnessProvider } from './provider/witness';
import { DocumentProvider } from './provider/document';
import { WitnessScreenProvider } from './witness-signature/provider/screen';
import { PluginProvider } from './witness-signature/provider/plugin';
import { CanvasProvider } from './witness-signature/provider/canvas';
import { LayerProvider } from './witness-signature/provider/layer';

import PDFPages from './witness-signature/pages';
import useStyles from './styles';
import { View } from 'react-native';
import Screens from './screens';
import Header from './header';
import LiveChat from './live-chat';
import LiveParties from './live-parties';
import { LiveSessionProvider } from './provider';

const LiveSession = (props: any) => {
  const styles = useStyles();

  return (
    <HeaderProvider>
      <WitnessProvider>
        <DocumentProvider>
           <PluginProvider>
             <CanvasProvider>
               <WitnessScreenProvider>
                 <LayerProvider>
                  <LiveSessionProvider>
                    <View style={[styles.container]}>
                      <LiveChat />
                      <LiveParties />
                      <Header />
                      <Screens />
                      <PDFPages />
                    </View>
                  </LiveSessionProvider>
                 </LayerProvider>
               </WitnessScreenProvider>
             </CanvasProvider>
           </PluginProvider>
         </DocumentProvider>
       </WitnessProvider>
     </HeaderProvider>
  );
};

export default LiveSession;
