import React, { useState, useCallback, useRef, useContext, useEffect } from 'react';
import { View, Text, FlatList, Pressable, Image } from 'react-native';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { useNavigation } from '@react-navigation/native';
import { LiveSessionContext } from './provider';
import useStyles from './styles';
import { TextInput02, } from '@src/components/textinput';
import { images } from 'theme';
import { Card } from 'react-native-paper';
import Data from './data.json'
import Icon from '@expo/vector-icons/MaterialIcons';
import colors from '@assets/colors';
import { LayerContext } from '../live-request-new/signature-fields/provider/layer'; 

const LiveChatModal = () => {
    const styles = useStyles();
    const ref = useRef<SheetRef>();
    const navigation = useNavigation<NavigationScreenProps<'Home'>>();
    const { showLiveParties, onHideLiveParties } = React.useContext(LiveSessionContext);
    const [message, setMessage] = useState('')
    const flatListRef = useRef<any>();
    const { layers } = React.useContext(LayerContext);
    
    const [parties, setParties] = useState([
        { id: '1', title: 'Atty. Juan Martin', text: 'Notary' },
        { id: '2', title: 'Randolf Omalsa Dini-ay', text: 'Principal' },
        { id: '3', title: 'Dale Duque', text: 'Witness' },
        // Add more messages here
    ]);

    useEffect(() => {
        const participants = layers && layers[0]?.layerFields .filter((field: any) => field && field.name)
        if (participants) {
            console.log(`randolf: `, participants)
            const listOfParties = participants.map((participant: any) => ({
                id: participant.id,
                title: participant.name,
                text: participant.userType,
            }))
            setParties(listOfParties)
        }
    }, [layers])

    const scrollToBottom = () => {
        flatListRef.current.scrollToEnd({ animated: false });
    };


  const renderItem = ({ item, }: { item: any}) => (
    <View style={{ flexDirection: 'row', padding: 15, alignItems: 'center', }}>
        <View style={{ height: 50, width: 50, borderRadius: 50, }}>
            <Icon name={'photo'} size={50} color={colors.gray1} />
        </View>
        <View style={{ marginLeft: 8, }}>
            <Text style={{ fontSize: 18, color: colors.black1 }}>{item.title}</Text>
            <Text style={{ fontSize: 15, color: 'gray' }}>{item.text}</Text>
        </View>
    </View>
  );

  return (
      <Sheet
        ref={ref}
        isOpen={showLiveParties}
        onClose={() => onHideLiveParties()}
        snapPoints={[450]}
        
        initialSnap={1}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
                <View style={styles.chatContainer}>
                    <FlatList
                        style={{  marginBottom: 50, }}
                        ListFooterComponent={<View style={{  height: 30 }}></View>}
                        ref={flatListRef}
                        data={parties}
                        renderItem={renderItem}
                        keyExtractor={item => item.id}
                        />
                </View>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
      </Sheet>
  );
};

export default LiveChatModal;
