import React, { createContext, useCallback, useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import * as Types from './types';
import Konva from 'konva';

interface CanvasContextValue {
  canvas: Types.CanvasProps,
  addItem: Types.LayerFieldProps;
  viewRef: any;
  stageRef: any;
  onAddItem: (e: any) => void;
  onUpdateCanvas: (item: Types.CanvasProps) => void;
}

const CanvasContext = createContext<CanvasContextValue>({
  canvas: {} as Types.CanvasProps,
  addItem: {} as Types.LayerFieldProps,
  viewRef: {},
  stageRef: {},
  onAddItem: () => {},
  onUpdateCanvas: () => {},
});

const CanvasProvider = ({ children }: Types.DocumentProviderProps) => {
  const viewRef = useRef<any>();
  const [addItem, setAddItem] = useState<any>({});
  const [canvas, setCanvas] = useState<Types.CanvasProps>(
    {} as Types.CanvasProps
  );
  const stageRef = useRef<React.RefObject<Konva.Stage>>();

  const onAddItem = useCallback(
    (e: any) => {
      setAddItem({
        id: nanoid(),
        ...e,
      });
    },
    [addItem]
  );

  const onUpdateCanvas = useCallback(
    (item: Types.CanvasProps) => {
      setCanvas(item);
    },
    [canvas]
  );

  const value = {
    canvas,
    addItem,
    viewRef,
    stageRef,
    onAddItem,
    onUpdateCanvas,
  };

  return (
    <CanvasContext.Provider value={value}>{children}</CanvasContext.Provider>
  );
};

export { CanvasProvider, CanvasContext };
