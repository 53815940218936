import colors from '@assets/colors';
import TypographyStyle from '@assets/typography';
import { windowHeight, windowWidth } from '@src/utils/dimension-helper';
import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      position: 'absolute',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blurBG,
    },
    viewContainer: {
      // width: 636,
      // borderRadius: 10,
      backgroundColor: colors.white,
    },
    partnerTxt: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      {
        color: colors.black3,
        fontWeight: '700',
        textAlign: 'center',
        padding: 60,
        // marginTop: 60,
      },
    ]),
    header: {
      backgroundColor: colors.purple,
      height: 50,
      flexDirection: 'row',
    },
    v_field: { marginTop: 20 },
    h4: {
      ...TypographyStyle.heading4Bold,
      marginTop: 24,
      marginBottom: 37,
    },
    body2: {
      ...TypographyStyle.Body2Regular,
      color: colors.black3,
      textAlign: 'center',
    },
    bodyS: {
      ...TypographyStyle.BodySRegular,
      color: colors.black3,
      textAlign: 'center',
      marginHorizontal: 21,
    },
    fieldText: {
      ...TypographyStyle.fieldsTitle,
      color: colors.black3,
      textAlign: 'left',
      marginTop: 23,
      marginHorizontal: 53,
      width: '35%',
    },
    BGLightGray: {
      borderWidth: 3,
      borderColor: colors.grayPrimary,
      borderStyle: 'dashed',
      backgroundColor: '#F5F5F5',
      marginHorizontal: 53,
      borderRadius: 10,
      paddingBottom: 34,
    },
    iconClose: { alignSelf: 'flex-end', padding: 24 },
    iconUpload: { alignSelf: 'center', marginTop: 36, marginBottom: 16 },
    v: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: 23,
    },
    dash: {
      height: 1,
      width: 41,
      backgroundColor: colors.darkGray,
    },
    btn: { width: 150, alignSelf: 'center' },
    btnSee: {
      width: 180,
      alignSelf: 'center',
      marginVertical: 30,
    },
    viewContainerProgress: {
      // width: 918,
      borderRadius: 10,
      backgroundColor: colors.white,
    },
    loader: {
      height: 8,
      width: '70%',
      backgroundColor: colors.green,
      marginTop: 32,
      marginBottom: 15,
      alignSelf: 'center',
      borderRadius: 10,
    },
    iconSucess: {
      height: 132,
      width: 120,
      marginTop: 63,
      marginBottom: 28,
      alignSelf: 'center',
    },
    viewContainerSeeDetails: {
      // width: 918,
      // borderRadius: 10,
      backgroundColor: colors.white,
      // paddingHorizontal: 51,
      // paddingVertical: 55,
    },
    detailsHeader: {
      ...TypographyStyle.heading4Bold,
      marginBottom: 10,
      textAlign: 'left',
    },
    detailsDescription: {
      ...TypographyStyle.Body2Regular,
      color: colors.black3,
      textAlign: 'left',
    },
    btnContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column-reverse',
      paddingHorizontal: 30,
      position: 'absolute',
      bottom: 50,
      width: '100%'
    },
    btnBack: {
      width: 150,
      alignSelf: 'center',
      backgroundColor: colors.purple,
    },
    btnNext: {
      alignSelf: 'center',
      backgroundColor: colors.purple,
    },
    btnText: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.colorPrimary,
        fontWeight: '700',
        textAlign: 'center',
        paddingHorizontal: 110,
      },
    ]),
    btnTextHeader: {
      textAlign: 'left',
      color: colors.colorPrimary,
      width: 0,
    },
    inputV: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    w70: { width: '70%' },
    timeContainer: {
      width: '25%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    notarizeTime: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginTop: 14,
    },
    center: { alignSelf: 'center', justifyContent: 'center' },
    timeInput: { width: 40, marginTop: 0, marginLeft: 20 },
    colon: { ...TypographyStyle.heading4Bold, marginHorizontal: 8 },
    seconds: {
      marginTop: 8,
      borderRadius: 8,
      minHeight: 42,
      width: 40,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blurBG,
    },
    switchContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 8,
    },
    time: {
      width: 40,
      height: 42,
      marginTop: 8,
      borderWidth: 1,
      borderRadius: 10,
      borderColor: colors.border1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerText: {},
    w71: {},
    footerContainer: { marginTop: 50 },
    footerText: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.darkGray,
        fontWeight: '400',
        padding: 25,
        marginTop: -20,
      },
    ]),
    ti_search: {
      flex: 1,
      borderRadius: 8,
      backgroundColor: '#F5F5F5',
      paddingLeft: 40,
      fontWeight: 'bold'
    },
    ti_border_style: {
      flex: 1,
      backgroundColor: colors.white,
      borderRadius: 8,
      marginTop: 0
    },
    ti_style: {
      marginTop: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#F5F5F5',
      borderRadius: 8,
    },
    searchContainer: { marginTop: 100 },
    header_label: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { textAlign: 'left', color: colors.black3, marginVertical: 18 },
    ]),
    v_separator: {
      height: 0,
    },
    v_height: { height: 50 },
    inbox_row: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    inbox_status: {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: colors.red1,
      marginHorizontal: 20,
    },
    inbox_status_viewed: {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: colors.transparent,
      marginHorizontal: 20,
    },
    inbox_content: {
      flex: 1,
      marginVertical: 10,
    },
    inbox_text: {
      padding: 2,
      fontSize: 13,
    },
    partnerContainer: {},
    partnerName: {},
    partnerStatus: {},

    circle: {
      color: colors.purple,
      position: 'absolute',
    },
    dataContainer: {},
    textContainer: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      {
        color: colors.black3,
        fontWeight: '700',
        lineHeight: 23,
        textAlign: 'center',
      },
    ]),
    seeDetailsBtnContainer: {
      alignContent: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      flex: 1,
      flexDirection: 'column',
      // marginTop: 100,
    },
    seeDetailsTxt: {
      // padding: 85,
      color: colors.black,
    },
    seeDetailsBtn: {
      backgroundColor: colors.purple1,
      borderColor: colors.black,
      borderWidth: 1,
    },
    txt1: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      {
        color: colors.black3,
        fontWeight: '700',
        lineHeight: 23,
        textAlign: 'center',
      },
    ]),
    cardContainer: {
      marginTop: 25,
      width: 290,
      backgroundColor: '#F4F4FF',
    },
    fileName: {
      fontSize: 14,
      fontWeight: '700',
    },
    fileSize: {
      fontSize: 10,
      fontWeight: '400',
      marginTop: -5,
      lineHeight: 0,
    },
    closeBtn: {
      backgroundColor: colors.purple,
      color: colors.white,
      borderRadius: 10,
      marginTop: 15,
      marginRight: 15,
      paddingHorizontal: 5,
      paddingVertical: 5,
      height: 20,
    },
    fileTextContainer: {
      padding: 30,
    },
    file_icon1: {
      width: 16,
      height: 18,
    },
    fileIcon_container: {
      marginTop: 75,
      flexDirection: 'row',
      justifyContent: 'center',
    },
    fileIcon_label: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      {
        fontWeight: '700',
        color: colors.black10,
      },
    ]),
    fileIcon_btn: {
      backgroundColor: colors.transparent,
      height: 20,
    },
    arrow: {
      color: colors.white,
      width: '24',
      height: '24',
      marginLeft: 20,
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {
      container: {
        // flex: 1,
        position: 'absolute',
        width: '100%',
        // height: '100%',
        color: colors.white,
        // flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        // backgroundColor: colors.blurBG,
      },
      dataContainer: {
        paddingHorizontal: 70,
      },
      partnerContainer: { justifyContent: 'center', flexDirection: 'row' },
      partnerName: { paddingLeft: 5, marginTop: -5, fontWeight: '700' },
      partnerStatus: { paddingLeft: 15, color: 'green' },
      searchContainer: {
        marginTop: 30,
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center'
      },
      viewContainerSeeDetails: {
        width: '100%',
        height: '100%',
        // borderRadius: 10,
        backgroundColor: colors.white,
        paddingHorizontal: 0,
        paddingVertical: 0,
      },
      v: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 23,
      },
      header: {
        backgroundColor: colors.purple,
        top: 0,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        alignItems: 'center'
      },
      headerText: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.white,
          fontWeight: '700',
          padding: 15,
          flex: 1,
          marginLeft: -40
        },
      ]),
      v_field: { marginTop: 20 },
      w70: {
        marginTop: 100,
        paddingHorizontal: 30,
      },
      w71: {
        paddingHorizontal: 30,
        marginTop: 20,
      },
      timeContainer: {
        width: '100%',
        alignItems: 'flex-start',
        paddingLeft: 40,
      },
      notarizeTime: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: 14,
        marginLeft: -20,
      },
      textContainer: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.black3,
          fontWeight: '700',
          lineHeight: 23,
          textAlign: 'center',
          paddingTop: 50,
          paddingBottom: 50,
        },
      ]),
      seeDetailsBtnContainer: {
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        flex: 1,
        flexDirection: 'column',
        position: 'absolute',
        bottom: 0,
        width: '100%'
        // marginTop: 100,
      },
      seeDetailsTxt: {
        // padding: 85,
        color: colors.white,
        // paddingHorizontal: 100,
      },
      seeDetailsBtn: {
        backgroundColor: colors.purple,
        paddingHorizontal: 0,
        marginBottom: 20,
        width: '80%',
        // borderColor: colors.black,
        borderWidth: 1,
      },
      txt1: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.black3,
          fontWeight: '700',
          lineHeight: 23,
          textAlign: 'center',
        },
      ]),
      cardContainer: {
        marginTop: -25,
        width: '100%',
        paddingHorizontal: 50,
        backgroundColor: 'white',
      },
      fileName: {
        fontSize: 14,
        fontWeight: '700',
      },
      fileSize: {
        fontSize: 10,
        fontWeight: '400',
        marginTop: 10,
        lineHeight: 0,
      },
      closeBtn: {
        backgroundColor: colors.purple,
        color: colors.white,
        borderRadius: 10,
        marginTop: 15,
        marginRight: 15,
        paddingHorizontal: 5,
        paddingVertical: 5,
        height: 20,
      },
      fileTextContainer: {
        padding: 10,
        backgroundColor: '#F4F4FF',
      },
    },
  }
);

export default useStyles;
