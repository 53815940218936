import { blobToBase64 } from 'base64-blob';

type PayloadProps = {
  method: string;
  headers: any;
  body?: any;
  mode?: string;
  cache?: string;
  crossDomain?: boolean;
  credentials?: string;
};

class Request {
  private host: string;
  private token?: string;
  private contentType?: string | undefined = undefined;
  private responseType?: string | undefined = undefined;

  constructor(host: string) {
    this.host = host;
  }

  setResponseType = (responseType: string | undefined) => {
    this.responseType = responseType;
  };

  setContentType = (contentType: string) => {
    this.contentType = contentType;
  };

  setToken = (token: string) => {
    this.token = token;
  };

  getWithUrl = (url: string) => this._request(url, 'GET', null);

  get = (route: string) => this._request(route, 'GET', null);

  post = (route: string, body: any) => this._request(route, 'POST', body);

  patch = (route: string, body: any) => this._request(route, 'PATCH', body);

  put = (route: string, body: any) => this._request(route, 'PUT', body);

  delete = (route: string) => this._request(route, 'DELETE', null);

  _request = async (route: string, method: string, body: any | undefined) => {
    const payload: PayloadProps = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (this.token) {
      payload.headers.Authorization = `Bearer ${this.token}`;
    }

    if (method !== 'GET' && method !== 'HEAD') {
      payload.body = JSON.stringify(body);
    }

    if (method === 'DELETE') {
      delete payload.headers['Accept'];
      delete payload.headers['Content-Type'];
    }

    if (this.contentType === 'multipart/form-data') {
      payload.headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      };

      payload.body = body;
    }

    const url = route.includes('https') ? route : `${this.host}${route}`;

    return await this._sendHttpRequest(url, payload, this.responseType);
  };

  _sendHttpRequest = async (
    url: string,
    payload: any,
    responseType: string | undefined
  ) => {
    payload.url = url;
    console.log('REQUEST URL', url);
    console.log('REQUEST PAYLOAD', payload);

    const response = await fetch(url, payload);
    console.log('REQUEST RESPONSE', url, response.ok, response.status);

    if (response.ok === false) {
      throw await response.json();
    }

    if (responseType === 'blob') {
      const result: any = await response.blob();
      return await blobToBase64(result);
    } else if (responseType === 'text') {
      return await response.text();
    } else {
      return await response.json();
    }
  };
}

export default Request;
