import colors from '@assets/colors';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    panel_container: {
      width: '100%',
      height: '100%',
      paddingHorizontal: 20,
      backgroundColor: 'white',
      zIndex: 9999,
    },
    v_title: { marginBottom: 10, marginTop: 30 },
    t_document_field: {
      // marginTop: 40,
      fontSize: 16,
      lineHeight: 20,
      color: colors.black2,
      fontFamily: 'DMSans',
      fontWeight: 'bold',
    },
    t_add_field: {
      marginTop: 40,
      fontSize: 16,
      lineHeight: 20,
      color: colors.black2,
      fontFamily: 'DMSans',
    },
    v_qrcode: {
      height: 50,
      marginTop: 20,
      width: '100%',
      borderWidth: 1,
      borderRadius: 8,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      borderColor: colors.black4,
      backgroundColor: colors.white,
    },
    t_label: {
      flex: 1,
      fontSize: 14,
      lineHeight: 20,
      // marginLeft: 20,
      fontFamily: 'DMSans',
    },
    v_item: {
      height: 50,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
    },
    v_item_active: { backgroundColor: colors.offwhite },
    t_document: {
      fontSize: 16,
      lineHeight: 20,
      color: colors.black2,
      fontFamily: 'DMSans',
    },
    t_dragndrop: {
      marginTop: 7,
      fontSize: 13,
      lineHeight: 20,
      color: colors.black4,
      fontFamily: 'DMSans',
    },
    t_dragndrop_bold: {
      color: colors.black2,
      fontFamily: 'DMSans-Bold',
    },
    v_height: { height: 50 },
    btnBack: {
      width: '50%',
      marginTop: 25,
      backgroundColor: colors.transparent,
    },
    btnConfirm: {
      width: '50%',
      marginTop: 25,
      alignSelf: 'flex-end',
      justifyContent: 'flex-end',
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      // panel_container: {
      //   display: 'none',
      // },
    },
    [DEVICE_SIZES.XS]: {
      // panel_container: {
      //   display: 'none',
      // },
    },
  }
);

export default useStyles;
