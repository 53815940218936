import React, { useContext, useEffect, useCallback, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { View, Text, Image } from 'react-native';
import Header from './header';
import useStyles from './styles';
import Data from './data.json';
import Data1 from './data1.json';
import Data2 from './data2.json';
import { AppRoutingContext } from '@src/navigators/AppRouting';
import { TABLE_HEAD_MY_NOTARY_REQUEST } from '@src/utils/mockdata/table-headers';
import DataTableRow from '@src/components/data-table/data-row';
import images from '@assets/images';
import Pagination from '@src/components/data-table/pagination';
import { useInputHelper } from '@src/utils/inputs-helper';
import { applySortFilter, getComparator } from '@src/utils/filter-helper';
import { LayerContext } from '@src/screens/live-request-new/signature-fields/provider/layer';
import _ from 'lodash';

const initialState = {
  page: 0,
  order: 'descending',
  filterName: '',
  orderBy: 'updatedAt',
  rowsPerPage: 8,
};

const ViewAllDocument = () => {
  const styles = useStyles();
  const allDatas = _.concat(Data.result, Data1.result, Data2.result);
  const { onShowHeader, onHideHeader } = useContext(AppRoutingContext);
  const [order, setOrder] = React.useState<any>(undefined);
  const [orderBy, setOrderBy] = React.useState('name');
  const { state, onDispatch } = useInputHelper(initialState);
  const { search } = React.useContext(LayerContext);

  useEffect(() => {
    onShowHeader()
  }, []);

  const filteredUsers = React.useMemo(
    () =>
      applySortFilter(allDatas, getComparator(order, orderBy), search, 'name'),
    [allDatas, order, orderBy, search]
  );

  const handleChangePage = (newPage: any) => {
    onDispatch('page')(newPage);
  };

  const handleChangeRowsPerPage = (value: any) => {
    onDispatch('page')(0);
    onDispatch('rowsPerPage')(value);
  };
  const currentPosts = filteredUsers.slice(
    state.page * state.rowsPerPage,
    state.page * state.rowsPerPage + state.rowsPerPage
  );

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={{ paddingHorizontal: 20, paddingVertical: 15 }}>
            <Header />
          <View style={{ backgroundColor: 'white', paddingHorizontal: 20 }}>
            <DataTableRow
              headers={TABLE_HEAD_MY_NOTARY_REQUEST}
              data={currentPosts}
              ItemSeparatorComponent={() => <View style={styles.v_separator} />}
              renderMobile={(props: any) => (
                <View
                  style={{
                    paddingVertical: 5,
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Image
                    style={{
                      height: 29,
                      width: 22,
                      marginLeft: 5,
                    }}
                    source={images.document_icon}
                    resizeMode="contain"
                  />
                  <Text style={{ padding: 15 }}>{props.item.name}</Text>
                </View>
              )}
            />
          </View>
          <View style={styles.pagination}>
            <Pagination
              page={state.page}
              count={filteredUsers.length}
              rowsPerPage={state.rowsPerPage}
              rowsPerPageOptions={[5, 10, 50]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default ViewAllDocument;
