import React from 'react';
import { StyleSheet, Dimensions, View, Text, TouchableOpacity } from 'react-native';
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
} from 'react-native-gesture-handler';
import { CanvasContext, LAYER_HEIGHT, LAYER_WIDTH } from '../provider/canvas';
import { HEADER_HEIGHT } from '../header/styles.css';
import Icon from '@src/components/icon';
import colors from '@assets/colors';
import { LayerContext } from '../provider/layer';

const SIZE = 50;

type ContextType = {
  translateX: number;
  translateY: number;
};

type DraggableProps = {
  name: string;
  type: string;
  label: string;
  selected: string;
  onDrag: (value: any) => void;
};

function inside(point: any, box: any) {
  return (
    point.x > box.left &&
    point.x < box.right &&
    point.y > box.top &&
    point.y < box.bottom
  );
}

function Draggable(props: DraggableProps) {
  const panRef = React.useRef<any>();
  const opacity = useSharedValue(1);
  const offsetX = useSharedValue(0);
  const offsetY = useSharedValue(0);
  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);
  const { layers, selectedUser } = React.useContext(LayerContext);
  const { canvas, viewRef } = React.useContext(CanvasContext);
  const { width, height } = Dimensions.get('window')

  const onMeasure = () => {
    viewRef.current?.measure(
      (
        x: number,
        y: number,
        width: number,
        height: number,
        pageX: number,
        pageY: number
      ) => {
        const point = {
          x: translateX.value,
          y: translateY.value,
        };

        const box = {
          left: pageX - offsetX.value,
          top: pageY - (HEADER_HEIGHT + offsetY.value),
          right: pageX + width - offsetX.value,
          bottom: pageY + height - offsetY.value,
        };

        if (inside(point, box)) {
          const SIGN_WIDTH = LAYER_WIDTH;
          const SIGN_HEIGHT = LAYER_HEIGHT;
          const pointX = point.x - box.left;
          const pointY = point.y - box.top;
          const calculateX = box.right - point.x;
          const calculateY = box.bottom - point.y;
          const compareX = calculateX > SIGN_WIDTH;
          const compareY = calculateY > SIGN_HEIGHT;
          const xxx = compareX ? pointX : pointX - (SIGN_WIDTH - calculateX);
          const yyy = compareY ? pointY : pointY - (SIGN_WIDTH - calculateY);
          let arrayLayers: any = layers
          let length = arrayLayers[0].layerFields.length
          switch (props.type) {
            case 'signature':
            case 'initials':
              props.onDrag({
                type: props.type,
                x: (Math.abs(xxx / canvas.scale) + ((length-1) * (SIGN_WIDTH))),
                y: Math.abs(yyy / canvas.scale),
                width: SIGN_WIDTH,
                height: SIGN_HEIGHT,
              });
              break;
            case 'signature_fullname':
            case 'signature_date':
              props.onDrag({
                type: props.type,
                x: (Math.abs(xxx / canvas.scale) + ((length-1) * (SIGN_WIDTH + 10))),
                y: Math.abs(yyy / canvas.scale),
                width: SIGN_WIDTH,
                height: SIGN_HEIGHT,
                fontSize: 10,
              });
              break;
            case 'qrcode_sign':
              props.onDrag({
                type: props.type,
                x: (Math.abs(xxx / canvas.scale) + ((length-1) * (SIGN_WIDTH + 10))),
                y: Math.abs(yyy / canvas.scale),
                width: SIGN_HEIGHT,
                height: SIGN_HEIGHT,
              });
              break;
            default:
              props.onDrag({
                type: props.type,
                x: (Math.abs(xxx / canvas.scale) + ((length-1) * (SIGN_WIDTH + 10))),
                y: Math.abs(yyy / canvas.scale),
                width: 100,
                fontSize: 10,
              });
              break;
          }

          opacity.value = 0;
          translateX.value = 0;
          translateY.value = 0;
          opacity.value = withTiming(1);
        } else {
          translateX.value = withTiming(0);
          translateY.value = withTiming(0);
        }
      }
    );
  }

  const panGestureEvent = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    ContextType
  >({
    onStart: (event, context) => {
      context.translateX = translateX.value;
      context.translateY = translateY.value;
      offsetX.value = event.absoluteX - event.x;
      offsetY.value = event.absoluteY - (event.y + HEADER_HEIGHT);
    },
    onActive: (event, context) => {
      translateX.value = event.translationX + context.translateX;
      translateY.value = event.translationY + context.translateY;
    },
    onEnd: () => {
      const hasSelectedField = layers && layers[0]?.layerFields
        .filter((field: any) => selectedUser?.name?.includes(field?.name) && field?.type).length
      if (!hasSelectedField) {
        onMeasure()
      }
    },
  });
  const rStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,

      transform: [
        {
          translateX: translateX.value,
        },
        {
          translateY: translateY.value,
        },
      ],
    };
  });

  return (
    <TouchableOpacity onPress={() => {
      const hasSelectedField = layers && layers[0]?.layerFields
        .filter((field: any) => selectedUser?.name?.includes(field?.name) && field?.type).length
      if (!hasSelectedField) {
        let totalWidth = width - canvas.width
        let totalHeight = height - canvas.height
        offsetX.value = 20;
        offsetY.value = canvas.height*.4
        translateX.value = 40;
        translateY.value = 50
        onMeasure()
      }
    }} style={styles.container}>
      <PanGestureHandler ref={panRef} onGestureEvent={panGestureEvent}>
        <Animated.View style={[styles.square, rStyle, { backgroundColor: props.selected ? colors.lilac : colors.offwhite}]}>
          <Icon name={props.name} />
          <Text style={styles.t_label}>{props.label}</Text>
        </Animated.View>
      </PanGestureHandler>
    </TouchableOpacity>
  );
}

export default Draggable;

const styles = StyleSheet.create({
  container: {
    marginTop: 15,
    width: '100%',
  },
  square: {
    height: SIZE,
    width: '100%',
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    backgroundColor: colors.offwhite,
  },

  t_label: {
    fontFamily: 'DMSans',
    fontSize: 15,
    lineHeight: 20,
    marginLeft: 20,
  },
});
