/* eslint-disable react-native/no-inline-styles */
import React, { useContext, useEffect, useCallback, useState } from 'react';
import { View, Text, Pressable } from 'react-native';
import Button01 from '@src/components/button/Button01';
import Icon from '@expo/vector-icons/MaterialIcons';
import 'react-native-gesture-handler';
import colors from '@assets/colors';
import useStyles from './styles';
import './styles.css';
import { AppRoutingContext } from '@src/navigators/AppRouting';
import { TextInput02 } from '@src/components/textinput';
import { useNavigation } from '@react-navigation/native';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import WitnessItemMobile from './witness-item-mobile';
import { LayerContext } from '../../signature-fields/provider/layer';
import { nanoid } from '@reduxjs/toolkit';
import * as Types from './types';
import _ from 'lodash';

const initialWitness: Types.WitnessValue[] = [
  {
    id: nanoid(),
    name: '',
    email: '',
    fill: colors.signature2,
    ref: React.createRef(),
  },
];

const AddWitnessMobile = () => {
  const styles = useStyles();
  const [error, setError] = React.useState(false);
  const { onShowHeader, onHideHeader } = useContext(AppRoutingContext);
  const { onAddPrincipal } = useContext(LayerContext);
  const navigation =
    useNavigation<NavigationScreenProps<'PartnerNotariesMobile'>>();
  const [witnesses, setWitness] =
    useState<Types.WitnessValue[]>(initialWitness);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [disableBtn, setdisableBtn] = useState(false);

  const formValueAnd = (name && email) === ''
  const formValueOr = (name || email) === ''

  const addWitness = useCallback(() => {
    const randomColor = Math.random().toString(16).substr(-6);

    const newWitnesses = {
      id: nanoid(),
      name: name,
      email: email,
      userType: 'Witness',
      fill: `#${randomColor}`,
      ref: React.createRef(),
    };

    const updatedWitnesses =
      witnesses && witnesses[0].name === name && witnesses[0].email === email
        ? [newWitnesses]
        : [...witnesses, newWitnesses];

    setWitness(updatedWitnesses);
    onAddPrincipal([newWitnesses]);
    setName('');
    setEmail('');
  }, [witnesses, name, email]);

  const updateWitness = useCallback(
    (value: Types.WitnessValue[]) => {
      setWitness(value);
    },
    [witnesses]
  );

  useEffect(() => {
    onHideHeader();
    return () => {
      onShowHeader();
    };
  }, []);

  const onRemove = React.useCallback(
    (index: number) => {
      const witness = witnesses.slice();
      witness.splice(index, 1);
      updateWitness(witness);
    },
    [witnesses]
  );

  React.useEffect(() => {
    setError(false);
  }, [witnesses.length]);

  const nextBtn = () => {
    let newError: any = {};
    if (!email) {
      newError.email = 'Email is required.';
    }
    if (!name) {
      newError.name = 'Password is required.';
    }

    setError(newError);

    if (_.isEmpty(newError)) {
    setdisableBtn(false)
    
    addWitness();
    setdisableBtn(true)
    navigation.navigate('PartnerNotariesMobile');
    }
  };

  const nextBtn2 = () => {
    addWitness();
    navigation.navigate('PartnerNotariesMobile');
  };

  return (
    <View style={styles.container}>
      <View style={styles.formContainer}>
        <View style={styles.header}>
          <Pressable style={styles.arrow} onPress={() => navigation.goBack()}>
            <Icon name="keyboard-arrow-left" color={colors.white} size={24} />
          </Pressable>
          <Text style={styles.headerText}>Add Witnesses</Text>
        </View>
        {witnesses.map((item, index) => (
          <WitnessItemMobile
            setName={setName}
            setEmail={setEmail}
            key={index.toString()}
            ref={item.ref}
            index={index}
            item={item}
            onRemove={() => onRemove(index)}
          />
        ))}
        <Pressable onPress={addWitness} style={styles.addContainer}>
          <Icon name="add-circle" size={15} color={colors.purple} />
          <Text style={styles.body1}>Add another witnesses</Text>
        </Pressable>
        {/* <View style={styles.btnContainer}>
          <Button01
            onPress={nextBtn}
            label="Proceed without adding witnesses"
            style={styles.btnBack}
            labelStyle={{ color: colors.purple }}
          />
          <Button01 onPress={nextBtn} style={styles.btnNext} />
        </View> */}
        <View style={styles.btnContainer}>
          <Button01
            onPress={nextBtn2}
            label="Proceed without adding principal"
            style={formValueOr ? styles.btnBack : styles.btnBack2}
            labelStyle={formValueOr ? { color: colors.purple } : {color: colors.white}}
            disabled={formValueOr ? disableBtn : !disableBtn}
          /> 
          <Button01 onPress={nextBtn} style={styles.btnNext} disabled={formValueAnd ? !disableBtn : disableBtn} />
        </View>
      </View>
    </View>
  );
};

export default AddWitnessMobile;
