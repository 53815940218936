import React, { useContext } from 'react';
import { DrawerContext } from '../../DrawerProvider';
import Loading from '@src/components/loading';
import { ScrollView } from 'react-native';
import useStyles from './styles.css';
import { useAuthService } from '@src/ducks/hooks';

// SCREENS
const Notification = React.lazy(() => import('screens/drawer-screens/notification'));
const Dashboard = React.lazy(() => import('screens/drawer-screens/dashboard'));
const LiveSessionList = React.lazy(() => import('screens/drawer-screens/live-session-list'));
const FaceRecognition = React.lazy(() => import('screens/drawer-screens/face-recognition'));
const FaceVerified = React.lazy(() => import('@src/screens/gnotary/face-verified'));
const Inbox = React.lazy(() => import('screens/drawer-screens/inbox'));
const Applications = React.lazy(
  () => import('screens/drawer-screens/applications')
);
const DocumentsPublic = React.lazy(
  () => import('screens/drawer-screens/documents')
);
const Transactions = React.lazy(
  () => import('screens/drawer-screens/transactions')
);
const Account = React.lazy(() => import('screens/drawer-screens/account'));
const DigitalSteamStamp = React.lazy(
  () => import('screens/drawer-screens/digital-seal-stamp')
);
const DigitalNotarialBook = React.lazy(
  () => import('screens/drawer-screens/digital-notarial-book')
);
const LiveNotarizationRequest = React.lazy(
  () => import('screens/drawer-screens/live-notarization-request')
);
const Faq = React.lazy(() => import('screens/drawer-screens/faq'));
const Support = React.lazy(() => import('screens/drawer-screens/support'));
const PartnerNotaries = React.lazy(
  () => import('screens/drawer-screens/partner-notaries')
);
const Signatures = React.lazy(
  () => import('screens/drawer-screens/signatures')
);
const ViewAllDocument = React.lazy(() => import('screens/drawer-screens/dashboard/view-all-document'))

//ADMIN SCREENS
const ManageUsers = React.lazy(
  () => import('screens/drawer-screens/admin/manage-users')
);

const ManageGroups = React.lazy(
  () => import('screens/drawer-screens/admin/manage-groups')
);

const AuditLog = React.lazy(
  () => import('screens/drawer-screens/admin/audit-log')
);

const DrawerItemBody = () => {
  const styles = useStyles();
  const { activeTab } = useContext(DrawerContext);
  const { onLogout } = useAuthService();

  const renderContent = () => {
    switch (activeTab) {
      case 'Notification':
        return <Notification />;
      case 'Dashboard':
        return <Dashboard />;
      case 'Applications':
        return <Applications />;
      case 'Transactions':
        return <Transactions />;
      case 'Digital Seal and Stamp':
        return <DigitalSteamStamp />;
      case 'Digital Notarial Book':
        return <DigitalNotarialBook />;
      case 'Live Notarization Requests':
        return <LiveNotarizationRequest />;
      case 'Partner Notaries':
        return <PartnerNotaries />;
      case 'Signatures':
        return <Signatures />;
      case 'Account':
        return <Account />;
      case 'FAQ':
        return <Faq />;
      case 'Support':
        return <Support />;
      case 'Inbox':
        return <Inbox />;
      case 'Documents':
        return <DocumentsPublic />;
      case 'Manage Users':
        return <ManageUsers />;
      case 'Manage Groups':
        return <ManageGroups />;
      case 'Audit Log':
        return <AuditLog />;
      case 'Live Session List':
        return <LiveSessionList />;
      case 'Face Recognition':
        return <FaceRecognition />;
      case 'View All Document':
        return <ViewAllDocument />;
      case 'Face Verified':
        return <FaceVerified shouldUploadDocument={false as any} />;
      case 'Log out':
        onLogout();
        return <Loading />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.item_content_container}
    >
      <React.Suspense fallback={<Loading />}>{renderContent()}</React.Suspense>
    </ScrollView>
  );
};

export default DrawerItemBody;
