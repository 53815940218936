import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import Splash from '@src/components/loading';
import {AppRoutingProvider} from './AppRouting';
import Linking from './Linking';

const AppNavigator = () => {
  return (
    <NavigationContainer linking={Linking} fallback={<Splash />}>
      <AppRoutingProvider />
    </NavigationContainer>
  );
};

export default AppNavigator;
