import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import colors from '@assets/colors';
import { StyleSheet } from 'react-native';

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, alignItems: 'center', paddingBottom: 40 },
    formContainer: {
      width: '50%',
      padding: 47,
      backgroundColor: colors.white,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: colors.border2,
    },
    witnessContainer: {
      marginTop: 80,
      borderWidth: 1,
      borderRadius: 8,
      borderColor: colors.border1,
      marginLeft: 30,
      marginRight: 30,
    },
    witnessInputContainer: {
      padding: 23,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    v_divider: { width: 15 },
    addContainer: { flexDirection: 'row', alignItems: 'center', marginTop: 29 },
    btnContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 40,
    },
    witnessHeader: {
      padding: 23,
      flexDirection: 'row',
      backgroundColor: colors.lilac,
      justifyContent: 'center',
    },
    witnessLabel: {
      ...TypographyStyle.Body2Regular,
      color: colors.purple,
      fontWeight: '700',
    },
    input: { marginTop: 0, width: '48%' },
    body1: { ...TypographyStyle.Body1Regular, marginLeft: 10 },
    body2: { ...TypographyStyle.Body2Regular, color: colors.black3 },
    body2_bold: { fontFamily: 'DMSans-Bold' },
    btnBack: {
      width: '30%',
      alignSelf: 'center',
      backgroundColor: colors.white,
      borderWidth: 1,
      borderColor: colors.purple,
      marginRight: 12,
    },
    btnBack2: {
      width: '30%',
      alignSelf: 'center',
      backgroundColor: colors.white,
      borderWidth: 1,
      borderColor: colors.purple,
      marginRight: 12,
    },
    btnNext: { width: '30%', alignSelf: 'center' },
    viewContainerSeeDetails: {
      backgroundColor: colors.white,
    },

    header: {
      backgroundColor: colors.purple,
      top: 0,
      height: 50,
      width: '100%',
      flex: 1,
      textAlign: 'center',
      position: 'absolute'
    },
    headerText: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      {
        color: colors.white,
        fontWeight: '700',
        padding: 15
      },
    ]),

    inputV: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    w70: { width: '70%' },
    w71: {
      paddingHorizontal: 30,
      marginTop: 20,
    },
    btnText: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.colorPrimary,
        fontWeight: '700',
        textAlign: 'center',
        paddingHorizontal: 110
      },
    ]),

    footerContainer: { marginTop: 50 },
    arrow: {
      color: colors.white,
      width: '24',
      height: '24',
      marginLeft: 20
    }
  },
  {
    [DEVICE_SIZES.XL]: {},
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      container: { flex: 1, alignItems: 'center', paddingBottom: 40 },
      formContainer: {
        width: '100%',
        paddingHorizontal: 20,
      },
      witnessInputContainer: {
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      input: { width: '100%', marginTop: 20 },
      btnContainer: {
        flexDirection: 'column-reverse',
      },
      btnBack: { width: '100%', marginTop: 15, marginRight: 0 },
      btnNext: { width: '100%' },
    },
    [DEVICE_SIZES.XS]: {
      formContainer: {
        width: '100%',
        height: '100%',
        padding: 0,
        borderRadius: 0
        // paddingHorizontal: 20,
      },
      witnessInputContainer: {
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      body1: { justifyContent: 'center' },
      addContainer: { justifyContent: 'center' },
      input: { width: '100%', marginTop: 20 },
      btnContainer: {
        flexDirection: 'column-reverse',
        paddingHorizontal: 30,
        paddingVertical: 200,
      },
      btnBack: { width: '100%', marginTop: 15, marginRight: 0 },
      btnBack2: { width: '100%', marginTop: 15, marginRight: 0, backgroundColor: colors.border2, borderColor: colors.border2 },
      btnNext: { width: '100%' },
      container: {
        width: '100%',
        height: '100%',
        color: colors.white,
        paddingBottom: 0,
      },
      viewContainerSeeDetails: {
        width: '100%',
        height: '100%',
        backgroundColor: colors.white,
        paddingHorizontal: 0,
        paddingVertical: 0,
      },
      header: {
        backgroundColor: colors.purple,
        top: 0,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        alignItems: 'center'
      },
      headerText: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.white,
          fontWeight: '700',
          padding: 15,
          flex: 1,
          marginLeft: -40
        },
      ]),
      w70: {
        marginTop: 100,
        paddingHorizontal: 30,
      },
      w71: {
        paddingHorizontal: 30,
        marginTop: 20,
      },
    },
  }
);

export default useStyles;
