import React, { memo } from 'react';
import Initials from './initials';
import Signature from './signature';
import SignatureNotary from './signature-notary';
import SignatureFullname from './signature-fullname';
import SignatureDate from './signature-date';
import Text from './text';

type ItemsProps = {
  field: any;
  onSelect: () => void;
};

const Items = (props: ItemsProps) => {
  if (props.field.type === 'signature') {
    return <Signature field={props.field} onSelect={props.onSelect} />;
  } else if (props.field.type === 'initials') {
    return <Initials field={props.field} onSelect={props.onSelect} />;
  } else if (props.field.type === 'signature_fullname') {
    return <SignatureFullname field={props.field} onSelect={props.onSelect} />;
  } else if (props.field.type === 'signature_date') {
    return <SignatureDate field={props.field} onSelect={props.onSelect} />;
  } else if (props.field.type === 'signature_notary') {
    return <SignatureNotary field={props.field} onSelect={props.onSelect} />;
  }

  return <Text field={props.field} />;
};

export default memo(Items);
