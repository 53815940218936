import React from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import useStyles from './styles.css'

const WIDTH = Dimensions.get('window').width;
const HEIGHT_MODAL = 120;

const LiveSessionInformationModal = () => {
    const styles = useStyles()

  return (
    <TouchableOpacity
      disabled={true}
      style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
    >
      <View
        style={{
          height: HEIGHT_MODAL,
          paddingTop: 10,
          backgroundColor: 'white',
          borderRadius: 10,
          width: WIDTH - 30,
          position: 'absolute',
          top: 70
        }}
      >
        <View style={styles.liveSessionModal}>
          <Text style={styles.liveSessionTextHeader}>
            Live Session with <span style={{fontWeight: '700'}}>Atty. Juan Martin</span></Text>
          <Text style={styles.liveSessionText}>Non-Disclosure Agreement</Text>
          <Text style={styles.liveSessionText2}>21 December 2023 | 2:00 PM</Text>
          
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default LiveSessionInformationModal;
