import React, { useEffect } from 'react';
// import { HeaderContext } from './provider/header';
import Loading from '@src/components/loading';
import { ScrollView } from 'react-native';
import useStyles from './styles';
import WitnessSignature from './witness-signature';
import { LiveSessionContext } from './provider';

// SCREENS
// const Identification = React.lazy(() => import('./identification'));
const VideoSession = React.lazy(() => import('./video-session'));
// const WaitingApproval = React.lazy(() => import('./waiting-approval'));
// const Documents = React.lazy(() => import('./documents'));
// const Review = React.lazy(() => import('./review'));

// const Screens = ({ selectedDocument} : { selectedDocument: any}) => {
const Screens = () => {
  const styles = useStyles();
  const scrollRef = React.useRef<any>();
  const { activeIndex, } =  React.useContext(LiveSessionContext);
  const components = React.useMemo(() => {
    switch (activeIndex) {
      case 0:
        return <WitnessSignature />;
      case 1:
        return <VideoSession />;
    }
  }, [activeIndex]);

  // const components = React.useMemo(()  => <WitnessSignature />, []);

  // useEffect(() => {
  //   if(selectedDocument) {
  //     onSetCurrentPage(5)
  //   }
  // },[selectedDocument])

  // const handleScroll = (event: any) => {
  //   onUpdateScrollPosition({
  //     x: event.nativeEvent.contentOffset.x,
  //     y: event.nativeEvent.contentOffset.y,
  //   } as any);
  // };

  return (
    <ScrollView
      ref={scrollRef}
      // onScroll={handleScroll}
      scrollEventThrottle={16}
      style={styles.scrollview}
    >
      <React.Suspense fallback={<Loading />}>{components}</React.Suspense>
    </ScrollView>
  );
};

export default React.memo(Screens);
