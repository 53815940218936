/* eslint-disable react/no-unstable-nested-components */
import React, { useRef, useCallback } from 'react';
import {
  View,
  Text,
  Modal,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  useWindowDimensions,
  TouchableWithoutFeedback,
  Image,
} from 'react-native';
import { colors, images } from 'theme';
import Icon from '@expo/vector-icons/MaterialIcons';
import { useAppSelector } from '@src/ducks/ducksHook';
import {
  selectUserEmail,
  selectUserFullname,
} from '@src/ducks/slices/user.slice';

const ITEM_HEIGHT = 50;
export interface MeasureValue {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface MenuDropdownProps {
  options: any;
  visible: boolean;
  accessible?: boolean;
  measures: MeasureValue;
  onClose: () => void;
  onSelected: (item: any) => void;
}

const ProfileMenu = (props: MenuDropdownProps) => {
  const flatlist = useRef(null);
  const email = useAppSelector(selectUserEmail);
  const fullname = useAppSelector(selectUserFullname);
  const { height: HEIGHT } = useWindowDimensions();
  const stylePosition = React.useMemo(() => {
    const { x, y, width, height } = props.measures;
    const positionStyle = {
      width: 350,
      left: x - 350 + width,
      top: y + height,
    };

    return positionStyle;
  }, [props.measures, HEIGHT]);

  const RenderItem = useCallback(({ item, index }: any) => {
    let newRow = item;

    if (item && item.name && item.name.common) {
      newRow = item.name.common;
    } else if (item && item.name) {
      newRow = item.name;
    } else {
      newRow = item;
    }

    return (
      <TouchableOpacity
        key={`${index}`}
        onPress={() => {
          props?.onSelected(item.name);
          props?.onClose();
        }}
        style={[styles.v_item]}
      >
        <Icon name={item.icon} size={24} color="black" />
        <Text style={styles.t_item}>{newRow}</Text>
      </TouchableOpacity>
    );
  }, []);

  const ListHeaderComponent = () => {
    return (
      <View style={styles.v_header}>
        <Image
          style={styles.header_i_profile}
          source={images.placeholder_profile}
          resizeMode="stretch"
        />
        <View style={styles.header_v_info}>
          <Text style={styles.header_t_fullname}>{fullname}</Text>
          <Text style={styles.header_t_email}>{email}</Text>
        </View>
      </View>
    );
  };

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={'none'}
      onRequestClose={props.onClose}
      supportedOrientations={[
        'portrait',
        'portrait-upside-down',
        'landscape',
        'landscape-left',
        'landscape-right',
      ]}
    >
      <TouchableWithoutFeedback
        accessible={props.accessible}
        onPress={props.onClose}
      >
        <View style={styles.modal}>
          <View style={[styles.dropdown, stylePosition]}>
            <FlatList
              ref={flatlist}
              data={props.options}
              showsVerticalScrollIndicator={false}
              ItemSeparatorComponent={() => (
                <View style={styles.itemSeparator} />
              )}
              ListHeaderComponent={<ListHeaderComponent />}
              keyExtractor={(_, index) => `${index}`}
              renderItem={RenderItem}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: { flexGrow: 1 },
  dropdown: {
    width: 400,
    position: 'absolute',
    borderRadius: 4,
    backgroundColor: 'white',
    justifyContent: 'center',
    shadowColor: 'gray',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.6,
    shadowRadius: 10,
  },
  itemSeparator: {
    height: 1,
    marginHorizontal: 10,
    backgroundColor: colors.offwhite,
  },
  v_item: {
    height: ITEM_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  t_item: {
    flex: 1,
    fontSize: 15,
    lineHeight: 20,
    marginLeft: 20,
    color: colors.black,
    fontFamily: 'DMSans',
  },

  // LISTITEMHEADER
  v_header: {
    height: 100,
    paddingHorizontal: 10,
    backgroundColor: colors.purple,
    flexDirection: 'row',
    alignItems: 'center',
  },
  header_i_profile: { width: 50, height: 50, borderRadius: 50 / 2 },
  header_v_info: { marginLeft: 10 },
  header_t_fullname: {
    fontFamily: 'DMSans',
    fontSize: 17,
    color: colors.white,
    lineHeight: 20,
  },
  header_t_email: {
    fontFamily: 'DMSans',
    fontSize: 14,
    color: colors.white,
    lineHeight: 20,
  },
});

export default ProfileMenu;
