import { useCallback, useState } from 'react';
import _ from 'lodash';

export const useValidation = () => {
  const [error, setError] = useState<any>({});

  const checkValidation = useCallback(
    (requiredFields: any, stateInput: any) => {
      const newError: any = {};
      const ArrayConvert = _.isArray(requiredFields)
        ? requiredFields
        : Object.keys(requiredFields);
      ArrayConvert.forEach((field: any) => {
        if (!stateInput[field]) {
          newError[field] = 'This field is required';
        }
      });

      if (!_.isEmpty(newError)) {
        setError(newError);
      }

      return newError;
    },
    []
  );

  const onSetError = useCallback(
    (newError: any) => {
      setError(newError);
    },
    [error]
  );

  return { errorInput: error, checkValidation, onSetError };
};

export const hasDuplicateEmails = (array: any[]) => {
  const emailSet = new Set();

  for (const obj of array) {
    if (obj.hasOwnProperty('email')) {
      if (emailSet.has(obj.email)) {
        obj?.ref?.current?.onError({ email: 'Duplicate email' });
        return true; // Duplicate email found
      } else {
        emailSet.add(obj.email);
      }
    }
  }

  return false; // No duplicate emails found
};
