import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StyleSheet } from 'react-native';
import TypographyStyle from '@assets/typography';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.white,
    },
    v_body: {
      flex: 1,
      // flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_logo_container: {
      width: '100%',
    },
    imgContainer: {
      justifyContent: 'center',
      alignItems: 'center',
       width: '100%', height: 150 
    },
    live_session_concluded: { width: 80, height: 80  },
    txtNote1: StyleSheet.flatten([TypographyStyle.heading2Bold, {
      marginBottom: 25,
    }]),
    txtNote2: StyleSheet.flatten([TypographyStyle.heading4Regular]),
    txtNote3: StyleSheet.flatten([TypographyStyle.Body2Regular, {
      marginBottom: 50,
    }]),
    textContainer: {},
    textContainer2: { 
      paddingHorizontal: 50,
    },
    btn_container: { paddingHorizontal: 30, },
    btn_uploadDocument: { marginTop: 30, backgroundColor: colors.black },
    lbl_uploadDocument: { fontFamily: 'Montserrat-Bold' },

    review_documents_btn_border_transparent: {
      width: "100%",
      marginTop: 10,
      borderRadius: 8,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 32,
      paddingRight: 32,
      paddingVertical: 15,
      // backgroundColor: colors.purple,
      borderWidth: 1,
      borderColor: colors.purple,
    },
    review_documents_label_transparent:StyleSheet.flatten([
      TypographyStyle.buttonText,
      { 
        fontFamily: 'DMSans', 
        color: colors.purple 
      },
    ]),
  },
  {
    [DEVICE_SIZES.XS]: {
      live_session_concluded: { width: 80, height: 80 },
      v_body: {
        justifyContent: 'center',
        textAlign: 'center',
      },
      imgContainer: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      textContainer: { paddingBottom: 100, marginTop: 5 },
      txtNote1: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        { color: colors.black, fontWeight: '700', lineHeight: 23 },
      ]),
      txtNote2: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          fontWeight: '700',
          color: colors.purple,
          paddingBottom: 30,
          lineHeight: 23,
        },
      ]),
      txtNote3: StyleSheet.flatten([
        TypographyStyle.heading6Regular,
        { lineHeight: 20 },
      ]),
      btn_container: {
        bottom: 0,
        width: '100%',
      },
      btn_uploadDocument: { marginTop: 30, backgroundColor: colors.purple },
      lbl_uploadDocument: StyleSheet.flatten([
        TypographyStyle.BodySRegular,
        { fontWeight: '700', color: colors.white },
      ]),
    },
  }
);

export default useStyles;
