import QRCodeStyling from 'qr-code-styling';
import { blobToBase64 } from 'base64-blob';
import { WEB_BASE_URL } from '@env';
// import images from '@assets/images';
import colors from '@assets/colors';

const qrCode = new QRCodeStyling({
  type: 'svg',
  // image: images.ic_logo,
  dotsOptions: {
    color: colors.black3,
    type: 'rounded',
  },
  imageOptions: {
    imageSize: 0.5,
    crossOrigin: 'anonymous',
  },
  cornersSquareOptions: {
    type: 'extra-rounded',
  },
} as any);

async function QRStyling(width: number, height: number, id: string) {
  qrCode.update({
    data: `${WEB_BASE_URL}/document/${id}`,
    width: width,
    height: height,
  });

  const blob: any = await qrCode.getRawData();

  return await blobToBase64(blob);
}

export default QRStyling;
