/* eslint-disable react-native/no-inline-styles */
import React, { useContext, useEffect, useCallback, useState } from 'react';
import { View, Text, Pressable } from 'react-native';
import Button01 from '@src/components/button/Button01';
import Icon from '@expo/vector-icons/MaterialIcons';
import 'react-native-gesture-handler';
import colors from '@assets/colors';
import useStyles from './styles';
import './styles.css';
import { AppRoutingContext } from '@src/navigators/AppRouting';
import { TextInput02 } from '@src/components/textinput';
import { useNavigation } from '@react-navigation/native';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import PrincipalItemMobile from './principal-item-mobile';
import { LayerContext } from '../../signature-fields/provider/layer';
import { nanoid } from '@reduxjs/toolkit';
import { useValidation } from '@src/utils/validation-helper';
import _ from 'lodash';
import * as Types from './types';

const initialPrincipal: Types.PrincipalValue[] = [
  {
    id: nanoid(),
    name: '',
    email: '',
    fill: colors.signature2,
    ref: React.createRef(),
  },
];

const AddPrincipalMobile = () => {
  const styles = useStyles();
  const [error, setError] = React.useState(false);
  const { onShowHeader, onHideHeader } = useContext(AppRoutingContext);
  const { onAddPrincipal } = useContext(LayerContext);
  const navigation =
    useNavigation<NavigationScreenProps<'PartnerNotariesMobile'>>();
  const [principals, setPrincipals] =
    useState<Types.PrincipalValue[]>(initialPrincipal);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [disableBtn, setdisableBtn] = useState(false);
  const { errorInput, checkValidation, onSetError } = useValidation();

  const formValueAnd = (name && email) === ''
  const formValueOr = (name || email) === ''

  const addPrincipal = useCallback(() => {
    const randomColor = Math.random().toString(16).substr(-6);

    const newPrincipal = {
      id: nanoid(),
      name: name,
      email: email,
      userType: 'Principal',
      fill: `#${randomColor}`,
      ref: React.createRef(),
    };

    const updatedPrincipals =
      principals && principals[0].name === name && principals[0].email === email
        ? [newPrincipal]
        : [...principals, newPrincipal];

    setPrincipals(updatedPrincipals);
    onAddPrincipal([newPrincipal]);
    setName('');
    setEmail('');
  }, [principals, name, email]);

  const updateWitness = useCallback(
    (value: Types.PrincipalValue[]) => {
      setPrincipals(value);
    },
    [principals]
  );

  useEffect(() => {
    onHideHeader();
    return () => {
      onShowHeader();
    };
  }, []);

  const onRemove = React.useCallback(
    (index: number) => {
      const witness = principals.slice();
      witness.splice(index, 1);
      updateWitness(witness);
    },
    [principals]
  );

  React.useEffect(() => {
    setError(false);
  }, [principals.length]);

  const nextBtn = () => {
    let newError: any = {};
    if (!email) {
      newError.email = 'Email is required.';
    }
    if (!name) {
      newError.name = 'Password is required.';
    }

    setError(newError);

    if (_.isEmpty(newError)) {
    setdisableBtn(false)
    addPrincipal();
    navigation.navigate('AddWitnessMobile');
    }
  };

  const nextBtn2 = () => {
    addPrincipal();
    navigation.navigate('AddWitnessMobile');
  };

  return (
    <View style={styles.container}>
      <View style={styles.formContainer}>
        <View style={styles.header}>
          <Pressable style={styles.arrow} onPress={() => navigation.goBack()}>
            <Icon name="keyboard-arrow-left" color={colors.white} size={24} />
          </Pressable>
          <Text style={styles.headerText}>Add Principal</Text>
        </View>
        {principals.map((item, index) => (
          <PrincipalItemMobile
            setName={setName}
            setEmail={setEmail}
            key={index.toString()}
            ref={item.ref}
            index={index}
            item={item}
            onRemove={() => onRemove(index)}
          />
        ))}
        <Pressable onPress={addPrincipal} style={styles.addContainer}>
          <Icon name="add-circle" size={15} color={colors.purple} />
          <Text style={styles.body1}>Add another principal</Text>
        </Pressable>
        <View style={styles.btnContainer}>
          <Button01
            onPress={nextBtn2}
            label="Proceed without adding principal"
            style={formValueOr ? styles.btnBack : styles.btnBack2}
            labelStyle={formValueOr ? { color: colors.purple } : {color: colors.white}}
            disabled={formValueOr ? disableBtn : !disableBtn}
          />
          <Button01 onPress={nextBtn} style={styles.btnNext} disabled={formValueAnd ? !disableBtn : disableBtn} />
        </View>
      </View>
    </View>
  );
};

export default AddPrincipalMobile;
