import React, { useContext } from 'react';
import { getClientBox, getTotalBox } from '@src/utils/signature-helper';
import { Rect, Text, Group, Transformer, Path } from 'react-konva';
import { CanvasContext } from '../../provider/canvas';
import images from '@assets/images';
import LocalImage from '../Image';
import colors from '@assets/colors';

const ACTION_IMAGE_SIZE = 50;
const ACTION_IMAGE_MARGIN = 10;

type SignatureFullNameProps = {
  field: any;
  isSelected: boolean;
  onSelect: () => void;
  onRemove: () => void;
  onDeselect: () => void;
  onChange: (item: any) => void;
};

const baseWidth = 17.81;

const Signature = (props: SignatureFullNameProps) => {
  const { field } = props;
  const trRef = React.useRef<any>();
  const shapeRef = React.useRef<any>();
  const { canvas, stageRef } = useContext(CanvasContext);
  const [move, setMove] = React.useState({ ...field });
  const actionState = React.useMemo(() => {
    let rotatedPos90 =
      Math.abs(move.rotation) === 90
        ? move.height
        : move.width + ACTION_IMAGE_MARGIN;
    const rotatedNeg90 =
      Math.trunc(move.rotation) === -90
        ? move.width + ACTION_IMAGE_SIZE
        : ACTION_IMAGE_SIZE;

    const rotatedNeg90Y = Math.trunc(move.rotation) === -90 ? move.width : 0;
    let checkX = move.x > 0 ? move.x + rotatedPos90 : rotatedPos90;
    let checkY = move.y < ACTION_IMAGE_SIZE ? 0 : move.y - rotatedNeg90;
    let deleteX = move.x > 0 ? move.x + rotatedPos90 : rotatedPos90;
    let deleteY =
      move.y < ACTION_IMAGE_SIZE ? ACTION_IMAGE_SIZE : move.y - rotatedNeg90Y;

    if (
      stageRef.current.getWidth() - (move.x + rotatedPos90) <
      ACTION_IMAGE_SIZE
    ) {
      checkX =
        stageRef.current.getWidth() - rotatedPos90 - ACTION_IMAGE_SIZE * 2;
    }

    return {
      checkX: checkX,
      checkY,
      deleteX,
      deleteY,
    };
  }, [move, stageRef.current]);

  const imagePosition = React.useMemo(() => {
    const posX = field.width * 0.40621;
    const posY = field.height * 0.11;
    const heightPercentage = field.height * 0.37494;
    const widthScale = heightPercentage / baseWidth;
    const heightScale = heightPercentage / baseWidth;

    return {
      posX,
      posY,
      scale: Math.min(widthScale, heightScale),
    };
  }, [field.width, field.height]);

  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }

    if (field) {
      setMove(field);
    }
  }, [props.isSelected, field]);

  return (
    <React.Fragment>
      <Group
        draggable
        ref={shapeRef}
        x={field.x}
        y={field.y}
        width={field.width}
        height={field.height}
        onTap={props.onSelect}
        onClick={props.onSelect}
        rotation={field.rotation}
        onDragStart={props.onSelect}
        onDragMove={() => {
          const boxes = trRef.current
            ?.nodes()
            .map((node: any) => node.getClientRect());
          const box = getTotalBox(boxes);

          trRef.current?.nodes().forEach((shape: any) => {
            const absPos = shape.getAbsolutePosition();
            const rotation = shape.getAbsoluteRotation();
            // where are shapes inside bounding box of all shapes?

            const offsetX = box.x - absPos.x;
            const offsetY = box.y - absPos.y;

            // we total box goes outside of viewport, we need to move absolute position of shape
            const newAbsPos = { ...absPos };
            if (box.x < 0) {
              newAbsPos.x = -offsetX;
            }
            if (box.y < 0) {
              newAbsPos.y = -offsetY;
            }
            if (box.x + box.width > stageRef.current.width()) {
              newAbsPos.x = stageRef.current.width() - box.width - offsetX;
            }
            if (box.y + box.height > stageRef.current.height()) {
              newAbsPos.y = stageRef.current.height() - box.height - offsetY;
            }
            shape.setAbsolutePosition(newAbsPos);

            setMove({
              x: box.x,
              y: box.y,
              rotation,
              width: box.width,
              height: box.height,
            });
          });
        }}
        onDragEnd={(e) => {
          const targetX = e.target.x();
          const targetY = e.target.y();

          props.onChange({
            ...field,
            x: Math.abs(targetX / canvas.scale),
            y: Math.abs(targetY / canvas.scale),
            width: Math.abs(field.width / canvas.scale),
            height: Math.abs(field.height / canvas.scale),
            fontSize: Math.abs(field.fontSize / canvas.scale),
          });
        }}
        onTransformEnd={(e) => {
          const node = e.target;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          const mathWidth = Math.abs(node.width() / canvas.scale);
          const mathHeight = Math.abs(node.height() / canvas.scale);

          node.scaleX(1);
          node.scaleY(1);
          props.onChange({
            ...field,
            x: Math.abs(node.x() / canvas.scale),
            y: Math.abs(node.y() / canvas.scale),
            width: Math.max(mathWidth * scaleX),
            height: Math.max(mathHeight * scaleY),
            rotation: e.target.getAbsoluteRotation(),
            fontSize: Math.max(
              Math.abs(field.fontSize / canvas.scale) * scaleX
            ),
          });
        }}
      >
        <Rect
          dash={[8, 8]}
          dashOffset={3}
          strokeWidth={2}
          cornerRadius={6.25}
          width={field.width}
          height={field.height}
          stroke={field.fill}
          fill={`${field.fill}30`}
        />
        <Path
          data="M12.9102 20.8845 H22.2832"
          x={imagePosition.posX}
          y={imagePosition.posY}
          scaleX={imagePosition.scale}
          scaleY={imagePosition.scale}
          stroke={field.fill}
          strokeWidth={2.0829}
          linecap="round"
          linejoin="round"
        />
        <Path
          data="M17.5986 3.70078C18.0129 3.28647 18.5748 3.05371 19.1608 3.05371C19.4509 3.05371 19.7382 3.11085
          20.0062 3.22188C20.2743 3.3329 20.5178 3.49564 20.7229 3.70078C20.9281 3.90593
          21.0908 4.14948 21.2019 4.41751C21.3129 4.68555 21.37 4.97283 21.37 5.26295C21.37
          5.55308 21.3129 5.84036 21.2019 6.1084C21.0908 6.37643 20.9281 6.61998 20.7229 6.82513L7.70485
          19.8432L3.53906 20.8847L4.58051 16.7189L17.5986 3.70078Z"
          x={imagePosition.posX}
          y={imagePosition.posY}
          scaleX={imagePosition.scale}
          scaleY={imagePosition.scale}
          stroke={field.fill}
          strokeWidth={2.0829}
          linecap="round"
          linejoin="round"
        />
        <Text
          text="Sign"
          padding={field.height * 0.05}
          align="center"
          width={field.width}
          height={field.height}
          fontFamily="DMSans"
          verticalAlign="bottom"
          fill={field.fill}
          fontSize={field.height / 3.5}
        />
      </Group>

      {props.isSelected && (
        <>
          <LocalImage
            x={actionState.checkX}
            y={actionState.checkY}
            width={ACTION_IMAGE_SIZE}
            height={ACTION_IMAGE_SIZE}
            onClick={props.onDeselect}
            onTap={props.onDeselect}
            verticalAlign="middle"
            src={images.ic_check}
          />
          <LocalImage
            x={actionState.checkX}
            y={actionState.deleteY}
            width={ACTION_IMAGE_SIZE}
            height={ACTION_IMAGE_SIZE}
            onClick={props.onRemove}
            onTap={props.onRemove}
            verticalAlign="middle"
            src={images.ic_delete}
          />
        </>
      )}

      {props.isSelected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          rotationSnapTolerance={45}
          rotationSnaps={[0, 90, 180, 270]}
          boundBoxFunc={(oldBox, newBox) => {
            const box = getClientBox(newBox);
            const isOut =
              box.x < 0 ||
              box.y < 0 ||
              box.x + box.width > stageRef.current?.getWidth() ||
              box.y + box.height > stageRef.current?.getHeight();

            if (isOut) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(Signature);
