import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button01 } from '@src/components/button';
import { SignupContext } from './SignupProvider';
import { Text, View } from 'react-native';
import useStyles from './styles.css';

type EmailSentProps = {
  notary?: boolean;
};

const EmailSent = ({ notary }: EmailSentProps) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { updateScreen } = React.useContext(SignupContext);

  const onBack = () => {
    updateScreen('');
    navigation.navigate('Signin');
  };

  const principalContent = () => {
    return (
      <View style={styles.signInCenter}>
        <Text style={styles.t_email_sent}>Email sent</Text>
        <Text style={styles.t_email_sent_notes}>
          Click the link that was sent to your email to complete the
          verification process. You will only be able to access the dashboard
          once you are verified.
        </Text>
      </View>
    );
  };

  const notaryContent = () => {
    return (
      <View style={styles.signInCenter}>
        <Text style={styles.t_email_sent_notes}>
          Your registration as notary public has been received.
        </Text>
        <Text style={[styles.t_email_sent_notes, styles.t_marginTop]}>
          Please check your email for the status of your registration. You can
          only access the dashboard once you are approved.
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.v_signup}>
      {notary ? principalContent() : notaryContent()}
      {/* {notary ? principalContent() : notaryContent()} */}
      <Button01
        label="Back to log in"
        style={styles.btn_backtologin}
        labelStyle={styles.lbl_signup}
        onPress={onBack}
      />
      <Text style={styles.t_email_sent_notes2}>
        Did not receive the email? Check your spam or junk folder
      </Text>
    </View>
  );
};

export default EmailSent;
