import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, marginTop: 10, },
    header_label: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { textAlign: 'left', color: colors.black3, marginBottom: 20 },
    ]),
    documents_content: {
      flexDirection: 'row', 
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20,
      marginTop: 10
    },
    documents_label: {
      ...TypographyStyle.heading4Regular
    },
    view_all: {
      color: colors.gray1,
    },
    v_separator: {
      height: 0,
    },
    v_height: { height: 50 },
    ti_search: {
      flex: 1,
      borderRadius: 8,
      backgroundColor: colors.border6,
    },
    ti_border_style: { flex: 1, backgroundColor: colors.white },
    ti_style: { marginTop: 0, width: '100%', height: '100%' },
    cr_i_next: { tintColor: colors.white, marginTop: 8, marginLeft: 6 },
    cr_card_btn: {
      flex: 1,
      marginVertical: 25,
      borderRadius: 8,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 32,
      paddingRight: 32,
      paddingVertical: 15,
      backgroundColor: colors.purple,
    },
    cr_card_btn_label: StyleSheet.flatten([
      TypographyStyle.buttonText,
      { fontFamily: 'DMSans', color: colors.white },
    ]),
    badge_btn: {
      alignItems: 'center', 
      justifyContent: 'center', 
      width: 30, 
      height: 30, 
      marginHorizontal: 10,
      borderRadius: 15,
      backgroundColor: colors.white
    },
    badge_btn_label: { color: colors.red }
  },
  {
    [DEVICE_SIZES.LARGE_DEVICE]: {},
    [DEVICE_SIZES.SM]: {
      v_separator: {
        height: 1,
        opacity: 0.3,
        backgroundColor: colors.purple,
      },
    },
    [DEVICE_SIZES.XS]: {
      v_separator: {
        height: 1,
        opacity: 0.3,
        backgroundColor: colors.purple,
      },
    },
  }
);

export default useStyles;
