export const UpdatePersonalState = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  validIdUrl: '',
};

export const UpdatePasswordState = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

export const VerifyPersonalState = {
  fullname: '',
  address1: '',
  address2: '',
  birthday: '',
  validId: '',
};

export const SampleDate = {
  date: '',
}

export const DocumentDetailsState = {
  title: '',
  description: '',
  date: '',
  hours: '',
  minutes: '',
  meridiem: 'AM',
  time: ''
}