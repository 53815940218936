import React from 'react';
import { View, Text, Pressable } from 'react-native';
import useStyles from './style.css';
import { Button01 } from '@src/components/button';
import colors from '@assets/colors';
import PDFViewer from './viewer';
import AddSignature from './add-signature-pop';
import { useNavigation } from '@react-navigation/native';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import Icon from '@expo/vector-icons/MaterialIcons';

const AddSignatureFields = () => {
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<'ReviewDetails'>>();

  return (
    <View style={styles.container}>
      <View style={styles.viewContainerSeeDetails}>
        <View style={styles.header}>
          <Pressable style={styles.arrow} onPress={() => navigation.goBack()}>
            <Icon name="keyboard-arrow-left" color={colors.white} size={24} />
          </Pressable>
          <Text style={styles.headerText}>Add Signature Fields</Text>
        </View>
        <View style={{ marginTop: 35 }}>
          <Text style={{ paddingLeft: 30 }}>Add fields for</Text>
          {/* <View style={{ flex: .9 }}> */}
          <PDFViewer />
          {/* </View> */}
          <AddSignature />
          <Button01
            onPress={() => navigation.navigate('ReviewDetails')}
            label="Done"
            style={styles.btnNext}
            labelStyle={styles.btnText}
          ></Button01>
          <View style={styles.v_height} />
        </View>
      </View>
    </View>
  );
};

export default AddSignatureFields;
