import React, { useRef, useState } from 'react';
import { TextInput, Text, TouchableOpacity, Keyboard } from 'react-native';
import { InputProps } from './types';
import useStyles from './styles.css';

const TextInput01 = (props: InputProps) => {
  const {
    error,
    value,
    inputStyle,
    placeholder,
    onChangeText,
    keyboardType,
    containerStyle,
    secureTextEntry,
  } = props;
  const styles = useStyles();
  const txtRef = useRef<any>();
  const [isFocus, setFocus] = useState(false);

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => txtRef.current.focus()}
      style={[styles.container, containerStyle, error && styles.errorStyle]}
    >
      <TextInput
        ref={txtRef}
        value={value}
        autoCorrect={false}
        autoCapitalize="none"
        keyboardType={keyboardType}
        onChangeText={onChangeText}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        style={[styles.input, inputStyle]}
        onSubmitEditing={Keyboard.dismiss}
        secureTextEntry={secureTextEntry}
        placeholder={isFocus ? '' : placeholder}
      />
      {!!error && <Text style={styles.textError}>{error}</Text>}
    </TouchableOpacity>
  );
};

function areEqual(prevProps: any, nextProps: any) {
  return (
    prevProps.value === nextProps.value &&
    prevProps.containerStyle === nextProps.containerStyle &&
    prevProps.error === nextProps.error
  );
}

export default React.memo(TextInput01, areEqual);
