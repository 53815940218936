/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { View, Text, ViewStyle, Pressable, StyleSheet } from 'react-native';
import MenuDropdown, { MeasureValue } from './Menu';
import Icon from '@src/components/icon';
import colors from '@assets/colors';

type DropdownProps = {
  name?: string;
  value?: string;
  color?: string;
  iconSize?: number;
  options?: any[];
  style?: ViewStyle;
  renderItem?: any;
  onSelected: (item: any) => void;
  renderBase?: any;
  disabled?: boolean;
  default?: boolean;
};

function Dropdown(props: DropdownProps) {
  const pressableRef = React.useRef<any>(null);
  const [isFocus, setFocus] = React.useState<boolean>(false);
  const [measure, setMesasure] = React.useState<MeasureValue>(
    {} as MeasureValue
  );

  const RenderBase = () => {
    if (typeof props.renderBase === 'function') {
      return props.renderBase({
        value: props.value,
        name: props.name,
        iconSize: props.iconSize,
      });
    }

    return (
      <>
        <View style={[styles.v_circle, { backgroundColor: props.color }]} />
        <Text selectable={false} style={styles.t_label}>
          {props.value}
        </Text>
        <Icon name={props.name} size={props.iconSize} color={props.color} />
      </>
    );
  };

  React.useEffect(() => {
    if (pressableRef.current) {
      pressableRef.current?.measure(
        (
          fx: any,
          fy: any,
          width: number,
          height: any,
          px: number,
          py: number
        ) => {
          setMesasure({
            x: px,
            y: py,
            width,
            height,
          });
        }
      );
    }
  }, [pressableRef.current, isFocus]);

  if (typeof props.renderBase === 'function') {
    return (
      <View ref={pressableRef} style={[props.style]}>
        {props.renderBase({
          value: props.value,
          name: props.name,
          iconSize: props.iconSize,
          onPress: () => setFocus(true),
        })}
        <MenuDropdown
          default={props.default}
          visible={isFocus}
          measures={measure}
          value={props.value}
          options={props.options}
          renderItem={props.renderItem}
          onSelected={props.onSelected}
          onClose={() => setFocus(false)}
        />
      </View>
    );
  }

  return (
    <Pressable
      ref={pressableRef}
      disabled={props?.disabled ?? false}
      onPress={() => {
        setFocus(true)
      }}
      style={[styles.container, props.style]}
    >
      {RenderBase()}
      <MenuDropdown
        default={props.default}
        visible={isFocus}
        measures={measure}
        value={props.value}
        options={props.options}
        renderItem={props.renderItem}
        onSelected={props.onSelected}
        onClose={() => setFocus(false)}
      />
    </Pressable>
  );
}

export default Dropdown;

const styles = StyleSheet.create({
  container: {
    zIndex: 999,
    height: 50,
    marginTop: 20,
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    borderColor: colors.black4,
    backgroundColor: colors.white,
  },
  v_circle: {
    width: 24,
    height: 24,
    borderRadius: 24 / 2,
    backgroundColor: colors.black,
  },
  t_label: {
    flex: 1,
    fontFamily: 'DMSans',
    fontSize: 15,
    lineHeight: 20,
    marginLeft: 20,
  },
});
