import React, { useState, useCallback, useRef } from 'react';
import { View, Text, Image } from 'react-native';
import useStyles from './styles.css';
import { images } from 'theme';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { DocumentContext } from '@src/screens/live-request/provider/document';
import { LayerContext } from '@src/screens/live-request-new/signature-fields/provider/layer';
import { FileUploader } from 'react-drag-drop-files';
import { AuthContext } from '@src/AuthProvider';
import { Button01 } from '@src/components/button';
import { useNavigation } from '@react-navigation/native';

const fileTypes = ['JPG', 'PDF', 'JPEG', 'DOCX'];

const UploadDocumentSuccess = () => {
  const styles = useStyles();
  const [hideShow, setHideShow] = React.useState<boolean>(true);
  const navigation = useNavigation<any>();
  const [source, setSource] = React.useState(null);
  const { documentFile, setDocumentFile } = React.useContext(LayerContext);
  const { onErrorMessage } = React.useContext(AuthContext);
  const onTypeError = () =>
    onErrorMessage('Invalid file type. Please use PDF format.');
  const onSizeError = () =>
    onErrorMessage('Exceeded file size limit of 200MB.');

  const cancelFileBtn = () => {
    setHideShow(false);
  };

  const handleChange = (files: any) => {
    if (files) {
      setDocumentFile({ files });
      setHideShow(true);
      let reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onloadend = (e: any) => {
        setSource(e.target.result);
        console.log(files);
      };
    }
  };

  const successNextBtn = () => {
    navigation.navigate('DocumentDetails');
  };

  return (
    <View style={styles.containerSuccess}>
      <View style={styles.v_body}>
        <Image
          source={images.upload_success}
          style={styles.upload_success}
          resizeMode="contain"
        />
      </View>
      <View style={styles.successTextContainer}>
        <Text style={styles.textSuccess}>Your file was successfully</Text>
        <Text style={styles.textSuccess}>uploaded!</Text>
      </View>
      <View style={styles.cardContainer}>
        {hideShow && (
          <Card sx={{ maxWidth: 545 }}>
            <CardHeader
              avatar={
                <Text>
                  <p style={styles.fileName}>{documentFile.files.name}</p>
                  <p style={styles.fileSize}>{documentFile.files.size} KB</p>
                </Text>
              }
              action={
                <Button01
                  onPress={cancelFileBtn}
                  label="X"
                  style={styles.closeBtn}
                ></Button01>
              }
            />
          </Card>
        )}
      </View>
      <View style={styles.fileIcon_container}>
        <Image
          source={images.file_icon}
          style={styles.file_icon}
          resizeMode="contain"
        />
        <FileUploader
          classes="drop"
          maxSize={200}
          types={fileTypes}
          onTypeError={onTypeError}
          onSizeError={onSizeError}
          handleChange={handleChange}
        >
          <Button01
            label="Pick another file"
            labelStyle={styles.fileIcon_label}
            style={styles.fileIcon_btn}
            disabled
          />
        </FileUploader>
      </View>

      <View style={styles.nextBtnContainer}>
        <Button01
          label="Next"
          labelStyle={styles.nextBtnText}
          onPress={successNextBtn}
        />
      </View>
    </View>
  );
};

export default UploadDocumentSuccess;
