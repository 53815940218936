import { View, Text, TouchableOpacity, Pressable } from 'react-native';
import React, {useContext} from 'react';
import Icon from '@src/components/icon';
import useStyles from './styles';
import colors from '@assets/colors';
import { Button01 } from '@src/components/button';
import { TextInput02, TextInput03 } from '@src/components/textinput';
import TypographyStyle from '@assets/typography';
import { useNavigation } from '@react-navigation/native';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { HeaderContext } from '@src/screens/live-request/provider/header';
import moment from 'moment';
import { useValidation } from '@src/utils/validation-helper';
import { useInputHelper } from '@src/utils/inputs-helper';
import { LayerContext } from '../signature-fields/provider/layer';
import DateTimePicker from '@src/components/date-picker';
import _ from 'lodash';
import { DocumentDetailsState } from '@src/utils/mockdata/state-holder';

type Props = {
  onProceed?: () => void;
  onPress?: () => void;
};

const DocumentDetails = (props: Props) => {
  const { onProceed } = props;
  const styles = useStyles();
  const navigation =
    useNavigation<NavigationScreenProps<'AddPrincipalMobile'>>();
  const { setDocument } = React.useContext(LayerContext);
  const today = moment();
  const { errorInput, checkValidation, onSetError } = useValidation();
  const [isAMActive, setIsAMActive] = React.useState(true);
  const [isPMActive, setIsPMActive] = React.useState(false);
  const { state, onDispatch } = useInputHelper(DocumentDetailsState);
  const notaryTime = `${state.hours}:${state.minutes} ${state.meridiem}`;

  const handleAMPress = () => {
    setIsAMActive(true);
    setIsPMActive(false);
    state.meridiem = 'AM';
  };

  const handlePMPress = () => {
    setIsAMActive(false);
    setIsPMActive(true);
    state.meridiem = 'PM';
  };

  const submitDetails = () => {
    const NewState: any = { ...DocumentDetailsState };
    const newError = checkValidation(NewState, state);
    
    if (!_.isEmpty(newError || undefined)) {
      return { ...state };
    }

    onSetError(newError);
    navigation.navigate('AddPrincipalMobile');
  };

  const onChangeDate = (value: any) => {
    const formattedValue = moment(value).format('MM/DD/YYYY');
    state.date = formattedValue;

    onDispatch('date')(value.format('MM/DD/YYYY'))
  };

  React.useEffect(() => {
    onSetError({});
  }, [state]);

  React.useEffect(() => {
    setDocument(state);
  }, [state]);

  React.useEffect(() => {
    onDispatch('time')(notaryTime)
  }, [state.hours,state.minutes,state.meridiem]);

  // return (
  //   <View style={[styles.inputV, { zIndex: 10 }]}>
  //     <View style={[styles.w30]}>
  //       {dateopen && (
  //         <Datetime
  //           input
  //           inputProps={errorInput}
  //           timeFormat={false}
  //           onChange={onChangeDate}
  //           onOpen={() => setDateOpen(true)}
  //           onClose={() => setDateOpen(false)}
  //           renderInput={RenderInput}
  //         />
  //       )}

  //       {!dateopen && (
  //        <TextInput03
  //        value={''}
  //        onPress={() => {
  //         setDateOpen(true)
  //        }}
  //        placeholder="MM/DD/YYYY"
  //        label="Date to Notarize"
  //        onChange={(e) => onChangeDate('')}
  //        rightIcon={'calendar'}
  //       />
  //       )}
  //     </View>
  //   </View>
  // );

  return (
    <View style={styles.container}>
      <View style={styles.viewContainerSeeDetails}>
        <View style={styles.header}>
          <Pressable style={styles.arrow} onPress={() => navigation.goBack()}>
            <Icon name="keyboard-arrow-left" color={colors.white} size={24} />
          </Pressable>
          <Text style={styles.headerText}>Document Details</Text>
        </View>
        <View style={styles.inputV}>
          <TextInput02
            style={styles.w70}
            label="Document Title"
            placeholder="Enter document title"
            inputStyle={{ fontSize: 14, minHeight: 55 }}
            labelStyle={{ fontSize: 14 }}
            error={errorInput.title}
            // onChange={(e) => onDispatch('title')}
            // onChangeText={(value: string) => {
            //   onDispatch('title')(value)
            //   setDocument((updatedDocument: any) => ({
            //     ...updatedDocument,
            //     title: value,
            //   }));
            // }}
            onChangeText={onDispatch('title')}
          />
        </View>
        <View style={styles.inputV}>
          <TextInput02
            style={styles.w71}
            inputStyle={{ fontSize: 14, minHeight: 55 }}
            labelStyle={{ fontSize: 14 }}
            error={errorInput.description}
            // onChange={onChangeDesc}
            // value={state.description}
            label="Document Description"
            placeholder="Enter document description"
            // onChangeText={(value: string) => {
            //   onDispatch('description')(value)
            //   setDocument((updatedDocument: any) => ({
            //     ...updatedDocument,
            //     description: value,
                
            //   }));
            // }}
            onChangeText={onDispatch('description')}
          />
        </View>
        <View style={styles.w30}>
          <DateTimePicker
            disablePast
            label="Date to Notarize"
            format="MM/DD/YYYY"
            labelStyle={{
              ...TypographyStyle.Body1Regular,
              marginTop: 20,
              fontSize: 14,
              marginBottom: 10,
            }}
            onChange={onChangeDate}
            // onChangeText={onDispatch('date')}
            error={errorInput.date}
            style={{ alignItems: 'none', width: '100%' }}
          />
        </View>
        <View style={styles.timeContainer}>
          <Text style={{ ...TypographyStyle.Body1Regular, fontSize: 14 }}>
            Time to Notarize
          </Text>
          <View style={styles.notarizeTime}>
            <TextInput02
              value={state.hours}
              error={errorInput.hours}
              onChangeText={onDispatch('hours')}
              style={styles.hours}
              borderStyle={{ borderColor: colors.purple }}
              inputStyle={{
                paddingHorizontal: 5,
                textAlign: 'center',
                fontSize: 18,
              }}
              inputRange={[0, 12]}
            />
            <Text style={styles.colon}>:</Text>
            <TextInput02
              value={state.minutes}
              onChangeText={onDispatch('minutes')}
              style={styles.minutes}
              error={errorInput.minutes}
              inputStyle={{
                paddingHorizontal: 5,
                textAlign: 'center',
                fontSize: 18,
              }}
              borderStyle={{
                borderColor: colors.transparent,
                backgroundColor: colors.gray3,
              }}
              inputRange={[0, 59]}
            />
            <View style={styles.switchContainer}>
              <TouchableOpacity
                style={[
                  styles.time,
                  {
                    backgroundColor: isAMActive ? colors.purple : 'transparent',
                  },
                ]}
                onPress={handleAMPress}
              >
                <Text
                  style={{
                    ...TypographyStyle.Body2Regular,
                    color: isAMActive ? colors.white : colors.black,
                  }}
                >
                  AM
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.time,
                  {
                    backgroundColor: isPMActive ? colors.purple : 'transparent',
                  },
                ]}
                onPress={handlePMPress}
              >
                <Text
                  style={{
                    ...TypographyStyle.Body2Regular,
                    color: isAMActive ? colors.black : colors.white,
                  }}
                >
                  PM
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={styles.footerContainer}>
          <Text style={styles.footerText}>
            <span style={{ fontWeight: '700' }}>Note:</span> Immediate service
            is provided by available notaries only. If you have a preferred
            notary, please allow at least 3 days for confirmation. The date and
            time are subject to the notary's approval
          </Text>

          <View style={styles.btnContainer}>
            <Button01
              style={styles.btnNext}
              labelStyle={styles.btnText}
              label="Next"
              onPress={submitDetails}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default DocumentDetails;
