import React, { createContext, useRef, useCallback, useState, useContext, useEffect } from 'react';
import {
  selectUserEmail,
  selectUserFullname,
} from '@src/ducks/slices/user.slice';
import { useAppSelector } from '@src/ducks/ducksHook';
import * as Types from './types';
import { LayerContext } from '../../../live-request-new/signature-fields/provider/layer';
import moment from 'moment';
import images from '@assets/images'

interface ContextValue {
  isJoined: boolean;
  mySignature: any;
  jitsiRef: any;
  messages: any[];
  mic: boolean;
  video: boolean;
  tileview: boolean;
  showsignsecure: boolean;
  participants: object[];
  onChangeSignSecure: () => void;
  onChangeMicStatus: (value: boolean) => void;
  onChangeVideoStatus: (value: boolean) => void;
  onChangeTileViewStatus: (value: boolean) => void;
  updateParticipants: (value: any) => void;
  handleMessageUpdates: (value: any) => void;
  sendMessage: (message: string, participantId: string) => void;
  initializeSignatureFields: () => void;
  onAffixSignature: () => void;
  onSaveSignature: (signatureObject: any) => void;
  onEndVideoSession: () => void;
}

export const FONT_SIZE = 15;
export const IMAGE_SIZE = 20;
export const LAYER_WIDTH = 130;
export const LAYER_HEIGHT = 50;

const VideoContext = createContext<ContextValue>({} as ContextValue);

const VideoProvider = ({ children }: Types.DocumentProviderProps) => {
  const jitsiRef = useRef<any>();
  const [mic, setMic] = useState(false);
  const [video, setVideo] = useState(false);
  const email = useAppSelector(selectUserEmail);
  const [tileview, setTileView] = useState(false);
  const fullname = useAppSelector(selectUserFullname);
  const [showsignsecure, setSignsecure] = useState(false);
  const [participants, setParticipants] = useState<any>([
    { email, displayName: fullname },
  ]);
  const { layers, updateLayers } = useContext(LayerContext);
  const [messages, setMessages] = useState<any>([]);
  const [mySignature, setMySignature] = useState<any>()
  const [isJoined, setIsJoined] = useState(false)
  // const [myAffixSignature, setMyAffixSignature] = useState<any>()
  let realTimeLayers = layers
  let isInitialized: boolean = false
  let userInfo: any = { }

  const onChangeSignSecure = useCallback(() => {
    setSignsecure(!showsignsecure);
  }, [showsignsecure]);

  const onChangeMicStatus = useCallback(
    (value: boolean) => {
      setMic(value);
    },
    [mic]
  );

  const onChangeVideoStatus = useCallback(
    (value: boolean) => {
      setVideo(value);
    },
    [video]
  );

  const onChangeTileViewStatus = useCallback(
    (value: boolean) => {
      setTileView(value);
    },
    [tileview]
  );

  const updateParticipants = useCallback(() => {
    const result = jitsiRef.current?.getParticipantsInfo();
    setParticipants(result);
    console.log('updateParticipants', result);
  }, [participants]);

  const handleMessageUpdates = useCallback((payload: any) => {
    const messageObject = JSON.parse(payload.message)
    if (userInfo) {
      if (messageObject.type === 'message') {
        const yourMessage = messageObject.id === userInfo.participantId
        setMessages((messages: any[]) => [...messages, { ...messageObject, yourMessage }])
      }
      else if (messageObject.type === "initializeFields") {
        isInitialized = true
        const myUserId = jitsiRef.current._myUserID
        userInfo = { participantId: myUserId }
        realTimeLayers = messageObject.realTimeLayers
        updateLayers(messageObject.realTimeLayers)
        setIsJoined(true)
      }
      else if (messageObject.type === "affix") {
        const signature = messageObject.signature
        const layerField = messageObject.layerField
        const hasLayerFields = (layers && (0 < layers[0]?.layerFields.length))
        let affixLayers: any = hasLayerFields ? [...layers] : [...realTimeLayers]
        let layer = affixLayers[layerField.layerId]
        layer.layerFields[layerField.fieldId] = {
          ...layer.layerFields[layerField.fieldId],
          ...signature,
          signatureDate: +moment(),
        }
        affixLayers[layerField.layerId] = layer
        updateLayers(affixLayers)
        if (userInfo) {
          const isMyAffixSignature = messageObject.id === userInfo.participantId
          if (isMyAffixSignature) {
            setMySignature((signatureObject: any) => ({ ...signatureObject, affix: true }))
          }
        }
      }
    }
  }, [jitsiRef, layers])

  const sendMessage = useCallback((message: string, participantId: string) => {
    if (jitsiRef && jitsiRef.current) {
      const myUserId = jitsiRef.current._myUserID
      const result = jitsiRef.current?.getParticipantsInfo();
      const participant = [...result].find((item: any) => item.participantId === myUserId)
      if (participant) {
        const messageObject = JSON.stringify({
          type: 'message',
          id: participant.participantId,
          email: participant.email ?? "",
          name: participant.displayName,
          text: message,
        })
        jitsiRef.current.executeCommand('sendChatMessage', messageObject, participantId);
      }
    }
  }, [jitsiRef])

  const initializeSignatureFields = useCallback(() => {
    if (!isInitialized) {
      if (jitsiRef && jitsiRef.current) {
        const hasLayerFields = (layers && (0 < layers[0]?.layerFields.length))
        const participantId = jitsiRef.current._myUserID
        if (hasLayerFields) {
          isInitialized = true
          const messageObject = JSON.stringify({
            type: 'initializeFields',
            id: participantId,
            realTimeLayers: layers,
          })
          jitsiRef.current.executeCommand('sendChatMessage', messageObject);
        }
      }
    }
  }, [jitsiRef, layers])

  const onAffixSignature = useCallback(() => {
    if (!mySignature?.affix) {
      if (jitsiRef && jitsiRef.current) {
        const myUserId = jitsiRef.current._myUserID
        const signature = { ...mySignature.signature }
        const layerField = mySignature.layerField
        const messageObject = JSON.stringify({
          type: 'affix',
          id: myUserId,
          layerField,
          signature,
        })
        jitsiRef.current.executeCommand('sendChatMessage', messageObject);
      }
    }
  }, [mySignature, jitsiRef])

  const onSaveSignature = useCallback((signatureObject: any) => {
    setMySignature(signatureObject)
  }, [])

  const onEndVideoSession = useCallback(() => {
    setIsJoined(false)
  }, [])

  const value = {
    isJoined,
    mySignature,
    jitsiRef,
    messages,
    mic,
    video,
    tileview,
    participants,
    showsignsecure,
    onChangeMicStatus,
    onChangeVideoStatus,
    onChangeTileViewStatus,
    updateParticipants,
    onChangeSignSecure,
    handleMessageUpdates,
    sendMessage,
    initializeSignatureFields,
    onSaveSignature,
    onAffixSignature,
    onEndVideoSession,
  };

  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};

export { VideoProvider, VideoContext };
