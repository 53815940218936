import React from 'react';
import { useWindowDimensions } from 'react-native';
import { useDeviceSize, DEVICE_SIZES } from 'rn-responsive-styles';

export const mobileSizeChecker = () => {
  const device_size = useDeviceSize();

  const isMobile =
    device_size === DEVICE_SIZES.XS || device_size === DEVICE_SIZES.SM;

  return isMobile;
};

export const usePDFScale: any = (maxScale: number = 1.5) => {
  const device_size = useDeviceSize();
  const { width } = useWindowDimensions();

  const scale = React.useMemo(() => {
    switch (device_size) {
      case DEVICE_SIZES.MD:
      case DEVICE_SIZES.SM:
        return 1;
      case DEVICE_SIZES.XS:
        return (width - 40) / 612;
      case DEVICE_SIZES.LG:
      default:
        return maxScale || 1.5;
    }
  }, [device_size, width, maxScale]);

  return scale;
};
