import React, { useState, useReducer, useContext, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { TextInput02 } from '@src/components/textinput';
import { PrincipalValue } from './types';
import Icon from '@expo/vector-icons/MaterialIcons';
import colors from '@assets/colors';
import validator from 'validator';
import useStyles from './styles';
import { LayerContext } from '../../signature-fields/provider/layer';
import { useValidation } from '@src/utils/validation-helper';
import _ from 'lodash';

function reducer(state: any, action: any) {
  return { ...state, [action.type]: action.data };
}

type PrincipalItemProps = {
  item: PrincipalValue;
  index: number;
  setName: (value: string) => void;
  setEmail: (value: string) => void;
  onRemove: () => void;
};

const PrincipalItemMobile = React.forwardRef(
  ({ index, setEmail, setName, item, onRemove }: PrincipalItemProps) => {
    const styles = useStyles();
    const [error, setError] = useState<any>({});
    const [input, dispatch] = useReducer(reducer, item);
    const { setDocument, document } = useContext(LayerContext);
    const { errorInput, checkValidation, onSetError } = useValidation();

    const principalName = useCallback((value: string) => {
      setDocument((updatedDocument: any) => ({
        ...updatedDocument,
        principalName: value,
      }));
      setName(value);
    }, []);

    const principalEmail = useCallback((value: string) => {
      setDocument((updatedDocument: any) => ({
        ...updatedDocument,
        principalEmail: value,
      }));
      setEmail(value);
    }, []);

    const onContinue = () => {
      const newErr: any = {};

      if (!input.name) {
        newErr.name = 'This field is required';
      }
      if (!input.email) {
        newErr.email = 'This field is required';
      } else if (!validator.isEmail(input.email)) {
        newErr.email = 'Email format is invalid.';
      }

      setError(newErr);

      if (_.isEmpty(newErr)) {
        return { ...input };
      }

      return null;
    };

    return (
      <View style={styles.witnessContainer}>
        <View style={styles.witnessHeader}>
          <Text style={styles.witnessLabel}>Principal {index + 1}</Text>
          {index > 0 && (
            <TouchableOpacity onPress={onRemove}>
              <Icon name="delete" color={colors.purple} size={20} style={{paddingLeft: 2, marginTop: -1}} />
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.witnessInputContainer}>
          <TextInput02
            label="Full Name"
            error={errorInput.setName}
            style={styles.input}
            placeholder="Enter full name"
            // onChangeText={setName}
            onChangeText={principalName}
          />
          <View style={styles.v_divider} />
          <TextInput02
            label="Email"
            error={errorInput.setEmail}
            style={styles.input}
            placeholder="Enter email"
            // onChangeText={setEmail}
            onChangeText={principalEmail}
          />
        </View>
      </View>
    );
  }
);

PrincipalItemMobile.displayName = 'PrincipalItemMobile';

export default React.memo(PrincipalItemMobile);
