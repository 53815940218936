import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { StyleSheet } from 'react-native';
import colors from '@assets/colors';

export const HEADER_HEIGHT = 60;

const useStyles = CreateResponsiveStyle(
  {
    main: {
      zIndex: 2,
      width: '76%',
      height: '100%',
      marginLeft: '24%',
      position: 'absolute',
      backgroundColor: 'transparent',
    },
    container: {
      zIndex: 4,
      marginTop: 30,
      height: HEADER_HEIGHT,
      alignSelf: 'center',
    },
    v_page_wrapper: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    t_pages: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      { color: colors.black, marginHorizontal: 30, fontSize: 12 },
    ]),
    pdf_btn_export: {
      width: 40,
      height: 40,
      borderRadius: 40 / 2,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.transparent,
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      container: {
        display: 'none',
      },
    },
    [DEVICE_SIZES.XS]: {
      container: {
        display: 'none',
      },
    },
  }
);

export default useStyles;
