import React from 'react';
import { Rect, Text, Group, Path } from 'react-konva';
import RectImage from '../RectImage';

type SignatureNotaryProps = {
  field: any;
  onSelect: () => void;
};

const baseWidth = 14;

const SignatureNotary = (props: SignatureNotaryProps) => {
  const { field } = props;

  const imagePosition = React.useMemo(() => {
    const posX = field.width * 0.42873;
    const posY = field.height * 0.20105;
    const heightPercentage = field.height * 0.37494;
    const widthScale = heightPercentage / baseWidth;
    const heightScale = heightPercentage / baseWidth;

    return {
      posX,
      posY,
      scale: Math.min(widthScale, heightScale),
    };
  }, [field.width, field.height]);

  return (
    <React.Fragment>
      <Group
        x={field.x}
        y={field.y}
        width={field.width}
        height={field.height}
        onTap={props.onSelect}
        rotation={field.rotation}
        onClick={props.onSelect}
      >
        {field.signature ? (
          <>
            <RectImage
              width={field.width}
              height={field.height}
              src={field.signature}
            />
          </>
        ) : (
          <>
            <Rect
              dash={[8, 8]}
              dashOffset={3}
              strokeWidth={2}
              cornerRadius={6.25}
              fill={`${field.fill}30`}
              width={field.width}
              height={field.height}
              stroke={field.fill}
            />
            <Path
              data="M7 10.889A3.892 3.892 0 0 0 10.888 7 3.892 3.892 0 0 0 7 3.114 3.892 3.892 0 0 0 3.113 7 3.892 3.892 0 0 0 7 10.89ZM7 3.25A3.754 3.754 0 0 1 10.75 7 3.754 3.754 0 0 1 7 10.752a3.754 3.754 0 0 1-3.75-3.75A3.754 3.754 0 0 1 7 3.25Z"
              x={imagePosition.posX}
              y={imagePosition.posY}
              scaleX={imagePosition.scale}
              scaleY={imagePosition.scale}
              stroke={field.fill}
              strokeMiterlimit={10}
              strokeWidth={0.737}
            />

            <path
              strokeWidth={1.032}
              d="M8.775.372a1.628 1.628 0 0 0-1.1.091 1.64 1.64 0 0 1-1.353 0 1.628 1.628 0 0 0-2.005.538 1.63 1.63 0 0 1-1.171.677 1.63 1.63 0 0 0-1.468 1.468A1.632 1.632 0 0 1 1 4.317a1.628 1.628 0 0 0-.537 2.005 1.64 1.64 0 0 1 0 1.354A1.628 1.628 0 0 0 1 9.68a1.63 1.63 0 0 1 .677 1.171 1.629 1.629 0 0 0 1.468 1.468 1.63 1.63 0 0 1 1.171.677 1.629 1.629 0 0 0 2.005.537 1.64 1.64 0 0 1 1.354 0 1.629 1.629 0 0 0 2.005-.537 1.63 1.63 0 0 1 1.171-.677 1.629 1.629 0 0 0 1.468-1.468 1.63 1.63 0 0 1 .677-1.171 1.628 1.628 0 0 0 .537-2.005 1.64 1.64 0 0 1 0-1.354 1.628 1.628 0 0 0-.537-2.005 1.63 1.63 0 0 1-.677-1.171 1.63 1.63 0 0 0-1.468-1.468A1.632 1.632 0 0 1 9.681 1a1.626 1.626 0 0 0-.906-.629Z"
            />
            <path
              strokeWidth={0.737}
              d="M7 10.889A3.892 3.892 0 0 0 10.888 7 3.892 3.892 0 0 0 7 3.114 3.892 3.892 0 0 0 3.113 7 3.892 3.892 0 0 0 7 10.89ZM7 3.25A3.754 3.754 0 0 1 10.75 7 3.754 3.754 0 0 1 7 10.752a3.754 3.754 0 0 1-3.75-3.75A3.754 3.754 0 0 1 7 3.25Z"
            />

            <Path
              data="M8.775.372a1.628 1.628 0 0 0-1.1.091 1.64 1.64 0 0 1-1.353 0 1.628 1.628 0 0 0-2.005.538 1.63 1.63 0 0 1-1.171.677 1.63 1.63 0 0 0-1.468 1.468A1.632 1.632 0 0 1 1 4.317a1.628 1.628 0 0 0-.537 2.005 1.64 1.64 0 0 1 0 1.354A1.628 1.628 0 0 0 1 9.68a1.63 1.63 0 0 1 .677 1.171 1.629 1.629 0 0 0 1.468 1.468 1.63 1.63 0 0 1 1.171.677 1.629 1.629 0 0 0 2.005.537 1.64 1.64 0 0 1 1.354 0 1.629 1.629 0 0 0 2.005-.537 1.63 1.63 0 0 1 1.171-.677 1.629 1.629 0 0 0 1.468-1.468 1.63 1.63 0 0 1 .677-1.171 1.628 1.628 0 0 0 .537-2.005 1.64 1.64 0 0 1 0-1.354 1.628 1.628 0 0 0-.537-2.005 1.63 1.63 0 0 1-.677-1.171 1.63 1.63 0 0 0-1.468-1.468A1.632 1.632 0 0 1 9.681 1a1.626 1.626 0 0 0-.906-.629Z"
              x={imagePosition.posX}
              y={imagePosition.posY}
              scaleX={imagePosition.scale}
              scaleY={imagePosition.scale}
              stroke={field.fill}
              strokeWidth={0.737}
            />
            <Text
              text="Notarize"
              padding={field.height * 0.05}
              align="center"
              width={field.width}
              height={field.height}
              fontFamily="DMSans"
              verticalAlign="bottom"
              fill={field.fill}
              fontSize={field.height / 3.5}
            />
          </>
        )}
      </Group>
    </React.Fragment>
  );
};

export default React.memo(SignatureNotary);
