import React from 'react';
import { View, Text, Pressable } from 'react-native';
import Icon from '@src/components/icon';
import useStyles from './styles.css';

interface requestCardProps {
  icon: any;
  title: string;
  description: string;
  onPress: () => void;
}

const CreateRequestCard = (props: requestCardProps) => {
  const { title, description, icon, onPress } = props;
  const styles = useStyles();
  return (
    <View style={styles.cr_card_container}>
      <View style={styles.cr_card_text_v}>
        <Icon name={icon} style={styles.cr_card_icon} />
        <View style={styles.cr_flex1}>
          <Text style={styles.cr_card_title}>{title}</Text>
          <Text style={styles.cr_card_desc}>{description}</Text>
        </View>
      </View>
      <Pressable onPress={onPress} style={styles.cr_card_btn}>
        <Text style={styles.cr_card_btn_label}>Request now</Text>
        <Icon name="next" style={styles.cr_i_next} />
      </Pressable>
    </View>
  );
};

export default CreateRequestCard;
