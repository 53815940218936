import React, { useRef, memo, useState } from 'react';
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Keyboard,
  TouchableOpacity,
  Platform,
} from 'react-native';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { colors } from 'theme';
import _ from 'lodash';
import TypographyStyle from '@assets/typography';
import { InputProps2 } from './types';

function TextInput02(props: InputProps2) {
  const textRef = useRef<any>();
  const [isFocus, setFocus] = useState(false);
  const { label, onChangeText, error, hasPassword, onBlur, onSearchIcon, editable = true } = props;
  const [showPass, setShowPass] = useState(!!hasPassword);
  const errLabelStyle = error ? { color: colors.red } : {};
  const errBorderStyle: any = error && {
    borderColor: colors.red,
  };

  const handleOnBlur = () => {
    setFocus(false);
    if (onBlur) {
      onBlur();
    }
  };

  const handleOnChangeText = (text: string) => {
    if (props.inputRange) {
      const numericValue = text.replace(/[^0-9]/g, '');

      const numberValue =
        numericValue !== '' ? parseInt(numericValue, 10) : null;

      const limitedValue =
        numberValue !== null
          ? Math.min(
              Math.max(numberValue, props.inputRange[0]),
              props.inputRange[1]
            )
          : null;

      const formattedValue =
        limitedValue !== null ? limitedValue.toString().padStart(2, '0') : '';

      if (onChangeText) {
        onChangeText(formattedValue);
      }
    } else {
      if (onChangeText) {
        onChangeText(text);
      }
    }
  };

  return (
    <View style={[styles.marginTop20, props.style]}>
      {!!label && (
        <View style={styles.v_label}>
          <Text style={[styles.label, props.labelStyle, errLabelStyle]}>
            {props.label}
          </Text>

          {_.isEmpty(error) ? null : (
            <Text style={[styles.txtError]}>{error}</Text>
          )}
        </View>
      )}
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => textRef.current.focus()}
        style={[styles.border, props.borderStyle, errBorderStyle]}
      >
        <TextInput
          ref={textRef}
          style={[styles.input, props.inputStyle]}
          returnKeyType="done"
          autoComplete="off"
          autoCorrect={false}
          value={props.value}
          numberOfLines={1}
          multiline={props.multiline}
          secureTextEntry={showPass}
          maxLength={props.maxLength}
          onBlur={handleOnBlur}
          onFocus={() => setFocus(true)}
          keyboardType={props.keyboardType}
          placeholder={isFocus ? '' : props.placeholder}
          placeholderTextColor={colors.grayPrimary}
          onSubmitEditing={Keyboard.dismiss}
          // onChangeText={onChangeText}
          onChangeText={handleOnChangeText}
          onKeyPress={props?.onKeyPress}
          editable={editable}
        />
        {hasPassword && (
          <TouchableOpacity
            onPress={() => setShowPass(!showPass)}
            style={styles.btn_icon}
          >
            <Icon
              name={showPass ? 'eye-off' : 'eye'}
              color={colors.grayPrimary}
              size={24}
            />
          </TouchableOpacity>
        )}
        {onSearchIcon && (
          <TouchableOpacity style={styles.search_icon}>
            <Icon name={'magnify'} color={colors.grayPrimary} size={24} />
          </TouchableOpacity>
        )}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  label: StyleSheet.flatten([TypographyStyle.fieldsTitle]),
  v_label: { flexDirection: 'row', justifyContent: 'space-between' },
  marginTop20: { marginTop: 20, flex: 1 },
  border: {
    marginTop: 8,
    paddingVertical: Platform.OS === 'web' ? 0 : 14, //FIX FOR INPUT DOING MULTILINE
    minHeight: 42,
    borderWidth: 1,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.border6,
  },
  input: StyleSheet.flatten([
    TypographyStyle.fieldsTitle,
    {
      flex: 1,
      width: '100%',
      height: '100%',
      borderRadius: 8,
      color: colors.black3,
      paddingHorizontal: 16,
    },
  ]),
  input2: {
    flex: 1,
    fontFamily: 'Roboto',
    fontSize: 14,
    color: colors.black,
    paddingVertical: 0,
    marginLeft: 3,
  },
  txtError: StyleSheet.flatten([
    TypographyStyle.fieldsTitle,
    {
      fontSize: 13,
      color: colors.red,
      fontStyle: 'italic',
      fontFamily: 'DMSans-Bold',
    },
  ]),
  btn_icon: { position: 'absolute', width: 24, height: 24, right: 10 },
  search_icon: { position: 'absolute', width: 24, height: 24, left: 10 },
});

export default memo(TextInput02);
