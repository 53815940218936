import React, { useState, useCallback, useRef } from 'react';
import { View, Text } from 'react-native';
import { DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
import useStyles from './style.css';

const PaymentConfirmationDetails = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();

  const uploadDocument = () => {
    console.log('upload docs');
  };


  return (
    <View>
      <View style={styles.textContainer}>
        <Text style={styles.txtNote1}>Your transaction has been</Text>
        <Text style={styles.txtNote1}>successfully submitted and is</Text>
        <Text style={styles.txtNote1}>now awaiting approval.</Text>
        <View style={styles.detailsContainer3}>
          <Text style={styles.txtNote5}>
            We have forwarded your request to your
          </Text>
          <Text style={styles.txtNote5}>
            chosen notary public and witnesses.
          </Text>
          <Text style={styles.txtNote5}>
            An email update will be sent to you once
          </Text>
          <Text style={styles.txtNote5}>they accept the request.</Text>
        </View>
        <View style={styles.paymentContainerDetails}>
        </View>
      </View>
    </View>
  );
};

export default PaymentConfirmationDetails;
