import React, { memo } from 'react';
import SignatureFullname from './signature-fullname';
import SignatureDate from './signature-date';
import Signature from './signature';
import QRCodeSign from './qrcode';
import Initials from './initials';
import TextBox from './textbox';
import Text from './text';

type ItemsProps = {
  field: any;
  isSelected: boolean;
  onSelect: () => void;
  onRemove: () => void;
  onChange: (value: any) => void;
  onDeselect: () => void;
};

const Items = (props: ItemsProps) => {
  if (props.field.type === 'signature') {
    return (
      <Signature
        field={props.field}
        isSelected={props.isSelected}
        onSelect={props.onSelect}
        onRemove={props.onRemove}
        onChange={props.onChange}
        onDeselect={props.onDeselect}
      />
    );
  } else if (props.field.type === 'initials') {
    return (
      <Initials
        field={props.field}
        isSelected={props.isSelected}
        onSelect={props.onSelect}
        onRemove={props.onRemove}
        onChange={props.onChange}
        onDeselect={props.onDeselect}
      />
    );
  } else if (props.field.type === 'signature_fullname') {
    return (
      <SignatureFullname
        field={props.field}
        isSelected={props.isSelected}
        onSelect={props.onSelect}
        onRemove={props.onRemove}
        onChange={props.onChange}
        onDeselect={props.onDeselect}
      />
    );
  } else if (props.field.type === 'signature_date') {
    return (
      <SignatureDate
        field={props.field}
        isSelected={props.isSelected}
        onSelect={props.onSelect}
        onRemove={props.onRemove}
        onChange={props.onChange}
        onDeselect={props.onDeselect}
      />
    );
  } else if (props.field.type === 'qrcode_sign') {
    return (
      <QRCodeSign
        field={props.field}
        isSelected={props.isSelected}
        onSelect={props.onSelect}
        onRemove={props.onRemove}
        onChange={props.onChange}
        onDeselect={props.onDeselect}
      />
    );
  } else if (props.field.type === 'textbox') {
    return (
      <TextBox
        field={props.field}
        isSelected={props.isSelected}
        onSelect={props.onSelect}
        onRemove={props.onRemove}
        onChange={props.onChange}
        onDeselect={props.onDeselect}
      />
    );
  }

  return (
    <Text
      field={props.field}
      isSelected={props.isSelected}
      onSelect={props.onSelect}
      onRemove={props.onRemove}
      onChange={props.onChange}
      onDeselect={props.onDeselect}
    />
  );
};

export default memo(Items);
