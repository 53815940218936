import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { StyleSheet } from 'react-native';
import colors from '@assets/colors';

export const HEADER_HEIGHT = 90;

const useStyles = CreateResponsiveStyle(
  {
    pdf_header_container: {
      height: HEADER_HEIGHT,
      width: '100%',
      paddingHorizontal: 30,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: colors.purple,
      shadowOpacity: 1,
      shadowColor: 'rgba(82, 82, 82, 0.1)',
      shadowOffset: { height: 6, width: 0 },
      paddingVertical: 5,
      shadowRadius: 12,
      elevation: 10,
    },
    pdf_docs_pages_v: {
      flex: 1,
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    pdf_docs_pages_wrapper: {
      width: '80%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_zoom_wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    divider1: { width: 15 },
    pdf_zoom_text_v: {
      marginLeft: 20,
      flexWrap: 'wrap',
      flexDirection: 'row',
      backgroundColor: colors.white,
      paddingVertical: 14,
      paddingHorizontal: 12,
      alignItems: 'center',
      borderRadius: 8,
    },
    pdf_zoom_text: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      { color: colors.black3, marginRight: 16 },
    ]),
    divider2: {
      width: 1,
      height: 50,
      backgroundColor: colors.white,
      marginHorizontal: 25,
    },
    v_page_wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    t_pages: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      { color: colors.white, marginHorizontal: 15 },
    ]),
    pdf_btn_export: {
      padding: 10,
      borderWidth: 1,
      borderColor: colors.white,
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
    },
    pdf_buttons_v: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    pdf_btn_img: { width: 16, height: 16 },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
