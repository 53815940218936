import React from 'react';
import { View, Text, Pressable } from 'react-native';
import Icon from '@src/components/icon';
import useStyles from './style.css';
import { useNavigation } from '@react-navigation/native';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import colors from '@assets/colors';

const PaymentsHeader = () => {
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();

  return (
    <View style={styles.header}>
      <Pressable style={styles.arrow} >
        <Icon name="keyboard-arrow-left" color={colors.white} size={24} />
      </Pressable>
      <Text style={styles.headerText}>Payment</Text>
    </View>
  );
};

export default PaymentsHeader;
