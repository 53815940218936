import React from 'react';
import TextInput02 from '@src/components/textinput/TextInput02';
import Button from '@src/components/button/Button01';
import ProfileButton from './profile-button';
import ButtonRequest from './button-request';
import useStyles from './styles.css';
import { View } from 'react-native';

const WebHeader = () => {
  const styles = useStyles();

  return (
    <View style={styles.v_header_items}>
      <View style={styles.header_search}>
        <TextInput02
          borderStyle={styles.ti_borderStyle}
          style={styles.input_search}
          placeholder={'Input Your Text in Here'}
        />
        <Button
          style={styles.btn_search}
          labelStyle={styles.btn_label}
          label="Search"
        />
      </View>
      <View style={styles.v_create_request}>
        <ButtonRequest />
        <ProfileButton />
      </View>
    </View>
  );
};

export default WebHeader;
