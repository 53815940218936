import colors from '@assets/colors';
import TypographyStyle from '@assets/typography';
import { windowHeight, windowWidth } from '@src/utils/dimension-helper';
import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      position: 'absolute',
      width: '100%',
      height: '150%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blurBG,
    },
    paymentContainerDetails: { backgroundColor: colors.white },
    header: {
      backgroundColor: colors.purple,
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    headerText: {},
    textContainer: {},
    textContainer2: {},
    v_body: {
      //   flex: 1,
      //   alignItems: 'center',
      //   justifyContent: 'center',
    },
    v_logo_container: {
      width: '100%',
    },
    imgContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      height: 250,
      paddingTop: 150,
    },
    verified: { width: 80, height: 80 },
    txtNote1: StyleSheet.flatten([TypographyStyle.heading2Bold]),
    txtNote2: StyleSheet.flatten([TypographyStyle.heading4Regular]),
    txtNote3: StyleSheet.flatten([TypographyStyle.Body1Regular]),
    txtNote4: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      { fontWeight: '700' },
    ]),
    txtNote5: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      { fontWeight: '500' },
    ]),
    divider: {
      width: 327,
      backgroundColor: colors.grayPrimary,
      height: 3,
      rotate: '180deg',
      alignItems: 'center',
      marginTop: 50,
      paddingHorizontal: 30,
    },
    detailsContainer2: {
      flexDirection: 'column',
      marginTop: 0,
    },
    detailsContainer3: {
      flexDirection: 'column',
      marginTop: 30,
      // marginBottom: '50%'
    },
    inputVdetailsRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: -20,
      marginTop: 20,
    },
    labelDetails3: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.black3,
        paddingLeft: 30,
        fontWeight: '500',
        fontSize: 14,
      },
    ]),
    labelDetails3Padding: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.black3,
        paddingRight: 30,
        fontWeight: '700',
      },
    ]),
    detailsContainer1: {
      flexDirection: 'column',
    },
    payText1: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.grayPrimary,
        paddingLeft: 30,
      },
    ]),
    labelDetails1: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      {
        color: colors.black3,
        paddingLeft: 30,
        fontWeight: '700',
        marginTop: 15,
      },
    ]),
    labelPadding1: {
      paddingRight: 30,
      fontSize: 16,
      fontWeight: '700',
      marginTop: 15,
    },
    payText2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.black3,
        paddingLeft: 30,
      },
    ]),
    payTextPadding: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.black3,
        paddingRight: 30,
      },
    ]),
    btnContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      // marginTop: 50,
      position: 'absolute',
      bottom: 50,
      width: '100%',
    },
    btnNext: {
      alignSelf: 'center',
      backgroundColor: colors.purple,
      width: '80%',
    },
    btnText: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.colorPrimary,
        fontWeight: '700',
        textAlign: 'center',
      },
    ]),
    arrow: {
      color: colors.white,
      width: '18',
      height: '16',
      paddingRight: 40,
      marginTop: 15,
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {
      container: {
        // position: 'absolute',
        width: '100%',
        height: '100%',
        color: colors.white,
      },
      paymentContainerDetails: {
        width: '100%',
        height: '100%',
        backgroundColor: colors.white,
      },
      header: {
        backgroundColor: colors.purple,
        height: 50,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
      headerText: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.white,
          fontWeight: '700',
          padding: 15,
          paddingLeft: '40%',
        },
      ]),
      verified: { width: 80, height: 80 },
      v_body: {
        justifyContent: 'center',
        textAlign: 'center',
      },
      imgContainer: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      //   textContainer: { paddingBottom: 200, marginTop: 5, },
      txtNote1: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.black,
          fontWeight: '700',
          lineHeight: 23,
          marginTop: 20,
        },
      ]),
      txtNote2: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          fontWeight: '700',
          color: colors.purple,
          paddingBottom: 30,
          lineHeight: 23,
        },
      ]),
      txtNote3: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        { fontWeight: '700', textAlign: 'center' },
      ]),
      divider: {
        width: '85%',
        backgroundColor: colors.grayPrimary,
        height: 1,
        alignItems: 'center',
        marginTop: 50,
        marginHorizontal: 'auto',
        // paddingHorizontal: 30
      },
    },
  }
);

export default useStyles;
