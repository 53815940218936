import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { StyleSheet, Dimensions } from 'react-native';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    v_drawer_container: {
      height: (Dimensions.get('window').height *.9) + 8,
      top: (Dimensions.get('window').height *.1) + 8,
      minWidth: 316,
      overFlowX: 'hidden',
    },
    v_drawer_translate: {
      zIndex: 2,
      width: '100%',
      height: '100%',
    },
    linear_style: {
      height: '100%',
      width: '100%',
      minHeight: 200,
      backgroundColor: colors.white,
    },
    btn_invisible: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: colors.blurBG,
    },

    // DRAWER HEADER
    drawer_user_frame_container: { alignItems: 'center' },
    drawer_unawa_logo: {
      height: 33,
      width: 172,
      marginTop: 35,
      tintColor: colors.purple,
    },
    user_edit_profile_container: {
      marginTop: 32,
      paddingLeft: 16,
      flexDirection: 'row',
      alignSelf: 'flex-start',
    },
    user_profile_picture: {
      height: 46,
      width: 46,
      borderRadius: 64,
      marginRight: 16,
    },
    v_details: { justifyContent: 'center' },
    user_fullname: {
      color: colors.purple,
      fontSize: 14,
      lineHeight: 20,
      fontWeight: '700',
      fontFamily: 'DMSans',
    },
    edit_profile: {
      fontSize: 11,
      lineHeight: 16,
      color: colors.purple,
    },
    divider: {
      width: '100%',
      height: 1,
      backgroundColor: colors.purple,
      marginTop: 16,
      opacity: 0.1,
    },
    icon_notif: {
      top: 0,
      left: -3,
      position: 'relative',
      backgroundColor: colors.green,
      height: 9,
      width: 9,
      borderRadius: 10,
    },
    ti_style: { marginTop: 0, width: '100%', height: '100%' },
    ti_search: {
      flex: 1,
      borderRadius: 8,
      backgroundColor: 'white',
    },
    ti_border_style: { flex: 1, backgroundColor: colors.white },
    btn_search: {
      height: 38,
      paddingVertical: 12.5,
      paddingHorizontal: 28,
      backgroundColor: colors.lightPurple,
      borderRadius: 8,
    },

    // DRAWER LIST
    drawer_tab_container: {
      flexWrap: 'wrap',
      paddingVertical: 15,
      flexDirection: 'row',
      paddingHorizontal: 26,
    },
    drawer_admintab_container: {
      paddingVertical: 5,
      flexDirection: 'row',
      paddingHorizontal: 26,
    },
    activeBG: {
      // backgroundColor: colors.lilac,
    },
    activeColor: {
      color: colors.purple,
      fontWeight: 'bold',
    },
    drawer_tab_label: {
      fontSize: 18,
      lineHeight: 24,
      fontWeight: '500',
      fontFamily: 'DMSans',
      color: colors.black //colors.purple,
    },
    drawer_icons: {
      height: 20,
      width: 20,
      marginRight: 18,
      tintColor: colors.purple,
    },
    drawer_mobile_notif: {
      flex: 1,
      flexDirection: 'row',
      // flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginTop: 25,
      paddingHorizontal: 25,
      marginBottom: 32,
    },
    drawer_mobile_textnotif: {
      fontFamily: 'DMSans',
      fontWeight: '700',
      fontSize: 14,
      lineHeight: 21,
      color: colors.purple,
      marginLeft: 12,
      textDecorationLine: 'underline',
    },
    drawer_mobile_search_container: {
      flex: 1,
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 26,
      paddingHorizontal: 18,
    },
    drawer_mobile_btn_search: {
      width: '40%',
      height: 41,
      position: 'absolute',
      right: 18,
      top: 8,
    },
    v_margintop: { marginTop: 37, paddingRight: 20, alignItems: 'flex-end' },
    v_height: { height: 20 },

    // COPYRIGHT
    drawer_content_footer: {
      alignItems: 'flex-end',
      paddingRight: 20,
      bottom: 10,
      left: 0,
      // right: 0,
    },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { marginBottom: 10, alignSelf: 'center', color: colors.black1, },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([
      TypographyStyle.copyRightButton,
      { fontWeight: '400' },
    ]),
    signModalBlurBG: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: colors.blurBG,
      justifyContent: 'center',
      alignItems: 'center',
    },
    signContainer: {
      backgroundColor: colors.white,
      width: '35%',
      left: 200,
      borderRadius: 20,
      padding: 40,
    },
    signHeader: {
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 31,
    },
    signTextHeader: {
      color: colors.black2,
      fontFamily: 'Montserrat',
      fontWeight: '700',
      fontSize: 24,
      lineHeight: 29,
    },
    signTabsContainer: {
      width: '100%',
      height: 50,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 7,
      paddingVertical: 2,
      borderRadius: 8,
      backgroundColor: colors.lilac,
    },
    signTabBG: {
      width: '22%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 6,
    },
    signTabText: {
      color: colors.black2,
      fontFamily: 'Lato',
      fontWeight: '400',
      fontSize: 13,
      lineHeight: 16,
    },
    signTabImg: { width: 18, height: 18, tintColor: colors.black2 },
    signDrawText: {
      color: colors.black2,
      fontFamily: 'DMSans',
      fontWeight: '400',
      fontSize: 16,
      lineHeight: 21,
      marginTop: 40,
      marginBottom: 27,
    },
    signBtnV: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 30,
    },
    btnReset: {
      backgroundColor: colors.white,
      borderWidth: 1,
      borderColor: colors.purple,
      width: '30%',
      marginRight: 12,
    },
    adminTabs: StyleSheet.flatten([
      TypographyStyle.Body2Regular,
      {
        paddingVertical: 15,
        paddingHorizontal: 26,
        color: colors.black3,
      },
    ]),
  },
  {
    [DEVICE_SIZES.LG]: {
      v_drawer_container: {
        minWidth: 260,
      },
      btn_search: {
        paddingHorizontal: 18,
        borderRadius: 8,
      },
      user_profile_picture: {
        height: 36,
        width: 36,
      },
      drawer_tab_label: {
        fontSize: 14,
        lineHeight: 16,
      },
      drawer_icons: {
        height: 12,
        width: 12,
        marginRight: 16,
      },
    },
    [DEVICE_SIZES.MD]: {
      v_drawer_container: {
        minWidth: 230,
      },
      btn_search: {
        paddingVertical: 10,
        paddingHorizontal: 18,
        borderRadius: 8,
      },
      user_profile_picture: {
        height: 36,
        width: 36,
      },
      drawer_tab_label: {
        fontSize: 12,
        lineHeight: 14,
      },
      drawer_icons: {
        height: 12,
        width: 12,
        marginRight: 15,
      },
      user_fullname: {
        fontSize: 12,
      },
      edit_profile: {
        fontSize: 9,
        lineHeight: 11,
      },
    },
    [DEVICE_SIZES.SM]: {
      v_margintop: {
        marginTop: 15,
      },
      v_drawer_container: {
        zIndex: 5,
        width: '100%',
        position: 'absolute',
      },
      v_drawer_translate: {
        height: '100%',
        width: '50%',
        transform: [{ translateX: -500 }],
      },
      drawer_mobile_textnotif: {
        fontSize: 14,
      },
      btn_search: {
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: 8,
      },
      user_profile_picture: {
        height: 36,
        width: 36,
        marginRight: 0,
      },
      drawer_tab_label: {
        fontSize: 16,
        lineHeight: 14,
      },
      drawer_icons: {
        height: 12,
        width: 12,
        marginRight: 10,
      },
      drawer_tab_container: {
        paddingHorizontal: 24,
      },
      user_fullname: {
        fontSize: 12,
        textAlign: 'center',
        marginTop: 15,
        marginBottom: 7,
      },
      edit_profile: {
        fontSize: 9,
        lineHeight: 11,
        textAlign: 'center',
      },
      user_edit_profile_container: {
        marginTop: 39,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      linear_style: { backgroundColor: colors.border6 },
    },
    [DEVICE_SIZES.XS]: {
      v_margintop: {
        marginTop: 15,
      },
      v_drawer_container: {
        zIndex: 5,
        width: '100%',
        position: 'absolute',
      },
      v_drawer_translate: {
        height: '100%',
        width: '70%',
        transform: [{ translateX: 700 }],
      },
      drawer_mobile_textnotif: {
        fontSize: 12,
        lineHeight: 16,
      },
      btn_search: {
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: 8,
      },
      user_profile_picture: {
        height: 36,
        width: 36,
        marginRight: 0,
      },
      drawer_tab_label: {
        fontSize: 14,
        lineHeight: 14,
      },
      drawer_icons: {
        height: 12,
        width: 12,
        marginRight: 10,
      },
      drawer_tab_container: {
        paddingHorizontal: 24,
      },
      user_fullname: {
        textAlign: 'center',
        fontSize: 12,
        marginTop: 15,
        marginBottom: 7,
      },
      edit_profile: {
        fontSize: 9,
        textAlign: 'center',
        lineHeight: 11,
      },
      btn_invisible: {
        width: '100%',
      },
      user_edit_profile_container: {
        marginTop: 39,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      linear_style: { backgroundColor: colors.border6 },
    },
  }
);

export default useStyles;
