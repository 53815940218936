import React, { useState, useContext } from 'react';
import { View, Text, Pressable } from 'react-native';
import { applySortFilter, getComparator } from '@src/utils/filter-helper';
import { LayerContext } from '../signature-fields/provider/layer';
import Data from './data.json';
import useStyles from './styles';
import Icon from '@src/components/icon';

type DataModel = {
  name: string;
  status: string;
  city: string;
};

type Props = {
  data: DataModel[];
  setDisabledButton: (value: boolean) => void;
};

const PartnerNotariesData = (props: Props) => {
  const [order, setOrder] = React.useState<any>(undefined);
  const [orderBy, setOrderBy] = React.useState('name');
  const { setPartner, setShowBottomSheet } = React.useContext(LayerContext);
  const { setDocument } = React.useContext(LayerContext);
  const { data, setDisabledButton } = props;
  const styles = useStyles();

  const onClickItem = (item: any) => {
    console.log('partneritem', item);
    setPartner(item);
    setShowBottomSheet(true);

    setDocument((updatedDocument: any) => ({
      ...updatedDocument,
      notary: item.name,
    }));
    
  };

  return (
    <View>
      {data.map((item, index) => {
        return (
          <View key={`filteredUsers${index}`}>
            <Pressable
              style={[styles.dataContainer]}
              onPress={() => onClickItem(item)}
            >
              <View style={{ flexDirection: 'row' }}>
                <Icon
                  name={'circle'}
                  color={item.status === 'Available' ? 'green' : 'red'}
                />
                <Text
                  style={styles.partnerName}
                >{`${item.name}, ${item.city}`}</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 20 }}>
                <Text
                  style={[
                    styles.partnerStatus,
                    { color: item.status === 'Available' ? 'green' : 'red' },
                  ]}
                >
                  {item.status}
                </Text>
              </View>
            </Pressable>
          </View>
        );
      })}
    </View>
  );
};

export default PartnerNotariesData;
