import React from 'react';
import QRCodeStyling from 'qr-code-styling';
import images from '@assets/images';

const qrCode = new QRCodeStyling({
  type: 'svg',
  image: images.favicon,
  dotsOptions: {
    color: '#4267b2',
    type: 'rounded',
  },
  imageOptions: {
    imageSize: 0.5,
    crossOrigin: 'anonymous',
  },
  cornersSquareOptions: {
    type: 'extra-rounded',
  },
});

function QRCode(props: any) {
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  React.useEffect(() => {
    qrCode.update({
      data: props.value,
      width: props.width,
      height: props.height,
    });
  }, [props.value, props.width, props.height]);

  return <div ref={ref} />;
}

export default QRCode;
