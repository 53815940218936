import React, { useState, useCallback, createContext } from 'react';
import { qrcodePosition } from '@src/utils/signature-helper';
import { useAppSelector } from '@src/ducks/ducksHook';
import {
  selectUserEmail,
  selectUserFullname,
} from '@src/ducks/slices/user.slice';
import { nanoid } from '@reduxjs/toolkit';
import { CanvasContext } from './canvas';
import colors from '@assets/colors';
import * as Types from './types';
import _ from 'lodash';

interface ContextValue {
  layers: any[];
  selectedUser: Types.SelectUserProps;
  qrposition: string;
  updateLayers: (value: any) => void;
  appendOnLayers: (value: any) => void;
  updateQRPosition: (value: string) => void;
  updateSelectedUser: (value: any) => void;
}

const LayerContext = createContext<ContextValue>({} as ContextValue);

const LayerProvider = ({ children }: Types.DocumentProviderProps) => {
  const email = useAppSelector(selectUserEmail);
  const fullname = useAppSelector(selectUserFullname);
  const { viewRef, numPages, canvas } = React.useContext(CanvasContext);
  const [layers, setLayers] = React.useState<any>([]);
  const [qrposition, setQRPosition] = useState<string>('Top left');
  const [selectedUser, setSelectedUser] = useState({
    name: fullname || 'Arjay Hufancia',
    email: email || 'arjay',
    fill: colors.signature1,
  } as Types.SelectUserProps);

  const updateSelectedUser = useCallback(
    (user: any) => {
      setSelectedUser(user);
    },
    [selectedUser]
  );

  const updateLayers = useCallback(
    (newlayers: any) => {
      setLayers(newlayers);
    },
    [layers]
  );

  const appendOnLayers = useCallback(
    (e: any) => {
      const additem = {
        id: nanoid(),
        ...e,
      };

      const rects = layers.slice();
      const layerFields = rects[canvas.pageIndex].layerFields.concat([additem]);
      rects[canvas.pageIndex].layerFields = layerFields;
      setLayers(rects);
    },
    [layers, canvas.pageIndex]
  );

  const updateQRPosition = useCallback(
    (position: string) => {
      viewRef.current?.measure(
        (x: number, y: number, width: number, height: number) => {
          const qritem = qrcodePosition(
            position,
            Math.abs(width / canvas.scale),
            Math.abs(height / canvas.scale)
          );

          const newLayers = _.reduce(
            layers,
            (result: any, value) => {
              let layerFields = value.layerFields;

              const index = _.findIndex(layerFields, { type: 'qrcode_sign' });

              if (index !== -1) {
                layerFields[index] = qritem;
              } else {
                layerFields.push(qritem);
              }

              result.push({ ...value, layerFields });

              return result;
            },
            []
          );
          setLayers(newLayers);
        }
      );

      setQRPosition(position);
    },
    [qrposition, layers, viewRef.current, canvas.scale]
  );

  React.useEffect(() => {
    if (numPages > 0) {
      const qritem = qrcodePosition('Top left', 0, 0);

      const newlayers = _.range(numPages).map((item, index) => {
        if (index === numPages - 1) {
          return {
            id: nanoid(),
            ref: React.createRef(),
            layerFields: [
              qritem,
              {
                id: 'wxtbFB5KFrCG00-gntQS6',
                type: 'signature_notary',
                x: 440.52083333333333,
                y: 700.3333333333333,
                width: 130,
                height: 50,
                name: 'Arjay Hufancia',
                fill: '#FFAAAA',
                email: 'aaaa',
              },
            ],
          };
        } else {
          return {
            id: nanoid(),
            ref: React.createRef(),
            layerFields: [qritem],
          };
        }
      });
      setLayers(newlayers);
    }
  }, [numPages]);

  const value = {
    layers,
    qrposition,
    selectedUser,
    updateLayers,
    appendOnLayers,
    updateQRPosition,
    updateSelectedUser,
  };

  return (
    <LayerContext.Provider value={value}>{children}</LayerContext.Provider>
  );
};

export { LayerProvider, LayerContext };
