import React, { useContext } from 'react';
import { TouchableOpacity, Text, View, Image } from 'react-native';
import { DrawerContext } from '../../DrawerProvider';
import Icon from '@expo/vector-icons/Feather';
import useStyles from './styles.css';
import images from '@assets/images';
import colors from '@assets/colors';
import TypographyStyle from '@assets/typography';

const MobileHeader = () => {
  const styles = useStyles();
  const { onDrawerStatus, onActiveTab } = useContext(DrawerContext);

  return (
    <View style={styles.drawer_mobile_container}>
      {/* <Image
        source={images.enotary_horizontal_color}
        style={styles.drawer_mobile_logo}
        resizeMode="contain"
      /> */}
      <View style={{ height: 35, paddingHorizontal: 10, top: -5 }}>
        <Text style={{ 
          color: colors.black,
        }}>Welcome back!</Text>
        <Text style={{ 
          ...TypographyStyle.heading4Bold,
          color: colors.black,
        }}>Kyle Ferrer</Text>
      </View>
      <View style={{ flexDirection: 'row', }}>
        <TouchableOpacity onPress={() => onActiveTab('Notification')}>
          <Icon name="bell" size={24} color={colors.black} />
          <View style={styles.icon_notif} />
        </TouchableOpacity>
        <View style={{ width: 10 }} />
        <TouchableOpacity onPress={onDrawerStatus}>
          <Icon name="menu" size={24} color={colors.black} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default MobileHeader;
