import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StyleSheet } from 'react-native';
import TypographyStyle from '@assets/typography';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.white,
      minHeight: '100vh'
    },
    v_body: {
      flex: 1,
      // flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_logo_container: {
      width: '100%',
    },
    imgContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 300,
      height: 250,
      paddingTop: 130,
      marginTop: 100,
    },
    verified: { width: 80, height: 80 },
    txtNote1: StyleSheet.flatten([TypographyStyle.heading2Bold]),
    txtNote2: StyleSheet.flatten([TypographyStyle.heading4Regular]),
    txtNote3: StyleSheet.flatten([TypographyStyle.Body2Regular]),
    textContainer: {},
    textContainer2: {},
    btn_container: {},
    btnStyle: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.colorPrimary,
        fontWeight: '700'
      },
    ]),
    btn_uploadDocument: { marginTop: 30, backgroundColor: colors.black },
    lbl_uploadDocument: { fontFamily: 'Montserrat-Bold' },
  },
  {
    [DEVICE_SIZES.XS]: {
      verified: { width: 80, height: 80 },
      v_body: {
        justifyContent: 'center',
        textAlign: 'center',
      },
      imgContainer: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      textContainer: { paddingBottom: 100, marginTop: 5 },
      txtNote1: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        { color: colors.black, fontWeight: '700', lineHeight: 23 },
      ]),
      txtNote2: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          fontWeight: '700',
          color: colors.purple,
          paddingBottom: 30,
          lineHeight: 23,
        },
      ]),
      txtNote3: StyleSheet.flatten([
        TypographyStyle.heading6Regular,
        { lineHeight: 20 },
      ]),
      btn_container: {
        position: 'absolute',
        bottom: 0,
        paddingBottom: 20,
        width: '100%',
      },
      btn_uploadDocument: { marginTop: 30, backgroundColor: colors.purple },
      lbl_uploadDocument: StyleSheet.flatten([
        TypographyStyle.BodySRegular,
        { fontWeight: '700', color: colors.white },
      ]),
    },
  }
);

export default useStyles;
