import React, { memo, useContext } from 'react';
import { CanvasContext } from '../../provider/canvas';
import { Layer } from 'react-konva';
import Items from './items';

export const FONT_SIZE = 15;
export const IMAGE_SIZE = 20;
export const LAYER_WIDTH = 130;
export const LAYER_HEIGHT = 50;

const LayerCanvas = React.forwardRef(
  ({ item, documentId, onShowSignature, layerId }: any) => {
    const { canvas } = useContext(CanvasContext);

    return (
      <Layer>
        {item.layerFields.map((field: any, index: number) => {
          return (
            <Items
              key={index.toString()}
              field={{
                ...field,
                x: field.x * canvas.scale,
                y: field.y * canvas.scale,
                width: field.width * canvas.scale,
                height: field.height * canvas.scale,
                fontSize: field.fontSize * canvas.scale,
                documentId,
                config: {
                  ...field.config,
                  x: field?.config?.x * canvas.scale,
                  y: field?.config?.y * canvas.scale,
                  width: field?.config?.width * canvas.scale,
                  height: field?.config?.height * canvas.scale,
                },
                textConfig: {
                  ...field.textConfig,
                  fontSize: field?.textConfig?.fontSize * canvas.scale,
                },
                nameConfig: {
                  ...field.nameConfig,
                  y: field?.nameConfig?.y * canvas.scale,
                  fontSize: field?.nameConfig?.fontSize * canvas.scale,
                },
                dateConfig: {
                  ...field.dateConfig,
                  y: field?.dateConfig?.y * canvas.scale,
                  fontSize: field?.dateConfig?.fontSize * canvas.scale,
                },
                designationConfig: {
                  ...field.designationConfig,
                  y: field?.designationConfig?.y * canvas.scale,
                  fontSize: field?.designationConfig?.fontSize * canvas.scale,
                },
                checkConfig: {
                  ...field.checkConfig,
                  width: field?.checkConfig?.width * canvas.scale,
                  height: field?.checkConfig?.height * canvas.scale,
                  marginRight: field?.checkConfig?.marginRight * canvas.scale,
                },
                inputConfig: {
                  ...field.inputConfig,
                  fontSize: field?.inputConfig?.fontSize * canvas.scale,
                  width: field?.inputConfig?.width * canvas.scale,
                  height: field?.inputConfig?.height * canvas.scale,
                },
              }}
              onSelect={() =>
                onShowSignature({ layerId, fieldId: index, type: field.type })
              }
            />
          );
        })}
      </Layer>
    );
  }
);

export default memo(LayerCanvas);
