import React, { useContext } from 'react';
import { PluginContext } from '../provider/plugin';
import { MaterialIcons } from '@expo/vector-icons';
import { Button02 } from '@src/components/button';
import { View, Text } from 'react-native';
import {
  RenderCurrentPageLabelProps,
  RenderGoToPageProps,
} from '@react-pdf-viewer/page-navigation';
import useStyles from './styles.css';
import colors from '@assets/colors';

const PDFPages = () => {
  const styles = useStyles();
  const { pageNavigationPluginInstance } = useContext(PluginContext);
  const { CurrentPageLabel, GoToNextPage, GoToPreviousPage } =
    pageNavigationPluginInstance;

  return (
    <View style={styles.container}>
      <View style={styles.v_page_wrapper}>
        <GoToPreviousPage>
          {({ onClick }: RenderGoToPageProps) => (
            <Button02 onPress={onClick} style={styles.pdf_btn_export}>
              <MaterialIcons
                name="chevron-left"
                color={colors.black}
                size={25}
              />
            </Button02>
          )}
        </GoToPreviousPage>
        <CurrentPageLabel>
          {({ currentPage, numberOfPages }: RenderCurrentPageLabelProps) => (
            <Text selectable={false} style={styles.t_pages}>
              Page {`${currentPage + 1} / ${numberOfPages}`}
            </Text>
          )}
        </CurrentPageLabel>
        <GoToNextPage>
          {({ onClick }: RenderGoToPageProps) => (
            <Button02 onPress={onClick} style={styles.pdf_btn_export}>
              <MaterialIcons
                name="chevron-right"
                color={colors.black}
                size={25}
              />
            </Button02>
          )}
        </GoToNextPage>
      </View>
    </View>
  );
};

export default React.memo(PDFPages);
