import React, { useState, useCallback, useRef, useContext } from 'react';
import { ScrollView } from 'react-native';
import { Button01 } from '@src/components/button';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { useNavigation } from '@react-navigation/native';
import { LiveSessionContext } from '../provider';
import { images } from 'theme';
import { Card } from 'react-native-paper';
import Data from './data.json';
import Panel from './panel';

const UploadDocumentModal = () => {
  const ref = useRef<SheetRef>();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();
  const { showAddSignature, onHideAddSignature } = React.useContext(LiveSessionContext);

  return (
      <Sheet
        ref={ref}
        isOpen={showAddSignature}
        onClose={() => onHideAddSignature()}
        snapPoints={[450]}
        
        initialSnap={1}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <ScrollView>
                  <Panel />
              </ScrollView>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
      </Sheet>
  );
};

export default UploadDocumentModal;
