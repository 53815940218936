import { Text, View } from 'react-native';
import useStyles from './styles.css';
import React from 'react';
import { useNavigation } from '@react-navigation/native';

const CopyRightContent = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();

  return (
    // <View style={styles.drawer_content_footer}>
    //   <Text style={styles.copyRightStyle}>
    //     <Text style={styles.copyRightBtnStyle} onPress={() => navigation.navigate("terms-condition")}>Terms & Conditions</Text> |
    //     <Text style={styles.copyRightBtnStyle} onPress={() => navigation.navigate("privacy-policy")}> Privacy Policy</Text>
    //     {'\n© 2023 '}
    //     <Text style={styles.copyRightBtnStyle}>UNAWA</Text>. All rights reserved
    //   </Text>
    // </View>
    <View style={styles.drawer_content_footer}>
        <Text style={{ fontSize: 12, }} onPress={() => navigation.navigate("terms-condition")}>Terms & Conditions</Text>
        <Text style={{ fontSize: 12, }} onPress={() => navigation.navigate("privacy-policy")}> Privacy Policy</Text>
        <Text style={{ fontSize: 10, }}> © 2024 NotarizeIt. All rights reserved</Text>
    </View>
  );
};

export default CopyRightContent;
