/* eslint-disable react-native/no-inline-styles */
import React, { useContext, useState, useMemo, useEffect } from 'react';

// Import Worker
import { Worker, Viewer, ScrollMode, CharacterMap } from '@react-pdf-viewer/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';

import { selectCreateRequestData } from '@src/ducks/slices/signsecure.slice';
import { usePDFScale, mobileSizeChecker } from '@src/utils/screensize-helper';
import { View, ScrollView, ActivityIndicator } from 'react-native';
import { onViewerStateChange } from '@src/utils/pdf-plugin';
import { getViewport } from '@src/utils/signature-helper';
import { useAppSelector } from '@src/ducks/ducksHook';
import { PluginContext } from '../provider/plugin';
import { CanvasContext } from '../provider/canvas';
// import PageInfo from '../../page-info';
import useStyles from './styles.css';
import KonvaStages from '../stages';
import Pages from '../pages';
import { LayerContext } from '../../signature-fields/provider/layer';

// import { , Viewer } from '@react-pdf-viewer/core';

function PDFViewer(props: any) {
  const { item } = props;

  const styles = useStyles();
  const scale = usePDFScale(1.1);
  const {
    pageNavigationPluginInstance,
    zoomPluginInstance,
    printPluginInstance,
  } = useContext(PluginContext);
  const isMobile = mobileSizeChecker();
  const { zoomTo } = zoomPluginInstance;
  const [loading, setLoading] = useState(true);
  const { canvas, viewRef, onUpdateCanvas } = useContext(CanvasContext);
  const viewerStateChange = onViewerStateChange(onUpdateCanvas);
  const requestData = useAppSelector(selectCreateRequestData);
  const { layers } = React.useContext(LayerContext);
  const [reload, setReload] = useState(false)

  useEffect(() => {
    setReload(false)
  }, [layers])

  useEffect(() => {
    if (!reload) {
      setReload(true)
    }
  }, [reload])

  const characterMap: CharacterMap = {
    isCompressed: true,
    // The url has to end with "/"
    url: item.path ? item.path : null,
  };

  const { CANVAS_WIDTH, CANVAS_HEIGHT } = useMemo(
    () => getViewport(canvas, scale),
    [canvas, scale]
  );

  React.useEffect(() => {
    zoomTo(scale);
  }, [scale]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <View style={styles.container}>
        <ScrollView style={styles.scroll_vertical}>
          {/* {isMobile && (
            <PageInfo
              title="Live Notarization Request"
              subtitle="Video Session"
              subtitleStyle={{ marginBottom: 0 }}
            />
          )} */}
          {/* asdasd */}
          <Pages />
          <ScrollView
            horizontal
            contentContainerStyle={[
              styles.scroll_horizontal,
              {
                width: CANVAS_WIDTH,
                height: CANVAS_HEIGHT + 50,
              },
            ]}
          >
            <View
              ref={viewRef}
              style={[
                styles.v_viewer,
                {
                  width: CANVAS_WIDTH,
                  height: CANVAS_HEIGHT,
                },
              ]}
            >
              {item.uri && reload && (
                <Viewer
                  fileUrl={item.uri}
                  defaultScale={scale}
                  enableSmoothScroll={false}
                  scrollMode={ScrollMode.Page}
                  plugins={[
                    pageNavigationPluginInstance,
                    zoomPluginInstance,
                    viewerStateChange,
                    printPluginInstance,
                  ]}
                  onDocumentLoad={() => setLoading(false)}
                  renderLoader={() => (
                    <View
                      style={{
                        paddingTop: 100,
                        width: CANVAS_WIDTH,
                        height: CANVAS_HEIGHT,
                      }}
                    >
                      <ActivityIndicator animating color="black" size={30} />
                    </View>
                  )}
                />
              )}
              {reload && <KonvaStages
                loading={loading}
                documentId={requestData.id}
                layers={requestData?.workflow?.signatures ?? [
                  [
                    {
                      id: "wSlz1mtpVGegUlvPJ_zba",
                      type: "signature",
                      trackId: "O8YDQKPqTFY_kdFklg7t5",
                      config: {
                        id: "wSlz1mtpVGegUlvPJ_zba",
                        fill: "#FFAAAA",
                        x: 364.3449519230769,
                        y: 95.1923076923077,
                        width: 154,
                        height: 58,
                        rotation: 0,
                        stroke: "#FFAAAA",
                        strokeWidth: "1"
                      },
                      textConfig: {
                        id: "wSlz1mtpVGegUlvPJ_zba",
                        text: "SIGNATURE WILL BE PLACED HERE",
                        fontSize: 6,
                        fontFamily: "DMSans",
                        fill: "#FFAAAA",
                        x: 364.3449519230769,
                        y: 95.1923076923077,
                        width: 154
                      },
                      nameConfig: {
                        id: "1N22AWuS81h-dV145awuV",
                        x: 49.72956730769231,
                        y: 64,
                        text: "Arjay  Hufancia",
                        fontSize: 10,
                        width: 154,
                        fontFamily: "DMSans",
                        fill: "#000000"
                      }
                    },
                  ]
                ]}
              />
              }
            </View>
          </ScrollView>
        </ScrollView>
      </View>
    </Worker>
  );
}

export default PDFViewer;
