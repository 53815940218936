import * as api from './request';
import * as Types from '@src/ducks/types';

export const login = (params: Types.LoginInput) =>
  api.callPost('/auth/login', params);

export const refreshToken = (params: Types.SessionValue) =>
  api.callPost('/auth/refresh-token', params);

export const forgotpassword = (params: Types.LoginInput) =>
  api.callPost('/auth/forgot-password', params);

export const resetpassword = (params: Types.ResetPasswordInput) =>
  api.callPut('/auth/reset-password', params);

export const register = (params: any) =>
  api.callFormData('/auth/register', params);

export const userInfo = (params: Types.SessionValue) =>
  api.callGet('/user/' + params.id, params.accessToken);

export const updateUser = (params: Types.UpdateUserValue & Types.Token) =>
  api.callPatch('/user/' + params.id, params, params.accessToken);

export const signsecureCreate = (params: any, accessToken: string) =>
  api.callFormData('/directory/signsecure/create', params, accessToken);

export const signsecureViewDocument = (
  document_id: string,
  accessToken: string
) => api.callPost('/directory/signsecure/view/' + document_id, {}, accessToken);

export const signsecureDownloadDocument = (
  document_id: string,
  accessToken: string
) => api.callGet('/directory/proxy/' + document_id, accessToken, 'blob');

export const signsecureSignCompleteDocument = (
  document_id: string,
  params: any,
  accessToken: string
) =>
  api.callFormData(
    '/directory/signsecure/sign-complete/' + document_id,
    params,
    accessToken
  );

// UPLOAD SIGNATURE
export const createSignature = (params: any, accessToken: string) =>
  api.callFormData('/signature', params, accessToken);

export const fetchAllSignatures = (params: any) =>
  api.callGet('/signature/all?', params.accessToken);

export const deleteSignatureById = (params: any) =>
  api.callDelete('/signature/' + params.id, params.accessToken);

export const generateOTP = (params: any) =>
  api.callPost('/user/generate-otp', {}, params.accessToken);

export const validateOTP = (params: any) =>
  api.callGet('/user/validate-otp?otp=' + params.otp, params.accessToken);

export const fetchAllMessages = (params: any) =>
  api.callGet('/messages/all', params.accessToken);

export const fetchMessageById = (params: any) =>
  api.callGet('/messages/data/' + params.id, params.accessToken);
