import colors from '@assets/colors';
import TypographyStyle from '@assets/typography';
import { windowHeight, windowWidth } from '@src/utils/dimension-helper';
import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      position: 'absolute',
      width: '100%',
      height: '150%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blurBG,
    },
    paymentContainerDetails: { backgroundColor: colors.white },
    header: {
      backgroundColor: colors.purple,
      height: 50,
      display: 'flex',
      flexDirection: 'row',
    },
    headerText: {},
    v_body: {},
    textContainer: {},
    v_logo_container: {
      width: '100%',
    },
    imgContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '50%',
      paddingBottom: 20,
    },
    verified: { width: 80, height: 80 },
    txtNote1: StyleSheet.flatten([TypographyStyle.heading2Bold]),
    txtNote5: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      { fontWeight: '500', lineHeight: 18 },
    ]),
    detailsContainer3: {
      flexDirection: 'column',
      marginTop: 50,
    },
    btnContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      // marginTop: 100,
      bottom: 50,
      position: 'absolute',
      width: '100%',
    },
    btnNext: {
      alignSelf: 'center',
      backgroundColor: colors.purple,
      width: '80%',
    },
    btnText: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      {
        color: colors.colorPrimary,
        fontWeight: '700',
        textAlign: 'center',
      },
    ]),
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {
      container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        color: colors.white,
      },
      paymentContainerDetails: {
        width: '100%',
        height: '100%',
        backgroundColor: colors.white,
      },
      v_body: {
        justifyContent: 'center',
        textAlign: 'center',
      },
      header: {
        backgroundColor: colors.purple,
        height: 50,
        flexDirection: 'row',
        justifyContent: 'center',
        display: 'flex',
      },
      headerText: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        {
          color: colors.white,
          fontWeight: '700',
          padding: 15,
        },
      ]),
      imgContainer: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      verified: { width: 80, height: 80 },
      txtNote1: StyleSheet.flatten([
        TypographyStyle.Body1Regular,
        { color: colors.black, fontWeight: '700', lineHeight: 20 },
      ]),
      txtNote5: StyleSheet.flatten([
        TypographyStyle.heading6Regular,
        { fontWeight: '500', lineHeight: 18 },
      ]),
    },
  }
);

export default useStyles;
