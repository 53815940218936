import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';
import { StyleSheet } from 'react-native';
import TypographyStyle from '@assets/typography';
import { colors } from 'theme';
import { color } from 'react-native-reanimated';
import { transparent } from 'react-native-paper/lib/typescript/styles/themes/v2/colors';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.white,
    },
    containerStep1: {
      flex: 1,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
    },
    playModal: {
      zIndex: -1,
      overflow: 'hidden',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.black,
      opacity: 0.7,
      marginTop: -115,
      paddingLeft: 5
    },
    play: { height: 30, width: 26 },
    v_logo_container: {
      width: '100%',
    },
    imgContainer: {},
    imgContainerStep1: {
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      marginTop: -300,
    },
    imgContainerPlay: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    get_started: { width: 340, height: 315 },
    get_started_step1: { width: '150%', height: 550 },
    get_started_text: { width: 340, height: 315 },
    v_body: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    onboarding_text: { position: 'relative' },
    txtNote1: StyleSheet.flatten([TypographyStyle.heading2Bold]),
    txtNote2: StyleSheet.flatten([TypographyStyle.heading4Regular]),
    txtNote3: StyleSheet.flatten([TypographyStyle.Body2Regular]),
    textContainer: {},
    textContainer2: {},
    btn_container: {},
    btn_signin: { marginTop: 30, backgroundColor: colors.black },
    lbl_signin: { fontFamily: 'Montserrat-Bold' },
    btn_skip: { marginTop: 30, backgroundColor: colors.black },
    lbl_skip: { fontFamily: 'Montserrat-Bold' },
    step1TextContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 30,
    },
    text1: StyleSheet.flatten([
      TypographyStyle.Heading5Bold,
      {
        color: colors.purple4,
      },
    ]),
    text2: StyleSheet.flatten([
      TypographyStyle.heading4Bold,
      {
        color: colors.purple4,
      },
    ]),
    text3: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      {
        color: colors.black3,
        textAlign: 'center',
        lineHeight: 20.83,
      },
    ]),
    xBtnLabel: {
      color: colors.colorPrimary,
      fontSize: 20
    },
  },

  {
    [DEVICE_SIZES.XS]: {
      v_body: {
        height: 100,
        flexDirection: 'column',
      },

      v_logo_container: {
        height: 130,
      },
      container: {},
      imgContainer: {
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      },
      get_started: { width: 270, height: 420 },
      textContainer: {
        position: 'relative',
        shadowOpacity: 20,
        backgroundColor: colors.white,
        elevation: 55,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        textShadowColor: colors.white,
      },
      textContainer2: {
        position: 'relative',
        textAlign: 'center',
        backgroundColor: colors.white,
        paddingTop: 30,
      },
      txtNote1: StyleSheet.flatten([
        TypographyStyle.heading4Bold,
        { color: colors.purple },
      ]),
      txtNote2: StyleSheet.flatten([
        TypographyStyle.heading6Regular,
        { fontWeight: '700', color: colors.purple },
      ]),
      txtNote3: StyleSheet.flatten([TypographyStyle.Body2Regular]),
      btn_container: {
        marginBottom: -150,
      },
      btn_signin: { marginTop: 30, backgroundColor: colors.purple },
      lbl_signin: StyleSheet.flatten([
        TypographyStyle.BodySRegular,
        { fontWeight: '700', color: colors.white },
      ]),
      btn_skip: { marginTop: 30, backgroundColor: colors.transparent },
      lbl_skip: StyleSheet.flatten([
        TypographyStyle.BodySRegular,
        { fontWeight: '700', color: colors.purple },
      ]),
    },
  }
);

export default useStyles;
