import React, { useContext, useState } from 'react';
import { Text, View, Image, Pressable } from 'react-native';
import { getImage } from '@src/utils/mockdata/drawer-content';
import { DrawerContext } from '../DrawerProvider';
import useStyles from './styles.css';

const DrawerList = () => {
  const styles = useStyles();
  const { drawerList, activeTab, onActiveTab } = useContext(DrawerContext);
  const [hover, setHover] = useState(
    new Array<boolean>(drawerList.length).fill(false)
  );

  const handleHover = (eventName: string, idx: number) => {
    const isHover = eventName === 'hoverIn';
    setHover((prevState) => ({
      ...prevState,
      [idx]: isHover,
    }));
  };

  return (
    <View style={styles.v_margintop}>
      {drawerList.map((label: string, index: number) => {
        const isActive = label === activeTab || hover[index];
        const activeBG = isActive && styles.activeBG;
        const activeColor = isActive && styles.activeColor;
        const source = React.useMemo(() => getImage(label), [label]);

        return (
          <Pressable
            key={`tabs${index}`}
            onPress={() => onActiveTab(label)}
            onPressIn={() => handleHover('hoverIn', index)}
            onPressOut={() => handleHover('hoverOut', index)}
            onHoverIn={() => handleHover('hoverIn', index)}
            onHoverOut={() => handleHover('hoverOut', index)}
            style={[styles.drawer_tab_container, activeBG]}
          >
            {/* <Image
              source={source}
              resizeMode="contain"
              style={[styles.drawer_icons]}
            /> */}
            <Text style={[styles.drawer_tab_label, activeColor]}>{label}</Text>
          </Pressable>
        );
      })}
    </View>
  );
};

export default DrawerList;
