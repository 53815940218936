import React, { createContext, useCallback, useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { RootStackParamList, RootStackOptionsValue } from './NavigatorTypes';

// REDUCER
import { selectAuthLoggedIn } from '@src/ducks/slices/auth.slice';
import { useAppSelector } from '@src/ducks/ducksHook';

// COMPONENT SCREENS
import SignIn from '@src/screens/auth/signin';
import SplashScreen from '@src/screens/auth/splash-screen';
import OnBoardingStep1 from '@src/screens/auth/onboarding-screen/onboarding-step1';
import SignInBackAgain from '@src/screens/auth/signin-back';
import SignUp from '@src/screens/auth/signup';
import VerificationSuccess from '@src/screens/auth/verification/success';
import VerificationFailed from '@src/screens/auth/verification/failed';
import VerificationCheck from '@src/screens/auth/verification';
import TermsCondition from '@src/screens/auth/terms-condition';
import PrivacyPolicy from '@src/screens/auth/privacy-policy';
import Forgot from '@src/screens/auth/forgot-password';
import ResetPassword from '@src/screens/auth/reset-password';
import Homepage from '@src/screens/drawer-screens';
import LiveSession from '@src/screens/live-session';
import LiveSessionEnd from '@src/screens/live-session-end';
import LiveRequest from '@src/screens/live-request';
// import LiveRequestNew from '@src/screens/live-request-new';
import TEST from './test';
import Transaction from '@src/screens/drawer-screens/transactions';
import Popup from '@src/screens/drawer-screens/signatures/upload';
import OnBoarding from '@src/screens/auth/onboarding-screen';
import FaceVerified from '@src/screens/gnotary/face-verified';
import DocumentDetails from '@src/screens/live-request-new/document-details';
import AddWitnessMobile from '@src/screens/live-request-new/witness-signature/add-witness-mobile';
import AddPrincipalMobile from '@src/screens/live-request-new/witness-signature/add-principal-mobile';
import PartnerNotariesMobile from '@src/screens/live-request-new/partner-notaries';
import AddSignatureFields from '@src/screens/live-request-new/signature-fields';
import ReviewDetails from '@src/screens/live-request-new/review-details';
import UploadDocumentSuccess from '@src/screens/gnotary/upload-document/upload-success';
import ViewAllDocument from '@src/screens/drawer-screens/dashboard/view-all-document';
import PaymentPage from '@src/screens/live-request-new/payments';
import PaymentSuccess from '@src/screens/live-request-new/payments/payment-success/success-payment';
import PaymentConfirmation from '@src/screens/live-request-new/payments/payment-confirmation/payment-confirmation';

import { WitnessScreenProvider } from '../screens/live-request-new/signature-fields/provider/screen';
import { PluginProvider } from '../screens/live-request-new/signature-fields/provider/plugin';
import { CanvasProvider } from '../screens/live-request-new/signature-fields/provider/canvas';
import { LayerProvider } from '../screens/live-request-new/signature-fields/provider/layer';
import { LiveSessionProvider } from '../screens/live-request-new/provider';
import { VideoProvider } from '../screens/live-session/video-session/provider/video';

const RootStack = createStackNavigator<RootStackParamList>();

const RootStackOptions: RootStackOptionsValue = {
  headerShown: false,
  title: 'NotarizeIT',
  cardStyle: { backgroundColor: 'transparent' },
};

interface AppRoutingValue {
  isLoggedIn: boolean;
  testLogIn: () => void;
  testLogOut: () => void;
  onShowHeader: () => void;
  onHideHeader: () => void;
  onSearchStateChange: (value: string) => void;
  search: string
  headerHidden: boolean;
}

const AppRoutingContext = createContext<AppRoutingValue>({
  isLoggedIn: false,
  testLogIn: () => {},
  testLogOut: () => {},
  headerHidden: false,
  search: "",
  onSearchStateChange: () => {},
  onShowHeader: () => {},
  onHideHeader: () => {},
} as AppRoutingValue);

const AppRoutingProvider = () => {
  // const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [headerHidden, setHeaderHidden] = useState(false);
  const [search , setSearch] = React.useState<string>("")

  const testLogIn = useCallback(() => {
    setIsLoggedIn(true);
  }, []);

  const testLogOut = useCallback(() => {
    setIsLoggedIn(false);
  }, []);

  const onShowHeader = useCallback(() => {
    setHeaderHidden(false);
  }, []);

  const onHideHeader = useCallback(() => {
    setHeaderHidden(true);
  }, []);

  const onSearchStateChange = useCallback((value: string) => {
    setSearch(value)
    // console.log(value,'value')
  },[search])

  const screenComponents = React.useMemo(() => {
    if (isLoggedIn) {
      return (
        <>
          <RootStack.Screen name="Home" component={Homepage} />
          <RootStack.Screen name="LiveSession" component={LiveSession} />
          <RootStack.Screen name="LiveSessionEnd" component={LiveSessionEnd} />
          {/* <RootStack.Screen name="LiveRequestNew" component={LiveRequestNew} /> */}
          <RootStack.Screen
            name="verify-success"
            component={VerificationSuccess}
          />
          <RootStack.Screen name="FaceVerified" component={FaceVerified} />
          <RootStack.Screen name="ViewAllDocument" component={ViewAllDocument} />
          <RootStack.Screen name="UploadDocumentSuccess" component={UploadDocumentSuccess} />
          <RootStack.Screen name="DocumentDetails" component={DocumentDetails} />
          <RootStack.Screen name="AddPrincipalMobile" component={AddPrincipalMobile} />
          <RootStack.Screen name="AddWitnessMobile" component={AddWitnessMobile} />
          <RootStack.Screen name="PartnerNotariesMobile" component={PartnerNotariesMobile} />
          <RootStack.Screen name="AddSignatureFields" component={AddSignatureFields} />
          <RootStack.Screen name="ReviewDetails" component={ReviewDetails} />
          <RootStack.Screen name="PaymentPage" component={PaymentPage} />
          <RootStack.Screen name="PaymentSuccess" component={PaymentSuccess} />
          <RootStack.Screen name="PaymentConfirmation" component={PaymentConfirmation} />
          <RootStack.Screen name="privacy-policy" component={PrivacyPolicy} />
          <RootStack.Screen name="terms-condition" component={TermsCondition} />
        </>
      );
    } else {
      return (
        <>
          {/* <RootStack.Screen name="Signin" component={SignIn} /> */}
          <RootStack.Screen name="Splash" component={SplashScreen} />
          <RootStack.Screen name="FaceVerified" component={FaceVerified} />
          <RootStack.Screen name="OnBoarding" component={OnBoarding} />
          <RootStack.Screen name="OnBoardingStep1" component={OnBoardingStep1} />
          <RootStack.Screen name="SignInBack" component={SignInBackAgain} />
          <RootStack.Screen name="Signup" component={SignUp} />
          <RootStack.Screen name="Forgot" component={Forgot} />
          <RootStack.Screen name="ResetPassword" component={ResetPassword} />
          <RootStack.Screen
            name="verification-check"
            component={VerificationCheck}
          />
          <RootStack.Screen name="privacy-policy" component={PrivacyPolicy} />
          <RootStack.Screen name="terms-condition" component={TermsCondition} />
        </>
      );
    }
  }, [isLoggedIn]);

  

  const value = {
    isLoggedIn,
    testLogIn,
    testLogOut,
    onShowHeader,
    onHideHeader,
    headerHidden,
    onSearchStateChange,
    search
  };

  return (
    <PluginProvider>
      <CanvasProvider>
        <WitnessScreenProvider>
          <LayerProvider>
            <VideoProvider>
              <LiveSessionProvider>
                <AppRoutingContext.Provider value={value}>
                  <RootStack.Navigator screenOptions={RootStackOptions}>
                    {screenComponents}
                  </RootStack.Navigator>
                </AppRoutingContext.Provider>
              </LiveSessionProvider>
            </VideoProvider>
          </LayerProvider>
        </WitnessScreenProvider>
      </CanvasProvider>
    </PluginProvider>
  );
};

export { AppRoutingProvider, AppRoutingContext };
