import React, { useState, useCallback, createContext } from 'react';
import { useRoute, useFocusEffect } from '@react-navigation/native';
import { dashboardDocuments } from "@src/utils/transform-helper";
import { useFetchGet } from "@src/utils/api/hooks";
import _ from "lodash";


interface LiveSessionValue {
  activeIndex: number;
  showLiveParties: boolean;
  showLiveChat: boolean;
  showPDF: boolean;
  showAddSignature: boolean;
  onShowLiveParties: () => void;
  onHideLiveParties: () => void;
  onShowLiveChat: () => void;
  onHideLiveChat: () => void;
  onShowPDF: () => void;
  onHidePDF: () => void;
  onShowAddSignature: () => void;
  onHideAddSignature: () => void;
  onLiveVideoSession: () => void;
}

const LiveSessionContext = createContext<LiveSessionValue>({} as LiveSessionValue);

interface ProviderProps {
  children: React.ReactElement;
}

const LiveSessionProvider = ({ children }: ProviderProps) => {
  const [showLiveChat, setShowLiveChat] = useState(false);
  const [showLiveParties, setShowLiveParties] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [showAddSignature, setShowAddSignature] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);

  const onLiveVideoSession = useCallback(() => {
    setActiveIndex(1);
  },[setActiveIndex]);

  const onShowLiveParties = useCallback(() => {
    setShowLiveParties(true);
  },[showLiveParties]);

  const onHideLiveParties = useCallback(() => {
    setShowLiveParties(false);
  },[showLiveParties]);

  const onShowLiveChat = useCallback(() => {
    setShowLiveChat(true);
  },[showLiveChat]);

  const onHideLiveChat = useCallback(() => {
    setShowLiveChat(false);
  },[showLiveChat]);

  const onShowPDF = useCallback(() => {
    setShowPDF(true);
  },[showLiveChat]);

  const onHidePDF = useCallback(() => {
    setShowPDF(false);
  },[showLiveChat]);

  const onShowAddSignature = useCallback(() => {
    setActiveIndex(0);
    setShowAddSignature(true);
  },[showAddSignature]);

  const onHideAddSignature = useCallback(() => {
    setShowAddSignature(false);
  },[showAddSignature]);

  const value = {
    activeIndex,
    showLiveParties,
    showLiveChat,
    showPDF,
    showAddSignature,
    onShowLiveParties,
    onHideLiveParties,
    onShowLiveChat,
    onHideLiveChat,
    onShowPDF,
    onHidePDF,
    onShowAddSignature,
    onHideAddSignature,
    onLiveVideoSession,
  };

  return (
    <LiveSessionContext.Provider value={value}>
      {children}
    </LiveSessionContext.Provider>
  );
};

export { LiveSessionProvider, LiveSessionContext };
