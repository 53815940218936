import React, { useState, useCallback, useRef } from 'react';
import { View, Image, Text } from 'react-native';
import { Button01 } from '@src/components/button';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { useNavigation } from '@react-navigation/native';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import useStyles from './styles';
import { images } from 'theme';
import { LayerContext } from '../signature-fields/provider/layer';

const ChooseNotaryPopup = (props: any) => {
  const styles = useStyles();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<SheetRef>();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();
  const { partner, showBottomSheet, setShowBottomSheet } =
    React.useContext(LayerContext);

  const chooseAnother = () => {
    setShowBottomSheet(false);
  };

  const cancelFileBtn = () => {
    setShowBottomSheet(false);
  };

  const seeDetailsBtn = () => {
    // setOpen(false);
    setShowBottomSheet(false);
    navigation.navigate('AddSignatureFields');
  };

  return (
    <>
      <Sheet
        ref={ref}
        isOpen={showBottomSheet}
        onClose={() => setShowBottomSheet(false)}
        snapPoints={[500]}
        initialSnap={1}
      >
        <Sheet.Container
          style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
        >
          <Sheet.Content>
            <View style={styles.textContainer}>
              <Text style={styles.txt1}>Chosen Notary Public</Text>
            </View>
            <View style={styles.cardContainer}>
              <Card
                sx={{ maxWidth: 545 }}
                style={{ backgroundColor: '#F4F4FF' }}
              >
                <CardHeader
                  avatar={
                    <View style={styles.fileTextContainer}>
                      <Text style={styles.fileName}>{partner.name}</Text>
                      <Text style={styles.fileSize}>{partner.city}</Text>
                    </View>
                  }
                  action={
                    <Button01
                      onPress={cancelFileBtn}
                      label="X"
                      style={styles.closeBtn}
                    ></Button01>
                  }
                />
              </Card>
            </View>
            <View style={styles.fileIcon_container}>
              <Image
                source={images.file_icon1}
                style={styles.file_icon1}
                resizeMode="contain"
              />
              <Button01
                label="Choose another Notary Public"
                labelStyle={styles.fileIcon_label}
                style={styles.fileIcon_btn}
                onPress={chooseAnother}
              />
            </View>
            <View style={styles.seeDetailsBtnContainer}>
              <Button01
                style={styles.seeDetailsBtn}
                labelStyle={styles.seeDetailsTxt}
                label="Set Details"
                onPress={seeDetailsBtn}
              ></Button01>
            </View>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderTopLeftRadius: 20,
          }}
        />
      </Sheet>
    </>
  );
};

export default ChooseNotaryPopup;
