import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import useStyles from './style.css';
import PaymentsHeader from '../header';
import SuccessPaymentLogo from './logo';
import SuccessPaymentDetails from './success-payment-details';
import { Button01 } from '@src/components/button';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { images } from 'theme';
import { useNavigation } from '@react-navigation/native';

const PaymentSuccess = () => {
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();

  const nextBtn = () => {
    navigation.navigate('PaymentConfirmation');
  };

  const showAlert = () => {
    alert('under-construction-download');
  };

  return (
    <View style={styles.container}>
      <View style={styles.paymentContainerDetails}>
        <View style={styles.header}>
          <Text style={styles.headerText}>Payment</Text>
          <TouchableOpacity style={styles.arrow} onPress={showAlert}>
            <Image
              source={images.payment_download}
              style={{ width: 18, height: 16 }}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.v_body}>
          <SuccessPaymentLogo />
        </View>
        <SuccessPaymentDetails />
        <View style={styles.btnContainer}>
          <Button01
            style={styles.btnNext}
            labelStyle={styles.btnText}
            label="Next"
            onPress={nextBtn}
          />
        </View>
      </View>
    </View>
  );
};

export default PaymentSuccess;
