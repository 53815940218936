import React, {useState} from 'react';
import { View, Text, Pressable } from 'react-native';
import useStyles from './style.css';
import { TextInput02 } from '@src/components/textinput';
import { Button01 } from '@src/components/button';
import PaymentsHeader from './header';
import { useNavigation } from '@react-navigation/native';
import Icon from '@expo/vector-icons/MaterialIcons';
import colors from '@assets/colors';

const PaymentPage = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const [payment, setPayment] = useState();

  const payNowBtn = () => {
    console.log('success upload next button');
    navigation.navigate('PaymentSuccess');
  };

  return (
    <View style={styles.container}>
      <View style={styles.paymentContainerDetails}>
        <PaymentsHeader />

        {/* GCash Pay Section */}
        <View style={styles.detailsContainer1}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.payText1}>Pay with</Text>
          </View>
        </View>
        <View style={styles.detailsContainer2}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.labelDetails1}>GCash</Text>
            <Text style={styles.labelPadding1}>
              Php 5,008.00
              <Pressable  onPress={(e: any) => setPayment(e.target.value)}>
            <Icon style={styles.radio2} name="radio-button-unchecked" color={colors.red} size={16} />
            </Pressable>
            </Text>
          </View>
          <View>
          </View>
        </View>
        <View style={styles.detailsContainer2}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.payText2}>Pay Now</Text>
            <Text style={styles.payTextPadding1}>Available Balance</Text>
          </View>
        </View>

        <View style={styles.detailsContainer3}></View>
        <View style={styles.detailsContainer2}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.labelDetails}>GCredit</Text>
            <Text style={styles.labelPadding}>
              Php 5,008.00
              <Pressable  onPress={(d: any) => setPayment(d.target.value)}>
            <Icon style={styles.radio2} name="radio-button-unchecked" color={colors.red} size={16} />
            </Pressable>
            </Text>
          </View>
        </View>
        <View style={styles.detailsContainer2}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.payText2}>Pay Later</Text>
            <Text style={styles.payTextPadding1}>Available GCredit</Text>
          </View>
        </View>

        {/* Divider Section */}
        <View style={styles.divider} />

        {/* Notary Fee Section */}
        <View style={styles.detailsContainer3}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.labelDetails2}>Notary Public Name</Text>
          </View>
        </View>
        <View style={styles.detailsContainer2}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.labelDetails3}>Notary Fee</Text>
            <Text style={styles.labelDetails3Padding}>Php 200.00</Text>
          </View>
        </View>
        <View style={styles.detailsContainer2}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.labelDetails3}>Service Charge</Text>
            <Text style={styles.labelDetails3Padding}>Php 10.00</Text>
          </View>
        </View>
        <View style={styles.detailsContainer2}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.labelDetails3}>VAT</Text>
            <Text style={styles.labelDetails3Padding}>Php 5.00</Text>
          </View>
        </View>

        {/* Coupon Section */}
        <View style={styles.detailsContainer4}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.labelDetails3}>Coupon</Text>
            <TextInput02
              placeholder="Enter code"
              labelStyle={styles.couponLabel}
              style={styles.couponTextInput}
              borderStyle={{borderWidth: 1.15, borderColor: colors.border2}}
            ></TextInput02>
          </View>
        </View>

        {/* Amount Section*/}
        <View style={styles.detailsContainer2}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.labelDetails3}>Amount</Text>
            <Text style={styles.labelDetails3Padding}>Php 215.00</Text>
          </View>
        </View>

        {/* Divider Section */}
        <View style={styles.divider} />

        {/* Total Amount Section */}
        <View style={styles.detailsContainer2}>
          <View style={styles.inputVdetailsRow}>
            <Text style={styles.labelTotal}>Total</Text>
            <Text style={styles.labelTotalPadding}>Php 215.00</Text>
          </View>
        </View>

        {/* Pay Now Button Section */}
        <View style={styles.btnContainer}>
          <Button01
            style={styles.btnNext}
            labelStyle={styles.btnText}
            label="Pay Now"
            onPress={payNowBtn}
          />
        </View>
      </View>
    </View>
  );
};

export default PaymentPage;
