import React, { useContext } from 'react';
import { View, TouchableOpacity, ScrollView, Dimensions } from 'react-native';
import { mobileSizeChecker } from '@src/utils/screensize-helper';
import { useDeviceSize, DEVICE_SIZES } from 'rn-responsive-styles';
import CopyRightContent from './CopyRightContent';
import { TranslateX } from '@src/utils/animation';
import { DrawerContext } from '../DrawerProvider';
import DrawerHeader from './DrawerHeader';
import DrawerList from './DrawerList';
import useStyles from './styles.css';
import AdminTabs from './AdminTabs';
import { useAuthService } from '@src/ducks/hooks';

const DrawerComponent = () => {
  const styles = useStyles();
  const isMobile = mobileSizeChecker();
  const device_size = useDeviceSize();
  const { isDrawerVisible, onDrawerStatus } = useContext(DrawerContext);
  const { isLoggedIn } = useAuthService();
  const { width, height } = Dimensions.get('window');

  const rightToggle = () => {
    if (DEVICE_SIZES.XS === device_size) {
      return [width, width*.3];
    }
    else if (DEVICE_SIZES.SM === device_size ) {
      return [width, width*.5]
    }
  }

  const v_drawer_container_style= {
    height: (height *.9),
    top: (height *.1),
  }
  
  return (
    <View pointerEvents="box-none" style={[styles.v_drawer_container, v_drawer_container_style]}>
      {isDrawerVisible && (
        <TouchableOpacity
          activeOpacity={1}
          onPress={onDrawerStatus}
          style={styles.btn_invisible}
        />
      )}
      <TranslateX
        visible={isDrawerVisible}
        style={styles.v_drawer_translate}
        outputRange={isMobile ? rightToggle() : [0, 0]}
      >
        <View style={styles.linear_style}>
          <ScrollView>
            {/* //<DrawerHeader /> */}
            <DrawerList />
            <AdminTabs />
            <View style={styles.v_height} />
          </ScrollView>
          <CopyRightContent />
          <View style={styles.v_height} />
        </View>
      </TranslateX>
    </View>
  );
};

export default DrawerComponent;
