import colors from '@assets/colors';

export const TABLE_HEAD_INBOX = [
  {
    id: 'sender',
    label: 'Name',
  },
  { id: 'subject', label: 'Subject' },
  { id: 'createdAtFormatted', label: 'Date and Time', align: 'center' },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
  },
];

export const TABLE_HEAD_LIVEREQUEST = [
  {
    id: 'name',
    label: 'Name',
    icon: 'document_icon',
    iconStyle: {
      height: 22,
      width: 22,
      marginRight: 12,
    },
    cellStyle: { flex: 1.5 },
  },
  // { id: 'datetime', label: 'Date Requested' },
  { id: 'createdAtFormatted', label: 'Date Requested' },
  // { id: 'datetime', label: 'Date Scheduled' },
  { id: 'city', label: 'City', align: 'center' },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
  },
  {
    id: 'action',
    label: 'Actions',
    value: 'View',
    align: 'center',
    icon: 'ic_eye',
    buttonEnable: true,
    style: { fontFamily: 'DMSans-Bold' },
    color: colors.purple,
  },
];

export const TABLE_HEAD_MY_NOTARY_REQUEST = [
  {
    id: 'name',
    label: 'Name',
    icon: 'document_icon',
    iconStyle: {
      height: 22,
      width: 22,
      marginRight: 12,
    },
    cellStyle: { flex: 1.5 },
  },
  { id: 'datetime', label: 'Date Requested' },
  { id: 'city', label: 'City', align: 'center' },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
  },
  {
    id: 'action',
    label: 'Actions',
    value: 'View',
    align: 'center',
    icon: 'ic_eye',
    buttonEnable: true,
    style: { fontFamily: 'DMSans-Bold' },
    color: colors.purple,
  },
];

export const TABLE_HEAD_TRANSACTION = [
  {
    id: 'name',
    label: 'Name',
    cellStyle: { flex: 1.5 },
  },
  { id: 'status', label: 'Status' },
  { id: 'type', label: 'Type' },
  { id: 'datetime', label: 'Date', align: 'center' },
  {
    id: 'action',
    label: 'Actions',
    value: 'View',
    align: 'center',
    icon: 'ic_eye',
    style: { fontFamily: 'DMSans-Bold' },
    color: colors.purple,
  },
];

export const TABLE_HEAD_DIGITAL_NOTARIAL = [
  {
    id: 'name',
    label: 'Title of Document',
    cellStyle: { flex: 1.5 },
  },
  { id: 'date', label: 'Date' },
  { id: 'time', label: 'Time' },
  { id: 'principal', label: 'Principal' },
  { id: 'witnesses', label: 'Witnesses' },
  {
    id: 'record',
    label: 'Recorded video',
  },
];

export const TABLE_HEAD_SIGNATURE = [
  {
    id: 'createdAtFormatted',
    label: 'Created At',
  },
  { id: 'imageType', label: 'Image Type' },
  { id: 'path', label: 'Signature' },
  {
    id: 'action',
    label: 'Actions',
    value: 'Actions',
    align: 'center',
  },
];

export const TABLE_HEAD_DIGITAL_SEAL_STAMP = [
  {
    id: 'datetime',
    label: 'Created at',
  },
  { id: 'type', label: 'Type' },
  { id: 'preview', label: 'Preview', value: 'Preview' },
  {
    id: 'action',
    label: 'Actions',
    value: 'Actions',
    align: 'center',
  },
];

export const TABLE_HEAD_PARTNER_NOTARIES = [
  {
    id: 'name',
    label: 'Name',
  },
  { id: 'city', label: 'City' },
  { id: 'status', label: 'Status' },
  { id: 'datetime', label: 'Date' },
  {
    id: 'action',
    label: 'Actions',
    value: 'Schedule',
    align: 'center',
    icon: 'ic_schedule',
    style: { fontFamily: 'DMSans-Bold' },
    color: colors.purple,
  },
];

export const TABLE_HEAD_APPLICATION = [
  {
    id: 'name',
    label: 'Name',
    cellStyle: { flex: 1.5 },
  },
  { id: 'status', label: 'Status' },
  { id: 'type', label: 'Type' },
  { id: 'datetime', label: 'Date', align: 'center' },
  {
    id: 'action',
    label: 'Actions',
    value: 'View',
    align: 'center',
    icon: 'ic_eye',
    style: { fontFamily: 'DMSans-Bold' },
    color: colors.purple,
  },
];

export const TABLE_HEAD_LIVE_NOTARZATION_REQUESTS = [
  {
    id: 'name',
    label: 'Title of Document',
    cellStyle: { flex: 1.5 },
  },
  { id: 'date', label: 'Date' },
  { id: 'time', label: 'Time' },
  { id: 'principal', label: 'Principal' },
  { id: 'witnesses', label: 'Witnesses' },
  {
    id: 'record',
    label: 'Actions',
    cellStyle: { flex: 1.3 },
  },
];
