import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';
import { StyleSheet } from 'react-native';
import TypographyStyle from '@assets/typography';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.purple,
    },
    v_logo_container: {
      width: '100%',
    },
    splash_logo: { width: 340, height: 315 },

    v_body: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      // justifyContent: 'center',
    },
    signInCenter: { alignItems: 'center', marginTop: 20 },
  },
  {
    [DEVICE_SIZES.XS]: {
      v_logo_container: {
        width: '100%',
      },
      splash_logo: { width: 250, height: 70 },

      v_body: {
        width: '100%',
        alignItems: 'center',
      },
      signInCenter: { alignItems: 'center', marginTop: 20 },
    },
  }
);

export default useStyles;
