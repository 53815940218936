import React, { useState, useCallback, useRef, useContext, useEffect } from 'react';
import { View, Text, FlatList, Pressable, Image } from 'react-native';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { useNavigation } from '@react-navigation/native';
import { LiveSessionContext } from './provider';
import useStyles from './styles';
import { TextInput02, } from '@src/components/textinput';
import { images } from 'theme';
import { Card } from 'react-native-paper';
import Data from './data.json'
import { VideoContext } from './video-session/provider/video';

const LiveChatModal = () => {
    const styles = useStyles();
    const ref = useRef<SheetRef>();
    const navigation = useNavigation<NavigationScreenProps<'Home'>>();
    const { messages, sendMessage } = React.useContext(VideoContext);
    const { showLiveChat, onHideLiveChat } = React.useContext(LiveSessionContext);
    const [message, setMessage] = useState('')
    const flatListRef = useRef<any>();

    const scrollToBottom = () => {
        flatListRef.current.scrollToEnd({ animated: false });
    };
    
    // const [messages, setMessages] = useState([
    //     { id: '1', title: 'You', text: 'Dolor sit amet, consectetur adipiscing ' },
    //     { id: '2', title: 'Atty. Juan Martin', text: 'Dolor sit amet, consectetur adipiscing elit. Hendrerit ' },
    //     { id: '2', title: 'Atty. Juan Martin', text: 'Dolor sit amet, consectetur adipiscing elit. Hendrerit ' },
    //     { id: '1', title: 'You', text: 'Dolor sit amet, consectetur adipiscing ' },
    //     { id: '2', title: 'Atty. Juan Martin', text: 'Dolor sit amet, consectetur adipiscing elit. Hendrerit ' },
    //     { id: '1', title: 'You', text: 'Dolor sit amet, consectetur adipiscing ' },
    //     { id: '2', title: 'Atty. Juan Martin', text: 'Dolor sit amet, consectetur adipiscing elit. Hendrerit ' },
    //     { id: '2', title: 'Atty. Juan Martin', text: 'Dolor sit amet, consectetur adipiscing elit. Hendrerit ' },
    //     // Add more messages here
    // ]);

    // const onSendMessageKeyPress = useCallback((event: any) => {
    //     let text = message
    //     if (event.key === 'Enter' && text !== '') {
    //         let newMessages = [...messages, { id: '1', title: 'You', text: text }]
    //         setMessages(newMessages);
    //         setTimeout(() => {
    //             scrollToBottom();
    //         }, 75);
        
    //         setTimeout(() => {

    //             setMessage('');
    //             let response;
            
    //             if (text.toLowerCase().includes('hello') || text.toLowerCase().includes('hi')) {
    //                 response = 'Hello, Randolf!';
    //             } else if (text.toLowerCase().includes('how are you')) {
    //                 response = 'I am an AI, I do not have feelings, but thank you for asking!';
    //             } else {
    //                 response = 'Sorry, I did not understand that.';
    //             }
    //             newMessages.push({ id: '2', title: 'Atty. Juan Martin', text: response });
    //             setMessages(newMessages);
    //             setTimeout(() => {
    //                 scrollToBottom();
    //             }, 75);
    //         }, 1000);
    //     }
    // }, [message, setMessage, messages, setMessages]);

    useEffect(() => {
        setTimeout(() => {
            scrollToBottom();
        }, 75);
    }, [messages])

    const onSendMessageKeyPress = useCallback((event: any) => {
        if (event && message) {
            sendMessage(message, ``)
            setMessage("")
        }
    },[message])

  const renderItem = ({ item, }: { item: any}) => (
    <View style={item.yourMessage ? styles.yourMessageContainer : styles.messageContainer}>
        <Text style={{ fontSize: 10, padding: 2, color: 'gray' }}>{item.name}</Text>
        <Text style={item.yourMessage ? styles.yourMessageText : styles.messageText}>{item.text}</Text>
    </View>
  );

  return (
      <Sheet
        ref={ref}
        isOpen={showLiveChat}
        onOpenStart={() => {
            setTimeout(() => {
                scrollToBottom();
            }, 50);
        }}
        onClose={() => onHideLiveChat()}
        snapPoints={[450]}
        
        initialSnap={1}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
                <View style={styles.chatContainer}>
                    <FlatList
                        style={{  marginBottom: 50, }}
                        ListFooterComponent={<View style={{  height: 30 }}></View>}
                        ref={flatListRef}
                        data={messages}
                        renderItem={renderItem}
                        keyExtractor={(item, index) => `${item.name}-${index}`}
                        onContentSizeChange={scrollToBottom}
                        onLayout={scrollToBottom}
                        />
                </View>
                <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
                    <TextInput02
                        value={message}
                        onChangeText={setMessage}
                        placeholder="Enter you message"
                        onSubmitEditing={onSendMessageKeyPress}
                        inputStyle={styles.message_input}
                        borderStyle={styles.message_input_border_style}
                        style={styles.message_input_style}
                        
                        />
                    <Pressable  onPress={() => onSendMessageKeyPress({ key: 'Enter' })}
                                style={{ position: 'absolute', padding: 8, borderRadius: 10, marginRight: 27, bottom: 27, backgroundColor: '#44449B' }}>
                        <Image source={images.live_send_message} style={{ width: 20, height: 20, }} />
                    </Pressable>
                </View>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
      </Sheet>
  );
};

export default LiveChatModal;
