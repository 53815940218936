import React, { useCallback, useState } from 'react';
import { View, Text, Pressable } from 'react-native';
import useStyles from '../partner-notaries/styles';
import { TextInput02 } from '@src/components/textinput';
import { applySortFilter, getComparator } from '@src/utils/filter-helper';
import PartnerNotariesData from './partner-notaries';
import Data from './data.json';
import { LayerContext } from '../signature-fields/provider/layer';
import ChooseNotaryPopup from './bottom-popup';
import Icon from '@expo/vector-icons/MaterialIcons';
import { useNavigation } from '@react-navigation/native';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import colors from '@assets/colors';
import { Button01 } from '@src/components/button';
import Pagination from '@src/components/data-table/pagination';
import { useInputHelper } from '@src/utils/inputs-helper';

const initialState = {
  page: 0,
  order: 'descending',
  filterName: '',
  orderBy: 'updatedAt',
  rowsPerPage: 5,
};

const PartnerNotariesMobile = () => {
  const styles = useStyles();
  const [order, setOrder] = React.useState<any>(undefined);
  const [orderBy, setOrderBy] = React.useState('name');
  const [disabledButton, setDisabledButton] = React.useState<boolean>(true);
  const { search, onSearchStateChange } = React.useContext(LayerContext);
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();
  const [showPagination, setShowPagination] = React.useState<boolean>(true);
  const [hideButton, setHideButton] = React.useState<boolean>(true);
  const [isVisible, setIsVisible] = React.useState<boolean>(true);
  const { state, onDispatch } = useInputHelper(initialState);

  const filteredUsers = React.useMemo(
    () =>
      applySortFilter(
        Data.result,
        getComparator(order, orderBy),
        search,
        'name'
      ),
    [Data.result, order, orderBy, search]
  );

  const seeAllPartners = () => {
    setShowPagination(false);
    setHideButton(false);
  };

  const handleChangePage = (newPage: any) => {
    onDispatch('page')(newPage);
  };

  const handleChangeRowsPerPage = (value: any) => {
    onDispatch('page')(0);
    onDispatch('rowsPerPage')(value);
  };
  const currentPosts = filteredUsers.slice(
    state.page * state.rowsPerPage,
    state.page * state.rowsPerPage + state.rowsPerPage
  );

  React.useEffect(() => {
    if (search.length > 0) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [search]);

  return (
    <View style={styles.container}>
      <View style={styles.viewContainerSeeDetails}>
        <View style={styles.header}>
          <Pressable style={styles.arrow} onPress={() => navigation.goBack()}>
            <Icon name="keyboard-arrow-left" color={colors.white} size={24} />
          </Pressable>
          <Text style={styles.headerText}>Choose Notary Public</Text>
        </View>
        <View style={styles.searchContainer}>
          <TextInput02
            onSearchIcon="magnify"
            value={search}
            placeholder="Search Notary Public"
            inputStyle={styles.ti_search}
            borderStyle={styles.ti_border_style}
            style={styles.ti_style}
            onChangeText={onSearchStateChange}
          >
          </TextInput02>
        </View>
        <View>
          {isVisible && (
            <Text style={styles.partnerTxt}>
              You may also choose from our available partner notaries
            </Text>
          )}
          <View style={{ paddingBottom: 200, paddingTop: 50 }}>
            <PartnerNotariesData
              data={currentPosts}
              setDisabledButton={setDisabledButton}
            />
          </View>
        </View>

        <View style={styles.btnContainer}>
          {!hideButton ? (
            <Pagination
              page={state.page}
              count={filteredUsers.length}
              rowsPerPage={state.rowsPerPage}
              rowsPerPageOptions={[5, 10, 50, 100]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <Button01
              style={styles.seeDetailsBtn}
              labelStyle={styles.seeDetailsTxt}
              label="See all Partner Notaries"
              onPress={seeAllPartners}
            ></Button01>
          )}
          <ChooseNotaryPopup />
        </View>
      </View>
    </View>
  );
};

export default PartnerNotariesMobile;
