import React, { useCallback, useContext } from 'react';
import { useDeviceSize, DEVICE_SIZES } from 'rn-responsive-styles';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { View, Text, Pressable } from 'react-native';
import useStyles from './styles.css';
import { useNavigation } from '@react-navigation/native';
import Logo from './logo';
import LiveSessionConcluded from './live-session-concluded';
import { Button01 } from '@src/components/button';

type Props = {
};

const LiveSessionEnd = (props: Props) => {
  const device_size = useDeviceSize();
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();

  const onBacktoDashboard = useCallback(() => {
    navigation.navigate('Home')
  }, [])

  return (
    <View style={styles.container}>
      <View style={styles.v_body}>
        <Logo />
        <LiveSessionConcluded />
        <View style={styles.btn_container}>
          <Button01
                    style={{ backgroundColor: `#44449B` }}
                    onPress={onBacktoDashboard}
                    label="Back to Dashboard">

          </Button01>
          <Pressable onPress={() => { }} style={styles.review_documents_btn_border_transparent}>
              <Text style={styles.review_documents_label_transparent}>Review Documents</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default LiveSessionEnd;
