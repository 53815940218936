import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StyleSheet } from 'react-native';
import colors from '@assets/colors';
import TypographyStyle from '@assets/typography';

const useStyles = CreateResponsiveStyle(
  {
    affixSignature: {
      width: 120,
      height: 38,
      margin: 10,
      marginRight: 15,
      borderRadius: 8,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 10,
      paddingRight: 10,
      paddingVertical: 8,
      backgroundColor: colors.purple,
    },
    affixSignatureLabel: StyleSheet.flatten([
      TypographyStyle.buttonText,
      { fontSize: 12, fontFamily: 'DMSans', color: colors.white },
    ]),

    liveSessionModal: { flex: 1, alignItems: 'center', paddingVertical: 20 },
    liveSessionTextHeader: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      { fontSize: 16, fontFamily: 'DMSans', color: colors.black },
    ]),
    liveSessionText: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      { fontSize: 13, fontFamily: 'DMSans', color: colors.darkGray, marginTop: 10 },
    ]),
    liveSessionText2: StyleSheet.flatten([
      TypographyStyle.BodySRegular,
      { fontSize: 13, fontFamily: 'DMSans', color: colors.darkGray },
    ]),
    closeButton: {
      position: 'absolute',
      top: 35,
      right: 25,
      fontSize: 20,
      color: 'black',
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
