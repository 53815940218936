import React, { useEffect } from 'react';
import { useDeviceSize, DEVICE_SIZES } from 'rn-responsive-styles';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { View } from 'react-native';
import Background from './background';
import useStyles from './styles.css';
import { useNavigation } from '@react-navigation/native';
import Logo from './logo';

const SplashScreen = () => {
  const device_size = useDeviceSize();
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();

  if (
    DEVICE_SIZES.XS !== device_size &&
    DEVICE_SIZES.SM !== device_size &&
    DEVICE_SIZES.MD !== device_size
  ) {
    return null;
  }

  useEffect(() => {
    setTimeout(() => {
      navigation.navigate('OnBoarding');
    }, 1000);
  });

  return (
    <View style={styles.container}>
      <Background />
      <View style={styles.v_body}>
        <Logo />
      </View>
    </View>
  );
};

export default SplashScreen;
