import { all, fork } from 'redux-saga/effects';
import authWatcherSaga from './auth.sagas';
import userWatcherSaga from './user.sagas';
import signsecureWatcherSaga from './signsecure.sagas';
import { inboxWatcherSaga } from './inbox.sagas';
import { inboxdetailsWatcherSaga } from './inbox.sagas';
import { signatureWatcherSaga } from './signature.sagas';
import { deleteSignatureWatcherSaga } from './signature.sagas';
import { addSignatureWatcherSaga } from './signature.sagas';

export default function* rootSaga() {
  yield all([
    fork(authWatcherSaga),
    fork(userWatcherSaga),
    fork(signsecureWatcherSaga),
    fork(inboxWatcherSaga),
    fork(inboxdetailsWatcherSaga),
    fork(signatureWatcherSaga),
    fork(deleteSignatureWatcherSaga),
    fork(addSignatureWatcherSaga),
  ]);
}
