import { View, Text, Pressable } from 'react-native';
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';
import { AdminList } from '@src/utils/mockdata/AdminList';
import { useAppSelector } from '@src/ducks/ducksHook';
import React, { useContext, useState } from 'react';
import { DrawerContext } from '../DrawerProvider';
import useStyles from './styles.css';

const AdminTabs = () => {
  const styles = useStyles();
  const [hover, setHover] = useState(
    new Array<boolean>(AdminList.length).fill(false)
  );
  const role = useAppSelector(selectedAuthRole);
  const { onActiveTab, activeTab } = useContext(DrawerContext);

  const handleHover = (eventName: string, idx: number) => {
    const isHover = eventName === 'hoverIn';
    setHover((prevState) => ({
      ...prevState,
      [idx]: isHover,
    }));
  };

  if (role !== 'admin') {
    return null;
  }

  return (
    <View>
      <Text style={styles.adminTabs}>Admin Menu</Text>
      {AdminList.map((label: string, index: number) => {
        const isActive = label === activeTab || hover[index];
        const activeBG = isActive && styles.activeBG;

        return (
          <Pressable
            key={`admintabs${index}`}
            onHoverIn={() => handleHover('hoverIn', index)}
            onHoverOut={() => handleHover('hoverOut', index)}
            onPress={() => onActiveTab(label)}
            style={[styles.drawer_admintab_container, activeBG]}
          >
            <Text style={[styles.drawer_tab_label]}>{label}</Text>
          </Pressable>
        );
      })}
      <View style={{ height: 40 }} />
    </View>
  );
};

export default AdminTabs;
