import React from 'react';
import { View, Image, Text } from 'react-native';
import { DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
import useStyles from './styles.css';
import { images } from 'theme';

const Logo = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();

  //   if (DEVICE_SIZES.XS === device_size || DEVICE_SIZES.SM === device_size || DEVICE_SIZES.MD === device_size) {
  //     return null;
  //   }

  return (
    <View style={styles.v_logo_container}>
      <View style={styles.imgContainer}>
        <Image
          source={images.live_session_concluded}
          style={styles.live_session_concluded}
          resizeMode="contain"
        />
      </View>
    </View>
  );
};

export default Logo;
