import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import Button from '@src/components/button/Button02';
import CreateRequestCard from './CreateRequestCard';
import { RequestContext } from '../RequestProvider';
import { useNavigation } from '@react-navigation/native';
import useStyles from './styles.css';

const CreateRequestModal = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const {
    isCreateRequestVisible,
    onCreateRequestPress,
    onLivePress,
    onSubmitDocumentPress,
    onSetTotalSteps,
  } = useContext(RequestContext);

  const onPress = (type?: string) => {
    if (type === 'live') {
      onSetTotalSteps(4); // total of 5 steps including index 0
      // onLivePress();
      navigation.navigate('LiveRequestNew');
    } else {
      onSetTotalSteps(2); // total of 3 steps including index 0
      onSubmitDocumentPress();
    }
    onCreateRequestPress();
  };

  if (!isCreateRequestVisible) {
    return null;
  }

  return (
    <View style={styles.cr_modal_container}>
      <View style={styles.cr_field_v}>
        <Text style={styles.cr_header}>Create a new request</Text>
        <View style={styles.cr_card_v}>
          <CreateRequestCard
            icon={'camera'}
            title="I want a live notarization session"
            description="Choose from our available partners"
            onPress={() => onPress('live')}
          />
          {/* <CreateRequestCard
            icon={'document'}
            title="I want to submit a document"
            description="Submit documents online"
            onPress={onPress}
          /> */}
        </View>
        <Button style={styles.cr_btn_cancel} onPress={onCreateRequestPress}>
          <Text style={styles.cr_btn_label}>Cancel</Text>
        </Button>
      </View>
    </View>
  );
};

export default CreateRequestModal;
