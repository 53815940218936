import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

const RectImage = (props: any) => {
  const [image] = useImage(props.src);

  return (
    <Image
      x={props.x}
      y={props.y}
      image={image}
      width={props.width}
      height={props.height}
    />
  );
};

export default RectImage;
