import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../ducksHook';
import { selectAuthSession } from '../slices/auth.slice';
import {
  signsecureActions,
  selectCreateRequestData,
  selectedGeneratedPDF,
  selectedSignSecureLoading,
  selectedViewDocument,
} from '../slices/signsecure.slice';

// Types
import { SessionValue } from '../types';

interface Operators {
  requestData: any;
  selectedDocument: any,
  isLoading: boolean;
  session: SessionValue;
  generatedpdf: any;
  viewDocumentRequest: (value: any) => void;
  createLiveRequest: (value: any) => void;
  submitVideoSession: () => void;
  updateSignatures: (value: any) => void;
  resetError: () => void;
}

export const useSignsecureService = (): Readonly<Operators> => {
  const dispatch = useAppDispatch();
  const session = useAppSelector(selectAuthSession);
  const requestData = useAppSelector(selectCreateRequestData);
  const selectedDocument = useAppSelector(selectedViewDocument);
  // TODO: RequestData Static
  // Instead, use selectedDocument

  return {
    session,
    requestData,
    selectedDocument,
    isLoading: useAppSelector(selectedSignSecureLoading),
    generatedpdf: useAppSelector(selectedGeneratedPDF),
    createLiveRequest: useCallback(
      (value) => {
        dispatch(signsecureActions.createLiveRequest(value));
      },
      [session, dispatch]
    ),
    viewDocumentRequest: useCallback(
      (value) => {
        dispatch(signsecureActions.viewDocumentRequest(value));
      },
      [session, dispatch]
    ),
    submitVideoSession: useCallback(() => {
      dispatch(signsecureActions.submitVideoSession(requestData));
    }, [session, dispatch, requestData]),
    updateSignatures: useCallback(
      (value) => {
        dispatch(signsecureActions.updateSignatures(value));
      },
      [dispatch]
    ),
    resetError: useCallback(() => {
      dispatch(signsecureActions.resetError());
    }, [session, dispatch]),
  };
};

export default useSignsecureService;
